import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "../../views/admin/Dashboard";
import {Role} from "../../models/auth.model";
import Sidebar from "components/base/Sidebar/Sidebar";
import SmePanel from "../../views/sme/SmeVerification";
import {getLevel} from "../../utils/StorageUtils";
import SmeProfile from "components/sme/profile/SmeProfile";
import SmeSupplierList from "../../views/sme/SmeSupplierList";
import SmeSupplierProfile from "../../views/sme/SmeSupplierProfile";
import PaymentResult from "../../views/sme/paymentResult";
import SmeOrders from "../../views/sme/SmeOrders";
import SmeOrderDetail from "../../views/sme/SmeOrderDetail";
import {useSmeProfile} from "../../hooks/sme/useSmeVerification";
import {createContext, useMemo} from "react";
import {SME_LEVEL} from "../../utils/smeLevels";

export const SmeContext = createContext<any>(null);

export default function SmeLayout() {
    const {isPending: isLoadingProfile, refetch: getProfile, smeProfile} = useSmeProfile();
    const menu : {title : string , path :string}[] = useMemo(()=> {
        if (smeProfile){
            if (smeProfile.level === SME_LEVEL.AdminActivated) return [
                {
                    title: 'Profile',
                    path: '/sme/profile'
                },
                {
                    title: 'Dashboard',
                    path: '/sme/dashboard'
                },
                {
                    title: 'Supplier\'s',
                    path: '/sme/supplier'
                },
                {
                    title: 'Order\'s',
                    path: '/sme/orders'
                },
                // {
                //     title: 'Installment\'s',
                //     path: '/sme/installments'
                // },
            ]
            else return  [
                {
                    title: 'Verification',
                    path: '/sme/verification'
                },
                {
                    title: 'Profile',
                    path: '/sme/profile'
                }
            ]
        }else return []
    } , [smeProfile])
    return (
        <>
            {isLoadingProfile ? <p className={'text-center'}>loading</p> : <SmeContext.Provider value={{
                getProfile , smeProfile
            }} >
                <Sidebar menu={menu} title={'SME'}/>
                <div className="relative md:ml-64 bg-blueGray-100">
                    <div className="px-2 mx-auto w-full py-2">
                        <Routes>
                            <Route path="dashboard" element={<Dashboard/>}/>
                            <Route path="verification" element={<SmePanel refetch={getProfile} smeProfile={smeProfile!}
                                                                          currentLevel={smeProfile?.level!}/>}/>
                            <Route path="profile" element={<SmeProfile  smeProfile={smeProfile!}
                                                                       currentLevel={smeProfile?.level!} />}/>
                            <Route path='supplier' element={<SmeSupplierList/>}/>
                            <Route path="supplier/:id" element={<SmeSupplierProfile/>}/>
                            <Route path="payment-result/:id" element={<PaymentResult/>}/>
                            <Route path="orders" element={<SmeOrders/>}/>
                            <Route path="order/:id" element={<SmeOrderDetail/>}/>
                            <Route path='invoices' element={<></>}/>
                            <Route path="*" element={<Navigate to={ smeProfile?.level === SME_LEVEL.AdminActivated ? 'profile' : 'verification'}
                                                               replace={true}/>}
                            />
                        </Routes>
                    </div>
                </div>
            </SmeContext.Provider>}
        </>
    );
}
