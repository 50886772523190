import {QueryKeys} from "../../utils/ReactQueyConstants";
import { getSupplierLgs} from "../../http/admin/admin-request";
import { useQuery} from "@tanstack/react-query";


export default function useSupplierLogsAdmin (
    id : string ,
) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SUPPLIER_LOGS , id] ,
        queryFn : () => getSupplierLgs(id)
    })
    return {
        logs : data?.content.logs ,
        logsPending : isPending,
    }
}