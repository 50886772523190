import Button from "components/base/Button/Button";
import {useDeleteDocs, useGetDocs, useUploadDoc, useVerifyDocs} from "hooks/sme/useSmeVerification";
import {FC, useEffect, useRef, useState} from "react";
import {ISmeChild} from "../../../views/sme/SmeVerification";
import DeleteDocConfirmModal from "../DeleteDocConfirmModal";
import FileLogo from 'assets/img/other/file.png';
import {toast} from "react-toastify";
import {Label, Select} from "flowbite-react";
import {SmeLevelsForAdmin} from "../../../utils/smeLevels";
import {SmeRequirements} from "../../../utils/docsRequirements";
import {SmeDocs} from "../../../models/sme.models";

const ImgWidth = 220;

function Requierments() {
    return (
        <>
            <p className='w-full font-bold'>Required documents for SME:</p>
            <ul className='w-full list-disc px-5'>
                <li>Article of association</li>
                <li>CR (commercial registration) license</li>
                <li>ID of shareholders</li>
                <li>Bank certified SME business account IBAN</li>
                <li>A comprehensive financial statement (past two years)</li>
                <li>A bank statement covering the most recent year</li>
                {/* <li>Required documents for SUPPLIER:</li>
            <li>1) CR (commercial registration) license </li>
            <li>2) Article of association </li>
            <li>3) Bank certified Supplier business account IBAN</li> */}
            </ul>
        </>
    )
}


const UploadDocuments: FC<ISmeChild> = ({levelUp}) => {

    // const filesRef = useRef<HTMLInputElement>(null);
    // const [filesToUpload, setFilesToUpload] = useState<HTMLInputElement>();
    const [filesToUpload, setFilesToUpload] = useState<File>();
    const [desc, setDesc] = useState<any>('');
    const [confirm, showConfirm] = useState<{ show: boolean, id: string }>({show: false, id: ""});
    const [selectedImage, setSelectedImage] = useState<{ name: string, link: string }>();
    const imgContainer = useRef<HTMLDivElement>(null);

    const {docs, getDocList, getDocsLoading} = useGetDocs();
    const {uploadDoc, uploadPending, uploadResponse} = useUploadDoc();
    const {verifyDoc, verifyDocsPending, verifyDocsResponse} = useVerifyDocs();
    const {deleteDoc, deleteDocsPending, deleteDocsResponse} = useDeleteDocs();

    function handleUploadFiles() {
        if (!filesToUpload) {
            return toast.warning('select file to upload')
        }
        if (!desc) {
            return toast.warning('select title of file')
        }

        uploadDoc({file: filesToUpload, desc});
    }

    function handleConfirmDocs() {
        const requiremnets = SmeRequirements.filter(r => r.required)
        const list: string[] = [];
        requiremnets.forEach((req: any) => {
            if (!docs.find(((doc: SmeDocs) => doc.title === req.title))) {
                list.push(`you must upload  ${req.title}`)
            }
        })
        if (list && list.length > 0) {
            return toast.warning(list[0])
        } else {
            verifyDoc();
        }
    }

    useEffect(() => {
        if (uploadResponse?.status) {
            setFilesToUpload(undefined);
            setDesc('')
            getDocList();
        }
    }, [uploadResponse]);

    useEffect(() => {
        if (deleteDocsResponse?.status) {
            getDocList();
        }
    }, [deleteDocsResponse]);

    useEffect(() => {
        if (verifyDocsResponse?.status) {
            window.location.reload();
        }
    }, [verifyDocsResponse]);

    function onConfirmResult(success: boolean, id: string) {
        if (success) {
            deleteDoc({docId: id})
        }
        showConfirm({id: "", show: false});
    }

    function deleteImageById(docId: string) {
        deleteDoc({docId});
    }

    return (
        <form className="w-full md:w-[500px] flex flex-col h-full gap-5 justify-center items-center">

            {
                selectedImage &&
                <div
                    onClick={() => setSelectedImage(undefined)}
                    className="fixed z-40 flex flex-col overflow-hidden left-0 py-10 top-0 bg-[#000000F0] w-screen h-screen justify-center items-center">
                    <img src={selectedImage.link} alt="" className="z-50 max-h-full"/>
                    <p className='text-white'>{selectedImage.name}</p>
                </div>
            }

            <p className="font-bold text-2xl text-center">SME Upload Documents</p>
            <p className="text-gray-dark text-start">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <div className="flex gap-3 w-full border-2 border-blueGray-200 rounded-2xl">

                <div
                    onClick={() => {
                        if (imgContainer.current) {
                            imgContainer.current.scrollLeft -= 10;
                        }
                    }}
                    className="w-10 text-4xl font-bold px-2 justify-center cursor-pointer text-white
                            min-h-full flex flex-col rounded-tl-xl rounded-bl-xl bg-blueGray-600">
                    {"<"}
                </div>

                <div ref={imgContainer} className="h-[180px] w-full overflow-x-scroll whitespace-nowrap justify-center items-center hide-scroll 
                p-2">
                    {docs.length === 0 ? <span>No Image</span> :
                        docs.map(doc => {
                            const link = `${process.env.REACT_APP_BASE_URL}/api/v1/pub/sme/doc/file/${doc.file}`;
                            return (
                                <div
                                    key={doc._id}
                                    className="relative group inline-block mx-2 w-32 h-full cursor-pointer bg-[#FFFFFFA0] rounded-lg">

                                    <span
                                        onClick={() => setSelectedImage({
                                            link, name: doc.title
                                        })}
                                        className='absolute left-0 top-0 rounded-lg justify-center items-center group-hover:flex hidden 
                                    bg-[#000000A0] w-full h-full text-pink-500 font-bold text-sm'>
                                        {doc.title}
                                    </span>

                                    <img
                                        className="rounded-lg max-h-full w-[150px]"
                                        src={link}
                                        alt=""/>

                                    <Button
                                        loading={deleteDocsPending}
                                        disabled={deleteDocsPending}
                                        type='button'
                                        onClick={() => deleteImageById(doc._id)}
                                        className='bg-[#FF0000A0] items-center max-h-8 w-full rounded-t-none text-white absolute bottom-0'>
                                        Delete
                                    </Button>
                                </div>
                            )
                        })
                    }
                </div>

                <div onClick={() => {
                    if (imgContainer.current) {
                        imgContainer.current.scrollLeft += 10;
                    }
                }}
                     className="w-10 text-4xl font-bold px-2 justify-center cursor-pointer min-h-full
                                flex flex-col rounded-tr-xl rounded-br-xl bg-blueGray-600 text-white">
                    {">"}
                </div>

            </div>

            <div className="flex gap-5 w-full mt-5 flex-col">
                <label
                    className='w-full border-2 p-1 gap-2 cursor-pointer flex items-center text-blueGray-600 border-gray rounded-lg'
                    htmlFor='file'>
                    <img src={FileLogo} alt='' width={30}/>
                    <span>
                        {filesToUpload ? filesToUpload.name : "Select File To Upload"}
                    </span>
                </label>
                <input
                    id='file'
                    className="w-full border-solid border-2 border-gray 
                            hover:shadow-lg cursor-pointer rounded-lg"
                    type="file"
                    style={{display: "none"}}
                    multiple={false}
                    onChange={(e) => {
                        if (e.target.files && e.target.files.length > 0) {
                            setFilesToUpload(e.target.files[0]);
                        }
                    }}
                />

                <div>
                    <div className="block">
                        <Label htmlFor="levels" value="title of file"/>
                    </div>
                    <Select value={desc} id="titles" onChange={(event) => setDesc(event.target.value)}>
                        {SmeRequirements.map((item: any) => <option key={item.key} value={item.title}>
                            {item.title}
                        </option>)}
                    </Select>
                </div>

                <div className={'text-left'}>
                    <Button
                        loading={getDocsLoading || uploadPending}
                        disabled={getDocsLoading || uploadPending}
                        type="button"
                        className="whitespace-nowrap  self-center bg-blueGray-600 text-white"
                        onClick={handleUploadFiles}
                    >
                        Upload
                    </Button>
                </div>
            </div>

            <Requierments/>

            <Button
                loading={verifyDocsPending}
                type="button"
                disabled={docs.length === 0 || verifyDocsPending}
                className="whitespace-nowrap mt-5 bg-green-700 w-2/3 text-white"
                onClick={handleConfirmDocs}
            >
                Verify
            </Button>

            <div className="w-full lg:w-1/2 flex gap-3">


            </div>
            {
                confirm.show &&
                <DeleteDocConfirmModal
                    onClose={onConfirmResult}
                    id={confirm.id}
                    openModal={confirm.show}/>
            }

        </form>
    );
}

export default UploadDocuments;