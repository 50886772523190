import {Link} from "react-router-dom";
import Footer from "../components/base/Footers/Footer";
import pattern from '../assets/img/pattern_react.png'
import IndexNavbar from '../components/base/Navbars/IndexNavbar'
import service1 from '../assets/img/landing/service1.jpeg'
import service2 from '../assets/img/landing/service2.jpg'
import service3 from '../assets/img/landing/service3.jpg'
import service4 from '../assets/img/landing/service4.jpg'
import service5 from '../assets/img/landing/service5.jpg'
import {LinkedinOutlined, PhoneOutlined, WhatsAppOutlined} from "@ant-design/icons";


export default function HomePage() {
    return (
        <>
            <IndexNavbar fixed/>
            <section className="header relative items-center flex" style={{minHeight: '100vh'}}>
                <div className=" mx-auto container px-2 items-center flex flex-wrap">
                    <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12">
                        <div>
                            <h2 className="font-semibold text-4xl text-blueGray-600">
                                A robust trade credit solution for SMEs
                            </h2>
                            <p className="mt-4  leading-relaxed text-blueGray-500">
                                Fatra® Buy Now, Pay Later (BNPL) product presents an interest-free credit solution
                                specifically tailored for SMEs within the GCC region. SMEs are afforded the convenience
                                of digital registration via the Fatra® platform. This innovative offering serves as an
                                alternative to conventional credit facilities, distinguished by its expedited processing
                                and access to a broader array of lending institutions.
                            </p>
                            <div className="mb-4 mt-2">
                                <Link
                                    to={'/auth/register/sme'}
                                >
                                    <button
                                        className="get-started text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Get started as SME
                                    </button>
                                </Link>
                            </div>
                            <h2 className="font-semibold text-4xl text-blueGray-600">
                                Risk-free and improved sales for suppliers
                            </h2>
                            <p className="mt-4  leading-relaxed text-blueGray-500">
                                Through a partnership with Fatra®, suppliers will mitigate the risks typically
                                associated with extending credit to Small and Medium-sized Enterprises (SMEs). Fatra®
                                will directly settle invoices upon receipt from suppliers, thereby managing and
                                executing the credit arrangements and follow-ups. This process ensures that suppliers
                                maintain significant working capital, a marked improvement over the traditional model.
                                Furthermore, the Fatra® platform offers suppliers effortless access to a broad spectrum
                                of SMEs, both domestically and regionally.

                            </p>
                            <div className="my-2">
                                <Link
                                    to={'/auth/register/provider'}
                                >
                                    <button
                                        className="github-star text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Get started as supplier
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <img
                    className="absolute top-1 b-auto right-0  sm:w-6/12  md:block hidden w-10/12 "
                    src={pattern}
                    alt="..."
                />
            </section>
            <section className="py-20 relative bg-blueGray-100">
                <div className="mx-auto container px-2">
                    <div className="flex flex-wrap items-center">
                        <div className="w-12/12 md:w-6/12">
                            <div
                                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                                <img
                                    alt="..."
                                    src={service5} className="w-full align-middle rounded-t-lg"
                                />
                                <blockquote className="relative p-8 mb-4">
                                    <h4 className="text-xl font-bold text-black">
                                        Smooth Cross Boarder Transactions
                                    </h4>
                                    <p className="text-md font-light mt-2 text-black">
                                        Fatra@ Business-to-business Buy Now, Pay Later (B2B BNPL) services can
                                        significantly benefit small and medium-sized enterprises (SMEs) in the Gulf
                                        Cooperation Council (GCC) region. By providing a flexible financing option, B2B
                                        BNPL allows SMEs to manage their cash flow more effectively, facilitating
                                        smoother operations and enabling them to invest in growth opportunities without
                                        the immediate strain of upfront payments.
                                    </p>

                                </blockquote>
                            </div>
                        </div>

                        <div className="w-full md:w-6/12 px-4">
                            <div className="flex flex-wrap">
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-white font-bold p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                                                1
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                Service 1
                                            </h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Immediate settlement of invoice, payment is made to suppliers directly
                                                after invoices are uploaded to Fatra® platform.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col min-w-0">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-white font-bold p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blueGray-700">
                                                2
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                service 2
                                            </h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Exposure to new customer base, SME’s will be obtaining goods and
                                                services without the need of the credit approval of the supplier.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-6/12 px-4">
                                    <div className="relative flex flex-col min-w-0 mt-4">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-white font-bold p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blueGray-700">
                                                3
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                service 3
                                            </h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Seamless and fast financing solution with 0% interest: SME’s are
                                                expected to repay the loan amount through an easy payment arrangement.

                                            </p>
                                        </div>
                                    </div>
                                    <div className="relative flex flex-col min-w-0">
                                        <div className="px-4 py-5 flex-auto">
                                            <div
                                                className="text-white font-bold p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-700">
                                                4
                                            </div>
                                            <h6 className="text-xl mb-1 font-semibold">
                                                service 4
                                            </h6>
                                            <p className="mb-4 text-blueGray-500">
                                                Efficient and robust credit assessment while giving the SMEs access to a
                                                wide range of suppliers to select from.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto container px-2 overflow-hidden pb-20">
                    <div className="flex flex-wrap items-center justify-between pt-48">
                        <div className="w-full md:w-5/12">
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Interest-Free Loan Facility
                            </h3>
                            <p className="text-lg font-light leading-relaxed text-justify  mt-4 mb-4 text-blueGray-600">
                                Fatra’s® Buy Now, Pay Later (BNPL) services offer Zero % interest loan to SME’s for
                                short-term financing. SME’s are not expected to pay any interest on the financing and
                                only fulfil their initial loaned amount “value of the invoice”.
                            </p>
                            <div className="block pb-6">
                                <Link
                                    to={'/auth/register/sme'}
                                >
                                    <button
                                        className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Get started as SME
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full md:w-5/12 ">

                            <img
                                alt="..."
                                src={service1}
                                width={'100%'}
                                className="w-full  rounded  z-3"
                            />

                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-between ">
                        <div className="w-full md:w-5/12 ">
                            <img
                                alt="..."
                                src={service2}
                                width={'100%'}
                                className="w-full  rounded  z-3"
                            />
                        </div>

                        <div className="w-full md:w-5/12">
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Instant Onboarding & Financing
                            </h3>
                            <p className="text-lg font-light leading-relaxed text-justify mt-4 mb-4 text-blueGray-600">
                                SME’s are not expected to spend overwhelming long hours and countless documents for
                                financing products, with Fatra® SME are able to onboard digitally by providing the
                                relatable documentation and get a response without physical presence.
                            </p>
                            <div className="block pb-6">
                                <Link
                                    to={'/auth/register/sme'}
                                >
                                    <button
                                        className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Get started as SME
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-between">
                        <div className="w-full md:w-5/12 ">
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Credit Score
                            </h3>
                            <p className="text-lg font-light leading-relaxed text-justify  mt-4 mb-4 text-blueGray-600">
                                Fatra’s® adopts unique and tailored made credit assessment methods, the methods adopted
                                by Fatra® is designed to cater for our unique financing product, the credit score is
                                subjected finally to the SME’s Financial and Non-Financial standings.
                            </p>
                            <div className="block pb-6">
                                <Link
                                    to={'/auth/register/sme'}
                                >
                                    <button
                                        className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Get started as SME
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className="w-full md:w-5/12 ">

                            <img
                                alt="..."
                                src={service3}
                                width={'100%'}
                                className="w-full  rounded  z-3"
                            />

                        </div>
                    </div>
                    <div className="flex flex-wrap items-center justify-between ">
                        <div className="w-full md:w-5/12 ">
                            <img
                                alt="..."
                                src={service4}
                                width={'100%'}
                                className="w-full  rounded  z-3"
                            />
                        </div>

                        <div className="w-full md:w-5/12 ">
                            <h3 className="text-3xl mb-2 font-semibold leading-normal">
                                Seamless Online transactions
                            </h3>
                            <p className="text-lg font-light leading-relaxed text-justify mt-4 mb-4 text-blueGray-600">
                                Fatra® allows effortless & smooth payment to the supplier, eligible SMEs can digitally
                                purchase from their suppliers using Fatra’s® BNPL financing product.
                            </p>
                            <div className="block pb-6">
                                <Link
                                    to={'/auth/register/sme'}
                                >
                                    <button
                                        className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                    >
                                        Get started as SME
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>


                </div>
                <div className="justify-center text-center flex flex-wrap mt-24">
                    <div className="w-full md:w-6/12 px-12 md:px-4">
                        <h2 className="font-semibold text-4xl">About Us</h2>
                        <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-justify">
                            Welcome to Fatra, the pioneering force in Business-to-Business Buy Now, Pay Later (B2B BNPL)
                            services, dedicated to empowering small and medium-sized enterprises (SMEs) across the Gulf
                            Cooperation Council (GCC). Our mission is to revolutionize the way SMEs manage their
                            finances by offering a seamless, flexible credit facility that enhances cash flow, supports
                            business growth, and fosters stronger relationships with suppliers.
                        </p>
                        <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-justify">
                            At Fatra we understand the unique challenges faced by SMEs in the GCC’s rapidly evolving
                            market. Whether you're looking to expand your inventory, invest in new technology, or simply
                            manage day-to-day expenses, our innovative B2B BNPL solutions provide the financial freedom
                            you need without the burden of immediate upfront payments. Our platform is designed with
                            simplicity and efficiency at its core, ensuring that accessing credit is a smooth and
                            hassle-free experience.
                        </p>
                        <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500 text-justify">
                            Our team of financial experts and technology enthusiasts are committed to supporting the
                            region's vibrant SME sector. We leverage cutting-edge technology and deep market insights to
                            offer tailored credit solutions that align with your business needs. With Fatra , you can
                            trust that you’re partnering with a company that prioritizes your growth, understands your
                            challenges, and is dedicated to your success.
                            Join us in transforming the financial landscape for SMEs in the GCC. Experience the ease of
                            B2B BNPL with Fatra and unlock your business’s full potential.
                        </p>
                        <div className={'text-center mt-6'}>
                            <Link
                                to={'/about-us'}
                                className="

                             text-black
                             font-bold px-6 py-4 rounded
                              outline-none focus:outline-none  bg-green-300
                               active:bg-green-400 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb-16 bg-blueGray-200 relative pt-32">
                <div
                    className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
                    style={{transform: "translateZ(0)"}}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                <div className="mx-auto container px-2 landing-contact">
                    <div
                        className="flex flex-wrap justify-center bg-white shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
                        <div className="w-full text-center lg:w-8/12">
                            <p className="text-4xl text-center">
                            </p>
                            <h3 className="font-semibold text-3xl">
                                Contact Us
                            </h3>
                            <p>
                                Let's keep in touch!
                            </p>
                            <div className="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4 flex flex-row gap-2  justify-center items-center flex-wrap">
                                <a href={'tel:+965-67725228'} className={'bg-blue-300 p-2 rounded flex items-center' }>
                                    <PhoneOutlined className={'text-lg mx-1'}/>
                                    +965-67725228
                                </a>
                                <a href={'tel:+965-67725228'} className={'bg-blue-300 p-2 rounded flex items-center' }>
                                    <PhoneOutlined className={'text-lg mx-1'}/>
                                    +965-67725228
                                </a>
                                <span  className={'bg-green-300 p-2 rounded' }>
                                    <WhatsAppOutlined className={'text-lg mx-1'}/>
                                    +965-67725228
                                </span>
                                <a href={'https://kw.linkedin.com/in/fatra-llc-97b798262'} className={'bg-blue-300 p-2 rounded flex items-center' }>
                                    <LinkedinOutlined className={'text-lg mx-1'}/>
                                    Linkedin
                                </a>
                            </div>
                            <div className="sm:block flex flex-col mt-10">
                                <Link
                                    to="/contact-us"
                                    target="_blank"
                                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-2 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    Contact US
                                </Link>
                            </div>
                            <div className="text-center mt-16"></div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
}
