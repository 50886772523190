import NoData from "../../base/noData";
import {SmeDocs} from "../../../models/sme.models";
import imgurl from '../../../assets/img/aboutus.png'
interface Props {
    docs? : SmeDocs[] ,
    role : 'sme' | 'provider'
}
const baseUrl: string = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '185.237.14.67:3000';

export default function SmeDocsComponent ({docs , role} : Props) {
    return (
        <div className={'flex flex-wrap flex-row'}>
            {docs && docs.length > 0 ? docs?.map((doc : SmeDocs ) => <div className={'md:w-1/2 w-full p-3'}>
                <a
                    href={`${baseUrl}/api/v1/pub/${role}/doc/file/${doc.file}`}
                    target="_blank"
                    className={'h-96 cursor-pointer block relative border rounded overflow-hidden border-primary-light border-solid'}>
                        <img className={'w-full h-full  object-cover'} src={`${baseUrl}/api/v1/pub/${role}/doc/file/${doc.file}`} alt={"file"} />
                    <p style={{backgroundColor:'rgb(0 0 0 / 78%)'}} className={'text-white p-3 absolute bottom-0 left-0 right-0'}>
                        {doc.title}
                    </p>
                </a>
            </div>) : <NoData description={'no documents'}/>}
        </div>
    )
}