import fetch, {REQUEST_METHOD} from "../axiosHelper";
import {
    ActiveSmeBody,
    GetSmeListResponse,
    GetSmeLogsResponse,
    GetSmeResponse,
    VerifySmeBody
} from "../../models/sme.models";
import {
    GetSupplierListResponse,
    GetSupplierResponse,
    RejectSupplierBody,
    VerifySupplierBody
} from "../../models/supplier.models";
import {GetProviderLogsResponse} from "models/provider.models";
import {GetInsuranceResponse} from "../../models/insurance.model";
import {
    ChangeShareRateBody,
    GetCategoryListResponse,
    GetShareRateResponse,
    UpdateCategoryBody
} from "../../models/admin.model";
import {GetInstallmentListResponse, GetOrderListResponse, GetOrderResponse} from "../../models/order.model";
import {InitPaymentBody} from "../../models/payment.model";


export async function getSmeList(page: number, perPage: number, level: number) {
    let url = `/api/v1/admin/sme/list?page=${page}&perPage=${perPage}&sortBy=createdAt&order=desc`
    if (level !== -10) url = `/api/v1/admin/sme/list?page=${page}&perPage=${perPage}&level=${level}&sortBy=createdAt&order=desc`
    return await fetch<GetSmeListResponse, null>(url, REQUEST_METHOD.GET);
}

export async function getSme(id: string) {
    return await fetch<GetSmeResponse, null>(`/api/v1/admin/sme/show/${id}`, REQUEST_METHOD.GET);
}

export async function getSmeLgs(id: string) {
    return await fetch<GetSmeLogsResponse, null>(`/api/v1/admin/sme/log/list/${id}`, REQUEST_METHOD.GET);
}

export async function verifySme(data: VerifySmeBody) {
    return await fetch<any, VerifySmeBody>(`/api/v1/admin/sme/verify`, REQUEST_METHOD.POST, data);
}

export async function rejectSme(data: VerifySmeBody) {
    return await fetch<any, VerifySmeBody>(`/api/v1/admin/sme/reject`, REQUEST_METHOD.POST, data);
}

export async function activeSme(data: ActiveSmeBody) {
    return await fetch<any, ActiveSmeBody>(`/api/v1/admin/sme/active`, REQUEST_METHOD.POST, data);
}

export async function getSupplierList(page: number, perPage: number, level: number) {
    let url = `/api/v1/admin/provider/list?page=${page}&perPage=${perPage}&sortBy=createdAt&order=desc`
    if (level !== -10) url = `/api/v1/admin/provider/list?page=${page}&perPage=${perPage}&level=${level}&sortBy=createdAt&order=desc`
    return await fetch<GetSupplierListResponse, any>(url, REQUEST_METHOD.GET);
}

export async function getSupplier(id: string) {
    return await fetch<GetSupplierResponse, any>(`/api/v1/admin/provider/show/${id}`, REQUEST_METHOD.GET);
}

export async function getSupplierLgs(id: string) {
    return await fetch<GetSmeLogsResponse, null>(`/api/v1/admin/provider/log/list/${id}`, REQUEST_METHOD.GET);
}

export async function verifySupplier(data: VerifySupplierBody) {
    return await fetch<any, VerifySupplierBody>(`/api/v1/admin/provider/verify`, REQUEST_METHOD.POST, data);
}

export async function rejectSupplier(data: RejectSupplierBody) {
    return await fetch<any, RejectSupplierBody>(`/api/v1/admin/provider/reject`, REQUEST_METHOD.POST, data);
}

export async function getInsuranceList() {
    return await fetch<GetInsuranceResponse, null>(`/api/v1/admin/insurance/order`, REQUEST_METHOD.GET);
}

export async function setInsuranceList(insuranceList: string[]) {
    return await fetch<any, { insuranceList: string[] }>(`/api/v1/admin/insurance/order`, REQUEST_METHOD.POST, {insuranceList});
}

export async function getCategoryList() {
    return await fetch<any, GetCategoryListResponse>(`/api/v1/admin/categories/list`, REQUEST_METHOD.GET);
}

export async function updateCategory(data: UpdateCategoryBody) {
    return await fetch<any, UpdateCategoryBody>(`/api/v1/admin/category`, REQUEST_METHOD.PUT, data);
}

export async function createCategory(data: Omit<UpdateCategoryBody, 'categoryId'>) {
    return await fetch<any, Omit<UpdateCategoryBody, 'categoryId'>>(`/api/v1/admin/category`, REQUEST_METHOD.POST, data);
}

export async function changeBankShareRate(data: ChangeShareRateBody) {
    return await fetch<any, ChangeShareRateBody>(`/api/v1/admin/bank/share-rate`, REQUEST_METHOD.PUT, data);
}

export async function getBankShareRate() {
    return await fetch<GetShareRateResponse, null>(`/api/v1/admin/bank/share-rate`, REQUEST_METHOD.GET);
}

export async function adminGetOrderList(page: number, perPage: number, status: number) {
    let url = `/api/v1/admin/order/list?page=${page}&perPage=${perPage}`
    if (status !== -10) url += `&status=${status}`
    return await fetch<GetOrderListResponse, void>(url, REQUEST_METHOD.GET);
}

export async function adminGetOrder(id: string) {
    return await fetch<GetOrderResponse, void>(`/api/v1/admin/order/show/${id}`, REQUEST_METHOD.GET);
}

export async function adminGeInstallments(page: number, perPage: number) {
    let url = `/api/v1/admin/installment/list?page=${page}&perPage=${perPage}&sortBy=createdAt&order=desc`
    return await fetch<GetInstallmentListResponse, void>(url, REQUEST_METHOD.GET);
}

export async function adminVerifyInvoiceImage(data: InitPaymentBody) {
    return await fetch<any, InitPaymentBody>(`/api/v1/admin/order/invoice/verify`, REQUEST_METHOD.POST, data);
}






