import useOrderListSme from "../../hooks/sme/useOrderListSme";
import Card from "../../components/base/Cards/Card";
import {useState} from "react";
import Pagination from "../../components/base/Pagination/Pagination";
import {Link} from "react-router-dom";
import {RightCircleFilled} from "@ant-design/icons";
import Tag from "../../components/base/tag/Tag";
import {Order} from "../../models/order.model";
import OrderStatus from "../../components/order/OrderStatus";


export default function SmeOrders() {
    const [page, setPageNumber] = useState(1);
    const [status , setStatus] = useState<number>(-10)
    const {isPending, data, total} = useOrderListSme(page , status)
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    <Card title={'List Of Order\'s'}>
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                            <tr>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Supplier
                                </th>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Amount
                                </th>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Status
                                </th>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            {!isPending && data && data.length > 0 && <tbody>
                            {data.map((item: Order) => <tr key={item._id}>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                                    {item.provider_id.desc.title}
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center ">
                                    {item.amount} $
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                                    <OrderStatus status={item.status} paid={item.paid}/>
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                    <Link to={`/sme/order/${item._id}`}><RightCircleFilled/></Link>
                                </td>
                            </tr>)}

                            </tbody>}
                        </table>
                        {!isPending  && <Pagination
                            current={page}
                            pageSize={10}
                            total={total!}
                            onChange={(page: number) => setPageNumber(page)}
                        />
                        }
                    </Card>
                </div>
            </div>

        </>
    )
}