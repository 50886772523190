import React from "react";

import {FacebookFilled, InstagramFilled, TwitterCircleFilled} from '@ant-design/icons'
import {Link} from "react-router-dom";

export default function Footer() {
    return (
        <>
            <footer className="relative bg-blueGray-200 pt-8 pb-6">
                <div
                    className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                    style={{transform: "translateZ(0)"}}
                >
                    <svg
                        className="absolute bottom-0"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-blueGray-200 fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>
                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap text-center lg:text-left">
                        <div className="w-full lg:w-6/12 px-4">
                            <h4 className="text-3xl font-semibold">Let's keep in touch!</h4>
                            <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                                Find us on any of these platforms, we respond 24/7.
                            </h5>
                            <div className="mt-6 lg:mb-0 mb-6">
                                <a className={'cursor-pointer'}>
                                    <TwitterCircleFilled className={'text-4xl mx-2 text-gray-500'}/> </a>
                                <a className={'cursor-pointer'}><FacebookFilled
                                    className={'text-4xl mx-2 text-gray-500'}/></a>
                                <a className={'cursor-pointer'}> <InstagramFilled
                                    className={'text-4xl mx-2 text-gray-500'}/></a>
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="flex flex-wrap items-top mb-6">
                                <div className="w-full lg:w-4/12 px-4 ml-auto">
                                      <span className="block uppercase text-blueGray-500 text-sm font-semibold mb-2">
                                        Useful Links
                                      </span>
                                    <ul className="list-unstyled">
                                        <li>
                                            <Link
                                                className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                                to="/about-us"
                                            >
                                                About Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                                to="/contact-us"
                                            >
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="text-blueGray-600 hover:text-blueGray-800 font-semibold block pb-2 text-sm"
                                                to="/terms"
                                            >
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-6 border-blueGray-300"/>
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-sm text-blueGray-500 font-semibold py-1">
                                Copyright © {new Date().getFullYear()}{" "}
                                <a
                                    href="https://www.creative-tim.com?ref=nr-footer"
                                    className="text-blueGray-500 hover:text-blueGray-800"
                                >
                                    Fatra Team
                                </a>
                                .
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
