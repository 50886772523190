import {useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {getSmeList} from "../../http/admin/admin-request";


export default function useSmeListAdmin (page : number , level : number) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SME_LIST , page , level] ,
        queryFn : () => getSmeList(page , 10 , level)
    })
    return {isPending , data : data?.content.smes , total : data?.content.count}
}