import {QueryKeys} from "../../utils/ReactQueyConstants";
import {smeGetOrderList} from "../../http/sme/sme-requests";
import {useQuery} from "@tanstack/react-query";


export default function useOrderListSme (page : number , status : number ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SME_ORDER_LIST , page , status ] ,
        queryFn : () => smeGetOrderList(page , 10 , status)
    })
    return {isPending , data : data?.content.orders , total : data?.content.count}
}