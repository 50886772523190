import Card from "../../components/base/Cards/Card";
import useInsurance from "../../hooks/admin/useInsurance";
import NoData from "../../components/base/noData";
import {InsuranceModel} from "../../models/insurance.model";
import Button from "../../components/base/Button/Button";
import {OrderedListOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import AppModal from "../../components/base/modal/AppModal";
import classNames from "classnames";


export default function InsuranceList() {

    const [modal, showModal] = useState(false);
    const {data, isPending, changeLoading, changeOrder} = useInsurance(()=> {
        showModal(false)
    })
    const [draggingItem, setDraggingItem] = useState<any>(null);
    const [items, setItems] = useState<InsuranceModel[]>([])
    useEffect(() => {
        if (data) setItems(data)
    }, [data])
    const handleDragStart = (e: any, item: any) => {
        setDraggingItem(item)
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = () => {
        setDraggingItem(null)
    };

    const handleDragOver = (e: any) => {
        e.preventDefault();
    };

    const handleDrop = (e: any, targetItem: any) => {
        // draggingItem, items
        if (!draggingItem) return;
        if (items) {
            const list = [...items];
            const currentIndex = list.indexOf(draggingItem);
            const targetIndex = list.indexOf(targetItem);

            if (currentIndex !== -1 && targetIndex !== -1) {
                list.splice(currentIndex, 1);
                list.splice(targetIndex, 0, draggingItem);
                setItems(list)
            }
        }
    };


    const submit = () => {
        const order = items.map((ins : InsuranceModel ) => ins._id)
        changeOrder(order)
    }

    return (
        <>
            <div className="flex flex-wrap mt-4">

                <div className="w-full">
                    <Card title={'insurances'}>
                        <div className={'flex justify-end gap-x-2 mr-6'}>
                            <Button
                                onClick={() => showModal(true)}
                                className="text-black flex items-center bg-blueGray-200  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                <OrderedListOutlined className={'mr-2'}/>
                                change insurance order
                            </Button>
                        </div>
                        <div>
                            {!isPending && data && data.length > 0 ?
                                <table className="items-center w-full bg-transparent border-collapse">
                                    <thead>
                                    <tr>
                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            }
                                        >
                                            #
                                        </th>

                                        <th
                                            className={
                                                "px-6 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                            }
                                        >
                                            Insurance Name
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((item: InsuranceModel, index) => <tr key={item._id}>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {index + 1}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item.name}
                                        </td>
                                    </tr>)}
                                    </tbody>
                                </table> :
                                <NoData description={'no data'}/>
                            }
                        </div>
                    </Card>
                </div>
            </div>
            {modal && <AppModal
                open={modal}
                onClose={() => showModal(false)}>
                <div className="flex flex-col gap-2 px-2">
                    <h1 className="font-bold">Insurance Priority</h1>
                    {<p className={'text-neutral-500 text-sm'}>Drag & Drop and set priority for all insurances</p>}

                    {
                        items && items.length > 0 && <>
                            {items.map((ins: InsuranceModel) => <div
                                draggable="true"
                                onDragStart={(e) =>
                                    handleDragStart(e, ins)}
                                onDragEnd={handleDragEnd}
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, ins)}
                                className={classNames('cursor-grab border-2 border-solid border-neutral-400 px-3 py-3  text-center text-blueGray-600 bg-white rounded  shadow w-full ease-linear transition-all duration-150',
                                    {'dragging': draggingItem === ins})}>
                                {ins.name}
                            </div>)}
                        </>
                    }

                    <div className="flex gap-3 mt-5 justify-center">
                        <Button
                            onClick={() => showModal(false)}
                            className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                            close
                        </Button>
                        <Button
                            onClick={submit}
                            loading={changeLoading}
                            className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                            submit
                        </Button>
                    </div>
                </div>
            </AppModal>}
        </>
    );
}
