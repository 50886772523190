import Card from "../../components/base/Cards/Card";
import {useState} from "react";
import Pagination from "../../components/base/Pagination/Pagination";
import useOrderListSupplier from "../../hooks/provider/useOrderListSupplier";
import { RightCircleFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import OrderStatus from "components/order/OrderStatus";
import { Order } from "models/order.model";

export default function OrderListSupplier() {
    const [page, setPageNumber] = useState(1);
    const [status , setStatus] = useState<number>(-10)
    const {data, total, isPending} = useOrderListSupplier(page , status)
    console.log("data" , data)
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    <Card title={'list of order\'s'}>
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                            <tr>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    SME
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-center text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Amount
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Status
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            {!isPending && data && <tbody>
                            {data.map((item: Order) => <tr>
                                <td className="border-t-0 px-3  border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                                    <p className={
                                        "text-blueGray-800 font-bold"
                                    }>

                                        {item.sme_id.auth_id.email}
                                    </p>
                                    <p>
                                        {item.sme_id.auth_id.email}
                                    </p>
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 text-center border-r-0 text-xs whitespace-nowrap p-4">
                                    {item.amount} $
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                        <OrderStatus status={item.status} paid={item.paid}/>
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                    <Link to={`/provider/order/${item._id}`}><RightCircleFilled/></Link>
                                </td>
                            </tr>)}

                            </tbody>}
                        </table>

                        {!isPending  && <Pagination
                            current={page}
                            pageSize={10}
                            total={total!}
                            onChange={(page: number) => setPageNumber(page)}
                        />}
                    </Card>
                </div>
            </div>
        </>
    );
}
