import {useMutation} from "@tanstack/react-query";
import {initPayment, payInstallment} from "../../http/sme/sme-requests";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";


export default function useInitPay(onSuccess: (id: string) => void) {
    const navigate = useNavigate()
    const {isPending: paymentLoading, mutate: payment} = useMutation({
        mutationFn: initPayment,
        onSuccess(data) {
            toast.success('payment is done successfully')
            onSuccess(data.content.transactionId)
        },
        onError: () => {
            toast.warning('payment is done')
            navigate('/sme')
        }
    })

    const {isPending: paymentInstLoading, mutate: paymentInst} = useMutation({
        mutationFn: payInstallment,
        onSuccess(data) {
            toast.success('payment is done successfully')
            onSuccess(data.content.transactionId)
        },
        onError: () => {
            toast.warning('payment is done')
            navigate('/sme')
        }
    })

    return {paymentLoading, payment, paymentInstLoading, paymentInst}
}