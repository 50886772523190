import Card from "../../base/Cards/Card";
import useBankShareRate from "../../../hooks/admin/useBankShareRate";
import Button from "../../base/Button/Button";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";

const Schema = Yup.object({
    shareRate: Yup.number().required('enter share rate').min(0).max(.99),
});

export default function BankShareSetting(){
    const { data , updateLoading , update} = useBankShareRate(() => {

    })

    const formik = useFormik({
        initialValues: {
            shareRate: .1,
        },
        validationSchema: Schema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
           update({...data})
        }
    })
    useEffect(() => {
        if (data) formik.setValues({shareRate : data})
    } , [data])
    return (
        <div className="flex flex-wrap mt-4">
            <div className="w-full">
                <Card  title={'share bank setting'} >
                    <div className={'flex flex-row justify-start'}>
                        <div className={'md:w-1/3 sm:w-full xs:w-full'}>
                            <div className="flex flex-col gap-2 px-2">
                                <h1 className="font-bold"> Change Share Rate</h1>
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="relative w-full my-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                            htmlFor="shareRate"
                                        >
                                            Bank Share Rate
                                        </label>
                                        <input
                                            id={'shareRate'}
                                            name={'shareRate'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.shareRate}
                                            type="text"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Share Rate"
                                        />
                                        {formik.errors.shareRate ?
                                            <p className={'text-red text-xs h-4 pt-1'}>{formik.errors.shareRate}</p> :
                                            <p className={'h-4 pt-1'}>{" "}</p>}
                                    </div>

                                    <div className="w-full flex justify-center gap-3 mb-5">
                                        <Button
                                            loading={updateLoading}
                                            className="w-full text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none  bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                            type={'submit'}
                                        >
                                            change
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}