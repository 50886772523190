import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {supplierGetOrderList} from "../../http/provider/provider-requests";


export default function useOrderListSupplier (page : number , status : number ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.PROVIDER_ORDER_LIST , page , status ] ,
        queryFn : () => supplierGetOrderList(page , 10 , status)
    })
    return {isPending , data : data?.content.orders , total : data?.content.orders.count}
}