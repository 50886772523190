import {QueryKeys} from "../../utils/ReactQueyConstants";
import {getSme} from "../../http/bank/bank-request";
import {useQuery} from "@tanstack/react-query";


export default function useSmeBank (
    id : string ,
) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.BANK_SME , id] ,
        queryFn : () => getSme(id)
    })
    return {
        data : data?.content.sme ,
        isPending
    }
}