import {QueryKeys} from "../../utils/ReactQueyConstants";
import {
    changeBankShareRate, getBankShareRate
} from "../../http/admin/admin-request";
import {toast} from "react-toastify";
import {useMutation, useQuery} from "@tanstack/react-query";


export default  function  useBankShareRate(onSuccessChange : () => void){
    const {data , isPending , refetch } = useQuery({
        queryKey  : [QueryKeys.ADMIN_SHARE_RATE] ,
        queryFn : getBankShareRate
    })
    const { isPending : updateLoading ,  mutate : update} = useMutation({
        mutationFn : changeBankShareRate ,
        onSuccess : () => {
            refetch();
            onSuccessChange()
            toast.success('done!')

        }
    })
    return {data : data?.content.shareRate , isPending , updateLoading , update}
}