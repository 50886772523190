import BankVerifiedLogo from 'assets/img/other/bank_verified.png';
import {Credit} from 'models/sme.profile.model';
import {FC} from 'react';
import {SME_LEVEL} from "../../../utils/smeLevels";
import classNames from "classnames";

const Approved: FC<Credit & { installments?: number [] } & { level: number }> = ({
                                                                                     amount,
                                                                                     request,
                                                                                     spend,
                                                                                     level,
                                                                                     installments
                                                                                 }) => {
    return (
        <div className="w-full py-10 text-center h-full flex flex-col gap-5 justify-center items-center">

            <img width={250} alt="" src={BankVerifiedLogo}/>

            <p className="sm:w-1/2 text-2xl font-bold">
                You are approved by the Insurances
            </p>

            <div className='w-full justify-center flex flex-col sm:flex-row gap-3 whitespace-nowrap'>
                <div className='text-center text-blueGray-800 shadow-lg'>
                    <p className="text-lg font-bold py-2 px-5 bg-yellow-200 rounded-t-lg">Requested Credit</p>
                    <p className="py-2 px-5 bg-yellow-300">{request}</p>
                </div>
                <div className='text-center text-blueGray-800 shadow-lg'>
                    <p className="text-lg font-bold py-2 px-5 bg-blue-200 rounded-t-lg">
                        Allocated Credit
                    </p>
                    <p className="py-2 px-5 bg-blue-300">{amount}</p>
                </div>
                <div className='text-center text-blueGray-800 shadow-lg'>
                    <p className="text-lg font-bold py-2 px-5 bg-green-200 rounded-t-lg">
                        Spent Credit
                    </p>
                    <p className="py-2 px-5 bg-green-300">{spend}</p>
                </div>
            </div>

            {level === SME_LEVEL.AdminActivated && installments &&
                <>
                    <div>
                        <h4 className="text-center text-lg font-bold my-1">
                            Your Installments
                        </h4>
                        <p className={'text-center text-sm text-neutral-500'}>you pay 1th payment when you are creating order</p>
                    </div>
                    <div className={'flex flex-row flex-wrap justify-center'}>
                        {installments.map((ins: number, index) => <div
                            className='sm:w-1/2  xs:w-full md:w-1/3 p-2 '>
                            <div className={'text-center text-blueGray-800 shadow-lg'}>
                                <p className={classNames('text-lg font-bold py-2 px-5  rounded-t-lg' , {'bg-yellow-200' : index !==0 , 'bg-green-200' : index === 0} )}>{`${index + 1}th payment`}</p>
                                <p className={classNames('py-2 px-5' , {'bg-yellow-300' : index !==0 , 'bg-green-300' : index === 0} )}>{`${ins * 100} %`}</p>
                            </div>
                        </div>)}
                    </div>


                </>}
        </div>
    );
}

export default Approved;