import {useState} from "react";
import Button from "../../base/Button/Button";
import {CloseCircleFilled, PlusCircleFilled} from "@ant-design/icons";
import {toast} from "react-toastify";

interface Props {
    installments : number[] ,
    onClose : () => void ,
    loading : boolean ,
    onSubmit : (list : number[]) => void
}
export default function SmeInstallment ({installments , onClose , onSubmit , loading} : Props) {
    const [installment , setInstallment] = useState<{key : number , loan : string|number}[]>(() => {
        return installments.map((i , index )=> {
            return {
                key : index , loan : i * 100
            }
        })
    })
    const onChange = (index : number , value : string) =>  {
        if (value) {
            const list = [...installment] ;
            list[index].loan = Number(value);
            setInstallment(list)
        }else {
            const list = [...installment] ;
            list[index].loan = "";
            setInstallment(list)
        }

    }
    const addInstallment = () => {
        setInstallment(prev => [...prev , {key : prev.length , loan: 10}])
    }
    const removeInstallment = (index : number) => {
        const remove = installment.filter(i => i.key !== index);
        const newList = remove.map((i , index )=> {
            return {
                key : index , loan : i.loan
            }
        })
        setInstallment(newList)
    }

    const submit = () => {
        let total = 0;
        let final :number[] =[]
        for (let item of installment){
            if (!item.loan) {
                return toast.warning('please be careful in enter installments')
            }
            if (item.loan === 0){
                return toast.warning('zero percent is invalid')
            }
            if (isNaN(Number(item.loan))){
                return toast.warning('please be careful in enter installments')
            }
            total = total + Number(item.loan)
            final.push(Number(item.loan) / 100)
        }
        if (total !== 100){
           return  toast.warning('sum of installments should be 100 %')
        }
        return  onSubmit(final)

    }
    return (
        <div className="flex flex-col gap-2 px-2">
            <h1 className="font-bold">SME Installments</h1>
            <div className={'flex flex-row justify-between items-center mb-3'}>
                <h4>manage installments for this SME</h4>
                <Button onClick={() => addInstallment()}>
                    <PlusCircleFilled className={'text-green-500'}/>
                </Button>
            </div>
            {installment.map((l : any , index ) => <div key={l.key} className={'relative'}>
                <input
                    id={`loan${index}`}
                    name={`loan${index}`}
                    type="number"
                    onChange={(event) => onChange(index , event.target.value)}
                    value={l.loan ? l.loan  : ''}
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="percent"
                />
                <Button className={'absolute right-2 top-1'} onClick={() => removeInstallment(l.key)}>
                    <CloseCircleFilled className={'text-red-dark'} />
                </Button>
            </div> )}

            <div className="flex gap-3 mt-5 justify-center">
                <button
                    onClick={() => onClose()}
                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                    close
                </button>
                <Button
                    onClick={submit}
                    loading={loading}
                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                    set
                </Button>
            </div>
        </div>
    )
}