import {Installment} from "../../models/order.model";
import {CheckCircleFilled} from "@ant-design/icons";
import Tag from "../base/tag/Tag";
import Button from "../base/Button/Button";
import React from "react";
import StorageKeys from "../../utils/StorageKeys";
import {Role} from "../../models/auth.model";
import useInitPay from "../../hooks/sme/useInitPay";
import {useNavigate, useParams} from "react-router-dom";


interface OrderInstallmentsProps {
    installments: Installment[]
}

export default function OrderInstallments({installments}: OrderInstallmentsProps) {
    const navigate = useNavigate()
    const {id} = useParams()
    const {paymentInstLoading , paymentInst} = useInitPay((id) => {
        navigate(`/sme/payment-result/${id}`)
    })
    return <>
        {localStorage.getItem(StorageKeys.ROLE) === Role.SME && <div className={'flex justify-end gap-x-2 mr-6 mb-2'}>
            <Button
                loading={paymentInstLoading}
                type={'button'}
                onClick={() => paymentInst({
                    orderId : id!
                })}
                className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
                <CheckCircleFilled className={'mr-2'}/>
                pay last installment
            </Button>
        </div>}
        <table className="items-center w-full bg-transparent border-collapse">
            <thead>
            <tr>
                <th
                    className={
                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    }
                >
                    #
                </th>
                <th
                    className={
                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    }
                >
                    Percent
                </th>
                <th
                    className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    }
                >
                    Amount
                </th>
                <th
                    className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    }
                >
                    status
                </th>
                <th
                    className={
                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    }
                >
                    Deadline
                </th>
            </tr>
            </thead>
            <tbody>
            {installments.map((item: Installment, index) => <tr key={item._id}>
                <td className="border-t-0 px-3  border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                    <p className={
                        "m-0"
                    }>

                        {index + 1}
                    </p>
                </td>
                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0  whitespace-nowrap p-4 items-center ">
                    <p className={
                        "text-blueGray-800 font-bold"
                    }>
                        {item.rate * 100} %
                    </p>
                </td>

                <td className="border-t-0 px-6 align-middle border-l-0 text-blueGray-800 font-bold  border-r-0  whitespace-nowrap p-4">
                    {item.amount} $
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0  whitespace-nowrap p-4 text-center">
                    {item.paid.at && <Tag icon={<CheckCircleFilled/>} color={'green'}>
                        paid
                    </Tag>}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-xs whitespace-nowrap p-4 text-center">
                    {item.paid.deadline && <p>
                        {new Intl.DateTimeFormat('en-GB', {
                            dateStyle: 'full',
                        }).format(new Date(item.paid.deadline))}
                    </p>}
                </td>
            </tr>)}
            </tbody>
        </table>
    </>

}