import Card from "components/base/Cards/Card";
import Approved from "components/supplier/steps/Approved";
import ConditionallyRejected from "components/supplier/steps/ConditionallyRejected";
import FullyRejected from "components/supplier/steps/FullyRejected";
import WaitingForAdmin from "components/supplier/steps/WaitingForAdmin";
import { useProviderLogs } from "hooks/provider/useProviderVerification";
import { PROVIDER_LEVEL, PROVIDER_REJECT_LEVEL } from "utils/providerLevels";
import AddInformation from "../../components/supplier/steps/AddInformation";
import EmailVerification from "../../components/supplier/steps/EmailVerification";
import LayoutWrapper from "../../components/supplier/steps/LayoutWrapper";
import PhoneVerification from "../../components/supplier/steps/PhoneVerification";
import UploadDocuments from "../../components/supplier/steps/UploadDocuments";
import {ProviderModel} from "../../models/provider.profile.model";

export interface IProviderChild {
    levelUp: (level: PROVIDER_LEVEL) => void
}

function LogRow({ title, desc }: { title: string, desc: string | number }) {
    return (
        <div className="w-full text-sm flex items-center px-3">
            <p className="text-blueGray-600 font-bold">
                {title}:
            </p>
            <p className="px-3">{desc}</p>
        </div>
    )
}
interface SmePanelProps {
    currentLevel: PROVIDER_LEVEL | PROVIDER_REJECT_LEVEL,
    providerProfile: ProviderModel ,
    refetch : () => void
}
const ProviderPanel = ( {currentLevel , providerProfile , refetch}: SmePanelProps) => {
    const {
        getLogList,
        getLogsLoading,
        logs
    } = useProviderLogs();
    function LogView() {
        return (
            <Card title="Verification Timeline">
                <div className="p-3 w-full grid gap-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                    {
                        getLogsLoading ? <p>Loading Logs</p> :
                            logs?.map((log) => (
                                <div key={log._id} className="border-2 border-gray rounded-lg border-dotted">
                                    <p className="p-2 rounded-t-lg w-full text-white bg-blue-700">
                                        {log.createdAt}
                                    </p>
                                    <div className="px-2 py-3">
                                        <LogRow
                                            title="Description"
                                            desc={log.desc}
                                        />
                                        <LogRow
                                            title="Level"
                                            desc={log.level}
                                        />
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </Card>
        )
    }

    return (
        <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12">
                <Card title={'Verification'}>
                    <LayoutWrapper steps={4} activeStep={currentLevel}>
                                <>
                                    {currentLevel === PROVIDER_LEVEL.Registered && <EmailVerification userEmail={providerProfile.auth_id.email} levelUp={refetch} />}
                                    {currentLevel === PROVIDER_LEVEL.EmailVerified && <PhoneVerification levelUp={refetch} />}
                                    {currentLevel === PROVIDER_LEVEL.PhoneVerified && <AddInformation level={currentLevel} levelUp={refetch} />}
                                    {currentLevel === PROVIDER_LEVEL.InfoAdded && <UploadDocuments levelUp={refetch} />}
                                    {currentLevel === PROVIDER_LEVEL.DocsUploaded && <WaitingForAdmin />}
                                    {currentLevel === PROVIDER_LEVEL.AdminVerified && <Approved />}
                                    {/*{currentLevel === PROVIDER_REJECT_LEVEL.ConditionalRejected && <ConditionallyRejected levelUp={refetch} />}*/}
                                    {currentLevel === PROVIDER_REJECT_LEVEL.ConditionalRejected && <AddInformation level={currentLevel}  levelUp={refetch} />}
                                    {
                                        currentLevel === PROVIDER_REJECT_LEVEL.FullRejected &&
                                        <FullyRejected level={currentLevel} />
                                    }
                                </>
                    </LayoutWrapper>
                </Card>

                <LogView />
            </div>
        </div>
    );
}

export default ProviderPanel;