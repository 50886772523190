import Sidebar from "components/base/Sidebar/Sidebar";
import SupplierProfile from "components/supplier/profile/SupplierProfile";
import {Navigate, Route, Routes} from "react-router-dom";
import ProviderPanel from "views/provider/ProviderVerification";
import {Role} from "../../models/auth.model";
import StorageKeys from "../../utils/StorageKeys";
import {getLevel} from "../../utils/StorageUtils";
import Dashboard from "../../views/admin/Dashboard";
import OrderListInsurance from "../../views/insurance/OrderListInsurance";
import OrderListSupplier from "../../views/provider/OrderListSupplier";
import OrderDetailSupplier from "views/provider/OrderDetailSupplier";
import {useProviderProfile} from "../../hooks/provider/useProviderVerification";
import {useMemo} from "react";
import {SME_LEVEL} from "../../utils/smeLevels";
import {PROVIDER_LEVEL} from "../../utils/providerLevels";


export default function Supplier() {
    const {isLoading: isLoadingProfile, refetch: getProfile, providerProfile} = useProviderProfile();
    const menu: { title: string, path: string }[] = useMemo(() => {
        if (providerProfile) {
            if (providerProfile.level === PROVIDER_LEVEL.AdminVerified) return [
                {
                    title: 'profile',
                    path: '/provider/profile'
                },
                {
                    title: 'dashboard',
                    path: '/provider/dashboard'
                },
                {
                    title: 'Order\'s',
                    path: '/provider/orders'
                },
            ]
            else return [
                {
                    title: 'Verification',
                    path: '/provider/verification'
                },
                {
                    title: 'Profile',
                    path: '/provider/profile'
                }
            ]
        } else return []
    }, [providerProfile])

    return (
        <>
            {isLoadingProfile ? <p className={'text-center'}>loading</p> : <>
                <Sidebar menu={menu} title={'Supplier'}/>
                <div className="relative md:ml-64 bg-blueGray-100">
                    <div className="px-2 mx-auto w-full py-2">
                        <Routes>
                            <Route path="dashboard" element={<Dashboard/>}/>
                            <Route path="verification"
                                   element={<ProviderPanel refetch={getProfile} providerProfile={providerProfile!}
                                                           currentLevel={providerProfile?.level!}/>}/>
                            <Route path="profile" element={<SupplierProfile  providerProfile={providerProfile!}/>}/>
                            <Route path="orders" element={<OrderListSupplier/>}/>
                            <Route path="order/:id" element={<OrderDetailSupplier/>}/>
                            <Route
                                path="*"
                                element={<Navigate to={providerProfile?.level === PROVIDER_LEVEL.AdminVerified ? 'profile' : 'verification'}
                                                   replace={true}/>}
                            />
                        </Routes>
                    </div>
                </div>
            </>}
        </>
    );
}
