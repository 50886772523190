import {useParams} from "react-router-dom";
import Card from "../../components/base/Cards/Card";
import OrderDetail from "../../components/order/OrderDetail";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import useOrderSupplier from "hooks/provider/useOrderSupplier";
import {OrderStatusEnum} from "../../models/order.model";
import OrderInvoice from "../../components/supplier/OrderInvoice";
import SupplierOrderInvoiceUpload from "../../components/supplier/SupplierOrderInvoiceUpload";

export default function OrderDetailSupplier() {
    const {id} = useParams()
    const {data, isPending} = useOrderSupplier(id!)
    return <div className="flex flex-wrap mt-4">
        <div className="w-full mb-4">
            <Card title={'Order Detail'}>
                {isPending ? <Loading3QuartersOutlined/> :
                    <OrderDetail showDetail={false} order={data!}/>}
            </Card>
        </div>
        {!isPending && data?.status === OrderStatusEnum.delivered && <div className="w-full mb-12">
            <Card title={'upload Invoice'}>
                <SupplierOrderInvoiceUpload/>
            </Card>
        </div>}
        {!isPending && data && data.status >= OrderStatusEnum.uploadedInvoice && <div className="w-full mb-12">
            <Card title={'Order Invoice Image'}>
                <OrderInvoice order={data!}/>
            </Card>
        </div>}
    </div>
}