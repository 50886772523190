import {QueryKeys} from "../../utils/ReactQueyConstants";
import {getSupplier} from "../../http/admin/admin-request";
import {useQuery} from "@tanstack/react-query";


export default function useSupplierAdmin (id : string) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SUPPLIER , id] ,
        queryFn : () => getSupplier(id)
    })
    return {data : data?.content.provider , isPending}
}