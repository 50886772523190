import {FC, useEffect, useRef, useState} from "react";
import {ISmeChild} from "../../../views/sme/SmeVerification";
import {SME_LEVEL} from "utils/smeLevels";
import {useAddPhone, useResendVerificationEmail, useValidatePhone, useVerifyEmail} from "hooks/sme/useSmeVerification";
import {Select, TextInput} from "flowbite-react";
import Backdrop from "components/base/Backdrop";
import Button from "components/base/Button/Button";
import countryCodes from "../../../utils/countryCodes";

type FormObject<T> = {
    value: T,
    error: boolean
}

const initInputState = {
    error: false,
    value: ''
} as FormObject<string>;


const PhoneVerification: FC<ISmeChild> = ({levelUp}) => {

    const [phone, setPhone] = useState<FormObject<string>>(initInputState);
    const [countryCode, setCountryCode] = useState(countryCodes[0].code)
    const [code, setCode] = useState<FormObject<string>>(initInputState);
    const [countdown, setCountDown] = useState<{ start: boolean, value: number }>({
        start: false, value: 0
    });

    const {
        addPhone,
        addPhoneLoading,
        addPhoneResponse
    } = useAddPhone();

    const {
        phoneValidationLoading,
        phoneValidationResponse,
        validatePhone
    } = useValidatePhone();

    useEffect(() => {
        if (phoneValidationResponse?.status) {
            levelUp(SME_LEVEL.PhoneVerified)
        }
    }, [phoneValidationResponse]);

    function handleResendCode() {
        if (phone.value.length === 0) {
            setPhone(prevState => ({...prevState, error: true}))
            return;
        }
        addPhone({phone: phone.value , countryCode })
    }

    useEffect(() => {
        if (addPhoneResponse?.status) {
            alert('Your code is: ' + addPhoneResponse?.content.otp);
            setCountDown({
                start: true,
                value: 20
            })
        }
    }, [addPhoneResponse]);

    function onSubmit() {
        if (code.value.length === 0) {
            setCode(prevState => ({...prevState, error: true}))
            return;
        }
        validatePhone({otp: Number(code.value)})
    }

    function onPhoneChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setPhone({error: false, value: event.target.value})
    }

    function onOtpChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setCode({error: false, value: event.target.value})
    }

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (countdown.start) {
            interval = setInterval(() => {
                if (countdown.value > 0) {
                    setCountDown(prev => ({...prev, value: --prev.value}));
                } else {
                    clearInterval(interval);
                    setCountDown({
                        start: false,
                        value: 0
                    })
                }
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        }
    }, [countdown]);

    return (
        <div className="flex flex-col w-full lg:w-1/2 h-full p-3 gap-10 justify-center items-center">
            <p className="font-bold text-3xl">SME Verifying Phone Number</p>
            <p className="w-full text-justify">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <div className="w-full flex gap-2">
                <Select
                    value={countryCode}
                    onChange={(e) => setCountryCode(e.target.value)}
                >
                    {countryCodes.map((c: any) => <option value={c.code} key={c.name}>
                        {c.name}
                    </option>)}
                </Select>
                <TextInput disabled={countdown.start} id="phone" type="tel" placeholder="Phone Number..." required
                           className="flex-grow"
                           color={phone.error ? "warning" : "gray"}
                           value={phone.value} onChange={onPhoneChanged}/>
                <Button
                    loading={addPhoneLoading}
                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 whitespace-nowrap 
                bg-blue-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    disabled={countdown.start || addPhoneLoading}
                    onClick={handleResendCode}>
                    {countdown.start ? countdown.value : "Get Code"}
                </Button>
            </div>

            <div className="w-full flex gap-2">
                <TextInput type="number" placeholder="OTP Code*" required
                           color={code.error ? "warning" : "gray"}
                           value={code.value} onChange={onOtpChanged}/>

                <Button
                    loading={phoneValidationLoading}
                    className="w-full text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 whitespace-nowrap 
                      bg-green-800 flex-grow  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    disabled={phoneValidationLoading || code.value.length != 4}
                    onClick={() => onSubmit()}>
                    Submit Code
                </Button>
            </div>
        </div>
    );
}

export default PhoneVerification;