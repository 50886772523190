import Button from "components/base/Button/Button";

import Banned from 'assets/img/other/banned.png';
import { SME_LEVEL, SME_REJECT_LEVEL, SME_REJECT_REASON } from "utils/smeLevels";
import { FC } from "react";
import { ISmeChild } from "views/sme/SmeVerification";

const FullyRejected: FC<{ level: SME_REJECT_LEVEL }> = ({ level }) => {
    return (
        <div className="w-1/2 py-10 text-center h-full flex flex-col gap-5 justify-center items-center">

            <img alt="" width={250} src={Banned} />

            <p className="text-2xl font-bold">{SME_REJECT_REASON[level]}</p>

        </div>
    );
}

export default FullyRejected;