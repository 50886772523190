import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {insuranceGetOrder} from "../../http/insurance/insurance-request";


export default function useOrderInsurance (id : string ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.INSURANCE_ORDER , id ] ,
        queryFn : () => insuranceGetOrder(id)
    })
    return {isPending , data : data?.content.order }
}