import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {bankGetOrder} from "../../http/bank/bank-request";


export default function useOrderBank (id : string ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.BANK_ORDER , id ] ,
        queryFn : () => bankGetOrder(id)
    })
    return {isPending , data : data?.content.order }
}