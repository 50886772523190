import CustomStepView from "components/base/stepview/StepView";
import React, { FC } from "react";

interface LayoutWrapperProps {
    steps: number,
    activeStep: number,
    children: React.ReactNode
}

const LayoutWrapper: FC<LayoutWrapperProps> = ({
    children, activeStep, steps
}) => {
    return (
        <div className="w-full h-full flex p-5">
            {/*<Sidebar name="PROVIDER" role={Role.SUPPLIER} />*/}
            <div className="hide-scroll flex flex-col w-full h-full items-center p-3 rounded-lg overflow-hidden overflow-y-scroll">
                <CustomStepView
                    direction="horizontal"
                    steps={steps}
                    activeStep={activeStep} />
                <div className="flex flex-col justify-center items-center w-full h-full">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default LayoutWrapper;