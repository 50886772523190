import React, { FC } from "react";

// interface StepViewProps {
//     steps: number,
//     acitveStep: number
// }

// const VerticalStepView: FC<StepViewProps> = ({
//     steps,
//     acitveStep
// }) => {
//     return (
//         <div className="flex flex-col m-2 items-center hide-scroll">

//             {
//                 Array.from({ length: steps }).map((num, index) => {
//                     return (
//                         <React.Fragment key={index}>
//                             <div className={`flex rounded-full border-solid border-4 p-0.5 h-12 w-12
//                             ${acitveStep >= index ? "border-blue-500" : "border-gray-dark"}`}>
//                                 <div className={`${acitveStep >= index ? "bg-blue-500" : "bg-gray-dark"} 
//                                                 flex justify-center items-center h-full w-full rounded-full`}>
//                                     <h1 className="text-white">
//                                         {index + 1}
//                                     </h1>
//                                 </div>
//                             </div>
//                             {
//                                 (index !== (steps - 1)) &&
//                                 < div className={`h-20 w-2 ${acitveStep >= index ? "bg-blue-500" : "bg-gray-dark"}`} />
//                             }
//                         </React.Fragment>
//                     )
//                 })
//             }

//         </div>
//     );
// }
// const HorizontalStepView: FC<StepViewProps> = ({
//     steps,
//     acitveStep
// }) => {
//     return (
//         <div className="flex m-2 items-center hide-scroll">

//             {
//                 Array.from({ length: steps }).map((num, index) => {
//                     return (
//                         <React.Fragment key={index}>
//                             <div className={`flex rounded-full border-solid border-4 p-0.5 h-12 w-12
//                             ${acitveStep >= index ? "border-blue-500" : "border-gray-dark"}`}>
//                                 <div className={`${acitveStep >= index ? "bg-blue-500" : "bg-gray-dark"} 
//                                                 flex justify-center items-center h-full w-full rounded-full`}>
//                                     <h1 className="text-white">
//                                         {index + 1}
//                                     </h1>
//                                 </div>
//                             </div>
//                             {
//                                 (index !== (steps - 1)) &&
//                                 < div className={`h-2 w-20 ${acitveStep >= index ? "bg-blue-500" : "bg-gray-dark"}`} />
//                             }
//                         </React.Fragment>
//                     )
//                 })
//             }

//         </div>
//     );
// }

interface StepViewProps {
    steps: number;
    activeStep: number;
    direction: "vertical" | "horizontal";
}

const CustomStepView: FC<StepViewProps> = ({
    steps,
    activeStep,
    direction
}) => {
    const isVertical = direction === "vertical";

    return (
        <div className={`flex m-2 items-center justify-center hide-scroll ${isVertical ? "flex-col" : ""}`}>
            {Array.from({ length: steps }).map((_, index) => (
                <React.Fragment key={index}>
                    <div
                        className={`flex rounded-full border-solid border-4 p-0.5 h-10 w-10
                            ${activeStep > index ? "border-blue-500" : "border-gray-dark"}`}
                    >
                        <div
                            className={`${activeStep > index ? "bg-blue-500" : "bg-gray-dark"} 
                                flex justify-center items-center h-full w-full rounded-full`}
                        >
                            <h1 className="text-white">{index + 1}</h1>
                        </div>
                    </div>
                    {index !== (steps - 1) && (
                        <div
                            className={`h-${isVertical ? "16" : "2"} w-${isVertical ? "2" : "16"} 
                            ${activeStep > index ? "bg-blue-500" : "bg-gray-dark"}`}
                        />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default CustomStepView;

// export { VerticalStepView, HorizontalStepView };