
export enum SME_REJECT_LEVEL {
    RejectedByInsurances = -6,
    ConditionalRejected = -2,
    FullRejected = -1,
}

export enum SME_LEVEL {
    // None,
    Registered,
    EmailVerified,
    PhoneVerified,
    InfoAdded,
    DocsUploaded,
    AdminVerified,
    BankVerified,
    InsuranceVerified,
    AdminActivated
}

export const SME_REJECT_REASON = {
    [SME_REJECT_LEVEL.RejectedByInsurances]: "Unfortunately you are rejected by the Insurances !",
    [SME_REJECT_LEVEL.ConditionalRejected]: "Your documents are not applied. Your level is downgraded !", 
    [SME_REJECT_LEVEL.FullRejected]: "Unfortunately you are banned !!!"
}

export const SmeLevelsForAdmin : any = {
    0 : {
        title : 'Registered'
    } ,
    1 : {
        title : 'Email Verified'
    } ,
    2 : {
        title : 'Phone Verified'
    } ,
    3 : {
        title : 'Info Added'
    } ,
    4 : {
        title : 'Docs Uploaded'
    } ,
    5 : {
        title : 'Admin Verified'
    } ,
    6 : {
        title : 'Bank Verified'
    } ,
    7 : {
        title : 'Insurance Verified'
    } ,
    8 : {
        title : 'Admin Activated'
    } ,
    '-1' : {
        title : 'Full Rejected'
    } ,
    '-2' : {
        title : 'Conditional Rejected'
    } ,
    '-6' : {
        title : 'All Insurance Rejected'
    } ,
}
export const SmeLevelsForBank : any = {
    5 : {
        title : 'Admin Verified'
    } ,
    6 : {
        title : 'Bank Verified'
    } ,
    7 : {
        title : 'Insurance Verified'
    } ,
    8 : {
        title : 'Admin Activated'
    } ,
}
export const SmeLevelsForInsurance : any = {
    6 : {
        title : 'Bank Verified'
    } ,
    7 : {
        title : 'Insurance Verified'
    } ,
    8 : {
        title : 'Admin Activated'
    } ,
}
