import {activeSme, rejectSme, verifySme} from "../../http/admin/admin-request";
import {useMutation} from "@tanstack/react-query";


export default function useSmeMutateAdmin(
    onSuccessVerify: () => void,
    onSuccessReject: () => void,
    onSuccessActive?: () => void,
) {

    const {isPending: verifyLoading, mutate: verify} = useMutation({
        mutationFn: verifySme,
        onSuccess() {
            onSuccessVerify()
        }
    })
    const {isPending: rejectLoading, mutate: reject} = useMutation({
        mutationFn: rejectSme,
        onSuccess() {
            onSuccessReject()
        }
    })
    const {isPending: activeLoading, mutate: setInstallments} = useMutation({
        mutationFn: activeSme,
        onSuccess() {
            if (onSuccessActive) onSuccessActive()
        }
    })
    return {
        verifyLoading, verify,
        rejectLoading, reject ,
        activeLoading , setInstallments
    }
}