import {FC, ReactNode} from "react";

const ProfileRow: FC<{ title: string, value?: ReactNode }> = ({ title, value }) => {

    return (
        <div className="w-full  flex drop-shadow-xl bg-white  justify-between rounded-lg border-l-8
                    border-l-blue-500 p-2 items-start">
            <p className="text-neutral-500  text-sm flex-none">{title}</p>
            <div className={'flex-auto'}>
                <p className=" text-end break-words">{value}</p>
            </div>
        </div>
    )
}

export default ProfileRow