import Card from "../../components/base/Cards/Card";
import useOrderListAdmin from "../../hooks/admin/useOrderListAdmin";
import {useState} from "react";
import Pagination from "../../components/base/Pagination/Pagination";

import {Link} from "react-router-dom";
import {RightCircleFilled} from "@ant-design/icons";
import {Order, OrderStatusEnum, OrderStatuses} from "../../models/order.model";
import OrderStatus from "../../components/order/OrderStatus";
import {Label, Select} from "flowbite-react";
import {SmeLevelsForAdmin} from "../../utils/smeLevels";

export default function OrderListAdmin() {
    const [page, setPageNumber] = useState(1);
    const [status , setStatus] = useState<number>(-10)
    const {data, total, isPending} = useOrderListAdmin(page , status)
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    <Card title={'list of order\'s'}>
                        <div className={'px-4'}>
                            <div className={'flex flex-row flex-wrap mb-4'}>
                                <div className={'md:w-1/4 w-full '}>
                                    <div className="mb-2 block">
                                        <Label htmlFor="levels" value="Order Status"/>
                                    </div>
                                    <Select value={status} id="levels" onChange={(event) => setStatus(Number(event.target.value))}>
                                        <option key={'all'} value={-10}>
                                            All
                                        </option>
                                        {Object.keys(OrderStatuses).map((item : string) => <option key={item} value={Number(item)}>
                                            {OrderStatuses[item].title}
                                        </option>)}
                                    </Select>
                                </div>
                            </div>
                            <table className="items-center w-full bg-transparent border-collapse">
                                <thead>
                                <tr>
                                    <th
                                        className={
                                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        }
                                    >
                                        SME
                                    </th>
                                    <th
                                        className={
                                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        }
                                    >
                                        Supplier
                                    </th>
                                    <th
                                        className={
                                            "px-6 align-middle border border-solid py-3 text-center text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        }
                                    >
                                        Amount
                                    </th>
                                    <th
                                        className={
                                            "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        }
                                    >
                                        Status
                                    </th>
                                    <th
                                        className={
                                            "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                        }
                                    >
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                {!isPending && data && <tbody>
                                {data.map((item: Order) => <tr>
                                    <td className="border-t-0 px-3  border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                                        <p className={
                                            "text-blueGray-800 font-bold"
                                        }>

                                            {item.sme_id.auth_id.email}
                                        </p>
                                        <p>
                                            {item.sme_id.auth_id.email}
                                        </p>
                                    </td>
                                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center ">
                                        <p className={
                                            "text-blueGray-800 font-bold"
                                        }>
                                            {item.provider_id.desc.title}
                                        </p>
                                        <p>
                                            {item.provider_id.auth_id.email}
                                        </p>
                                    </td>

                                    <td className="border-t-0 px-6 align-middle border-l-0 text-center border-r-0 text-xs whitespace-nowrap p-4">
                                        {item.amount} $
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                        <OrderStatus status={item.status} paid={item.paid}/>
                                    </td>
                                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                                        <Link to={`/admin/order/${item._id}`}><RightCircleFilled/></Link>
                                    </td>
                                </tr>)}

                                </tbody>}
                            </table>
                            {!isPending  && <Pagination
                                current={page}
                                pageSize={10}
                                total={total!}
                                onChange={(page: number) => setPageNumber(page)}
                            />}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}
