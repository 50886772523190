import Footer from 'components/base/Footers/Footer';
import IndexNavbar from 'components/base/Navbars/IndexNavbar';

const Text = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod\
                tempor incididunt ut labore et dolore magna aliqua.\
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod\
                tempor incididunt ut labore et dolore magna aliqua.";

const AvatarText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit';

const ContactPage = () => {
    return (
        <>
            <IndexNavbar fixed />
            <div className='overflow-x-hidden'>
                <div className='bg-blueGray-700 pb-32 w-screen overflow-x-hidden overflow-y-scroll flex flex-col items-center'>
                    <div
                        className="bg-cover  w-screen px-6 items-center flex justify-end"
                        style={{
                            height: "450px",
                            backgroundImage: `url('/assets/images/landing/p1.png')`,
                            backgroundRepeat: "no-repeat"
                        }}>

                        <div className='max-w-xl p-3 flex flex-col gap-6 items-start'>
                            <p className='text-gray-100 italic w-full text-4xl font-extrabold drop-shadow'>
                                Contact Us
                            </p>
                            <p className='text-gray-100 text-2xl drop-shadow text-justify'>
                                We recognize that the best technologies and processes
                                are useless without the best team.
                                We recognize that the best technologies and processes
                            </p>

                            <div
                                className="bg-blue-500 text-white  px-3 py-1 flex rounded items-center justify-center gap-6 hover:cursor-pointer w-full md:w-fit"
                            >
                                <img alt='' src='/assets/images/social/call.png' className='w-10 p-1' />
                                <span className='font-bold text-nowrap'>+1 22 666 589</span>
                            </div>
                        </div>

                    </div>

                    <div className='lg:w-1/2 w-fit flex gap-6 flex-col lg:flex-row'>

                        <img
                            alt=''
                            src='/assets/images/map.webp'
                            className='flex justify-center items-center leading-6 drop-shadow rounded
                                    text-justify gap-5 bg-blueGray-600 h-96 -mt-10'/>

                        <div className='flex flex-col justify-center p-3 gap-3'>

                            <div className='drop-shadow p-3 flex gap-3'>
                                <img src='/assets/images/social/linkedin.png' alt='' className='rounded-full w-16 h-16' />
                                <span className='hover:cursor-pointer'>
                                <p className='text-2xl font-extrabold text-white'>Linkedin</p>
                                <p className='text-white overflow-hidden mt-3'>linkedin/skeimasi</p>
                            </span>
                            </div>

                            <div className='drop-shadow p-3 flex gap-3'>
                                <img src='/assets/images/social/whatsapp.png' alt='' className='rounded-full w-16 h-16' />
                                <span className='hover:cursor-pointer'>
                                <p className='text-2xl font-extrabold text-white'>Linkedin</p>
                                <p className='text-white overflow-hidden mt-3'>whatsapp/skeimasi</p>
                            </span>
                            </div>

                            <div className='drop-shadow p-3 flex gap-3'>
                                <img src='/assets/images/social/instagram.png' alt='' className='rounded-full w-16 h-16' />
                                <span className='hover:cursor-pointer'>
                                <p className='text-2xl font-extrabold text-white'>Linkedin</p>
                                <p className='text-white overflow-hidden mt-3'>instagram/skeimasi</p>
                            </span>
                            </div>

                        </div>

                    </div>

                </div>
                <Footer />
            </div>
        </>
    );
}

export default ContactPage;