

enum StorageKeys {
    TOKEN = 'token' ,
    ROLE = 'role' ,
    NAME = 'name',
    LEVEL = 'LEVEL',
    EMAIL = 'email',
}

export default StorageKeys

