export enum Role {
    SME = 'sme',
    SUPPLIER = 'provider',
    ADMIN = 'admin',
    BANK = 'bank',
    INSURANCE = 'insurance'
}

export interface LoginBody {
    email: string,
    password: string
}

export interface LoginResponse {
    status: boolean,
    content: {
        'user-role': Role,
        user: {
            _id: string,
            auth_id: { email : string },
            name: string,
            insurance_list: string[],
            "createdAt": Date,
            "updatedAt": Date,
        },
        token: string
    }
}

export interface RegisterBody {
    email: string,
    password: string,
    role: Role
}

export interface RegisterResponse {
    status: boolean,
    content: {
        'user-role': Role,
        user: {
            auth_id: string;
            level: number;
            adminVerified: boolean;
            bankVerified: boolean;
            insuranceVerify: {
                list: any[];
            };
            categories: any[];
            installments: any[];
            credit: {
                amount: number;
                spend: number;
            };
            _id: string;
            docs: any[];
            createdAt: string;
            updatedAt: string;
            __v: number;
        },
        token: string
    }
}