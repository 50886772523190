import { rejectSme, verifySme} from "../../http/bank/bank-request";
import {useMutation} from "@tanstack/react-query";


export default function useSmeMutateBank (
    onSuccessVerify : () => void ,
    onSuccessReject : () => void
) {

    const {isPending : verifyLoading , mutate : verify} = useMutation({
        mutationFn : verifySme ,
        onSuccess() {
            onSuccessVerify()
        }
    })
    const {isPending : rejectLoading , mutate : reject} = useMutation({
        mutationFn : rejectSme ,
        onSuccess() {
            onSuccessReject()
        }
    })
    return {
        verifyLoading , verify ,
        rejectLoading , reject
    }
}