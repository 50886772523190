import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {bankGetOrderList} from "../../http/bank/bank-request";
import {insuranceGetOrderList} from "../../http/insurance/insurance-request";


export default function useOrderListInsurance (page : number ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.INSURANCE_ORDER_LIST , page ] ,
        queryFn : () => insuranceGetOrderList(page , 10)
    })
    return {isPending , data : data?.content.orders , total : data?.content.count}
}