import Card from "../../components/base/Cards/Card";
import SmeTableBank from "../../components/bank/sme/SmeTableBank";



export default function SmeListBank() {
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    <Card  title={'List Of SME\'s'} >
                        <SmeTableBank/>
                    </Card>
                </div>
            </div>
        </>
    );

}
