import {useFormik} from "formik";
import useSignup from "hooks/auth/useSignup";
import {RegisterBody, Role} from "models/auth.model";
import {useEffect, useRef, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import * as Yup from 'yup';
import fatra from "../../assets/img/logo/logo.png";
import AppModal from "components/base/modal/AppModal";
import Button from "../../components/base/Button/Button";
import {toast} from "react-toastify";


const SignupSchema = Yup.object({
    role: Yup.string().required('select your role').oneOf(["sme", "provider"]),
    email: Yup.string().required('enter your email').email('email is incorrect'),
    password: Yup.string().required('enter your password').min(8, 'password is too short'),
    confirmPassword: Yup.string()
        .required('confirm your password').min(8, 'password is too short')
        .oneOf([Yup.ref('password')], 'Passwords must match'),
    agree: Yup.boolean().oneOf([true], 'accept terms is required'),
    refCode: Yup.string()
        .required('enter Fatra admin Verification code')
})

type ExtraSignupForm = { confirmPassword: string, agree: boolean }

export default function Register() {
    const params = useParams();
    const userData = useRef<any>();

    const {loading, signup} = useSignup();
    const [userRole, setUserRole] = useState<Role>(params.role as Role);
    const [confirmRole, showConfirmRole] = useState(false);

    const formik = useFormik({
        initialValues: {
            role: userRole,
            email: "",
            password: "",
            confirmPassword: "",
            agree: false,
            refCode: ''
        } as RegisterBody & ExtraSignupForm & { refCode: string },
        validationSchema: SignupSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: RegisterBody & ExtraSignupForm & { refCode: string }) => {
            if (data.role === Role.SME) {
                if (data.refCode.toLowerCase().includes('fatrasme')) {
                    userData.current = {
                        email: data.email,
                        password: data.password,
                        role: data.role
                    };
                    showConfirmRole(true)
                } else {
                    toast.error('admin verification code is wrong.')
                    toast.warning('contact us with email')
                }
            } else {
                if (data.refCode.toLowerCase().includes('fatrasup')) {
                    userData.current = {
                        email: data.email,
                        password: data.password,
                        role: data.role
                    };
                    showConfirmRole(true)
                } else {
                    toast.error('admin verification code is wrong.')
                    toast.warning('contact us with email')
                }
            }
        }
    });

    function handleConfirmRole(confirmed: boolean) {
        showConfirmRole(false);
        if (confirmed) {
            const {confirmPassword, agree, ...rest} = userData.current;
            signup(rest);
        }
        userData.current = undefined;
    }


    const navigate = useNavigate();

    useEffect(() => {
        formik.resetForm();
        formik.setFieldValue("role", userRole);
        navigate("../register/" + userRole)
    }, [userRole]);

    return (
        <>
            <div className="container mx-auto md:px-4 px-2 py-5">
                <div className="flex flex-col content-center items-center justify-center">
                    <div className="w-full lg:w-4/12 px-4">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full mb-2 shadow-lg rounded-lg bg-blueGray-200 border-0">
                            <div className="rounded-t  px-6 py-4 bg-blueGray-600">
                                <div className="text-center flex justify-center mb-1">
                                    <img src={fatra} alt={"fatra"} className={'h-12'}/>
                                </div>
                                <div className="text-center mt-2">
                                    <h6 className="text-white text-sm font-bold">
                                        Sign Up
                                    </h6>
                                </div>
                            </div>

                            <div className="flex-auto px-6 py-3">
                                <p className={'text-black text-xs pb-1 text-center'}>to get fatra admin verification
                                    code please contact us with info@fatra.io</p>
                                <form onSubmit={formik.handleSubmit} noValidate>
                                    <div className={'rounded overflow-hidden flex flex-row cursor-pointer'}>
                                        <div
                                            onClick={() => setUserRole(Role.SME)}
                                            className={`w-1/2 text-center py-2 
                                                    ${userRole === Role.SME ? "text-white" : "text-black"}
                                                    ${userRole === Role.SME ? "bg-blue-500" : "bg-zinc-400"}`}>
                                            SME
                                        </div>
                                        <div
                                            onClick={() => setUserRole(Role.SUPPLIER)}
                                            className={`w-1/2 text-center py-2
                                                    ${userRole === Role.SUPPLIER ? "text-white" : "text-black"}
                                                    ${userRole === Role.SUPPLIER ? "bg-blue-500" : "bg-zinc-400"}`}>
                                            SUPPLIER
                                        </div>
                                    </div>
                                    {/* <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                            htmlFor="grid-password"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="email"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Name"
                                        />
                                    </div> */}

                                    <div className="relative w-full mt-2 mb-3">
                                        <label
                                            className="block  text-blueGray-600 text-xs "
                                            htmlFor="grid-password"
                                        >
                                            Email
                                        </label>
                                        <input
                                            id={'email'}
                                            name={'email'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            type="email"
                                            className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Email"
                                        />
                                        {formik.errors.email ?
                                            <p className={'text-red-dark text-xs h-4 pt-1'}>{formik.errors.email}</p> :
                                            <p className={'h-4 pt-1'}>{" "}</p>}
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block  text-blueGray-600 text-xs "
                                            htmlFor="grid-password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            id={'password'}
                                            name={'password'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                            type="password"
                                            className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Password"
                                        />
                                        {formik.errors.password ?
                                            <p className={'text-red text-xs h-4 pt-1'}>{formik.errors.password}</p> :
                                            <p className={'h-4 pt-1'}>{" "}</p>}
                                    </div>
                                    <div className="relative w-full my-2">
                                        <label
                                            className="block  text-blueGray-600 text-xs "
                                            htmlFor="grid-password"
                                        >
                                            Confirm Password
                                        </label>
                                        <input
                                            id={'confirmPassword'}
                                            name={'confirmPassword'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.confirmPassword}
                                            type="password"
                                            className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Confirm Password"
                                        />
                                        {formik.errors.confirmPassword ?
                                            <p className={'text-red text-xs h-4 pt-1'}>{formik.errors.confirmPassword}</p> :
                                            <p className={'h-4 pt-1'}>{" "}</p>}
                                    </div>
                                    <div className="relative w-full my-2">
                                        <label
                                            className="block  text-blueGray-600 text-xs "
                                            htmlFor="grid-password"
                                        >
                                            Fatra Admin Verification Code
                                        </label>
                                        <input
                                            id={'refCode'}
                                            name={'refCode'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.refCode}
                                            type="text"
                                            className="border-0 px-3 py-2 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Admin Verification Code"
                                        />
                                        {formik.errors.refCode ?
                                            <p className={'text-red text-xs h-4 pt-1'}>{formik.errors.refCode}</p> :
                                            <p className={'h-4 pt-1'}>{" "}</p>}
                                    </div>

                                    <div className={'flex items-center'}>
                                        <input
                                            id={'agree'}
                                            name={'agree'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            checked={formik.values.agree}
                                            type="checkbox"
                                            className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-4 ease-linear transition-all duration-150"
                                        />
                                        <label className="inline-flex items-center cursor-pointer">

                                            <span className="ml-2 text-sm font-semibold text-blueGray-600">
                                                I agree with the{" "}
                                                <Link
                                                    to={'/terms'}
                                                    className="text-lightBlue-500"
                                                >
                                                    Privacy Policy
                                                </Link>
                                            </span>
                                        </label>
                                    </div>
                                    {formik.errors.agree ?
                                        <p className={'text-red text-xs h-4 pt-1'}>{formik.errors.agree}</p> :
                                        <p className={'h-4 pt-1'}>{" "}</p>}

                                    <div className="text-center mt-3">
                                        <Button
                                            // loading={loading}
                                            disabled={loading || !formik.isValid}
                                            className={
                                                `${(loading || !formik.isValid) ? "bg-blue-400" : "bg-blue-600"} 
                                                text-white hover:bg-blue-500  text-sm font-bold uppercase 
                                                px-6  rounded shadow hover:shadow-lg outline-none 
                                                focus:outline-none mr-1 mb-1 w-full ease-linear 
                                                transition-all duration-150 
                                                ${(loading || !formik.isValid) ? "cursor-not-allowed" : "cursor-pointer"}`
                                            }
                                            type="submit"
                                        >
                                            Create Account
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex justify-center z-0 ">
                        <Link to="/auth/login" className="text-black">
                            <small>Do you have account ? Login</small>
                        </Link>
                    </div>
                </div>
            </div>
            {
                confirmRole &&
                <AppModal
                    open={confirmRole}
                    onClose={() => handleConfirmRole(false)}>
                    <div className="flex flex-col gap-5">
                        <h1 className="text-blueGray-600 text-center">Warning</h1>
                        <h4 className="text-center">Do you want to continue as {userRole.valueOf()} ?</h4>
                        <div className="flex gap-3 mt-5 justify-center">
                            <Button
                                className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                onClick={() => handleConfirmRole(false)}>No</Button>
                            <Button
                                className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                onClick={() => handleConfirmRole(true)}>Yes</Button>
                        </div>
                    </div>
                </AppModal>
            }
        </>
    );
}
