import AdminVerifiedLogo from 'assets/img/other/admin_verified.png';

const Approved = () => {
    return (
        <div className="w-1/2 py-10 text-center h-full flex flex-col gap-5 justify-center items-center">

            <img width={250} alt="" src={AdminVerifiedLogo} />

            <p className="text-2xl font-bold">
                You are verified by the Admin.
            </p>

        </div>
    );
}

export default Approved;