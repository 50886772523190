import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "../../views/admin/Dashboard";
import {Role} from "../../models/auth.model";
import Sidebar from "../../components/base/Sidebar/Sidebar";
import SmeListBank from "../../views/bank/SmeListBank";
import SmeBank from "../../views/bank/SmeBank";
import OrderListBank from "../../views/bank/OrderListBank";
import OrderDetailBank from "../../views/bank/OrderDetailBank";
import roleMenus from "../../utils/roleMenus";
import InstallmentListBank from "../../views/bank/InstallmentListBank";


export default function Bank() {
    return (
        <>
            <Sidebar menu={roleMenus[Role.BANK]} title={Role.BANK}/>
            <div className="relative md:ml-64 bg-blueGray-100">
                <div className="px-2 mx-auto w-full py-2">
                    <Routes>
                        <Route path="dashboard" element={<Dashboard/>}/>
                        <Route path="sme" element={<SmeListBank/>}/>
                        <Route path="sme/:id/*" element={<SmeBank/>}/>
                        <Route path="orders" element={<OrderListBank/>}/>
                        <Route path="order/:id" element={<OrderDetailBank/>}/>
                        <Route path="installments" element={<InstallmentListBank/>}/>
                        <Route
                            path="*"
                            element={<Navigate to="dashboard" replace={true} />}
                        />
                    </Routes>
                    {/*<FooterAdmin />*/}
                </div>
            </div>
        </>
    );
}
