import React, {useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Role} from "../../../models/auth.model";
import fatra from "../../../assets/img/logo/logo.png";
import roleMenus from "../../../utils/roleMenus";
import AppDarwer, {Placement} from "../drawer/AppDrawer";
import AppModal from "../modal/AppModal";
import Button from "../Button/Button";
import {toast} from "react-toastify";

interface SidebarProps {
    title: string,
    menu: {title : string , path : string}[]
}

export default function Sidebar(props: SidebarProps) {
    const {title, menu} = props;
    const location = useLocation()
    const navigate = useNavigate();
    const [drawer, setDrawer] = useState(false);
    const [logoutModal, showLogoutModal] = useState(false);
    return (
        <>
            <nav
                className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-blueGray-800 text-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div
                    className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Brand */}
                    <Link to={'/'}>
                        <img className="h-8"
                             src={fatra}
                             alt="fatra"/>
                    </Link>
                    <button
                        className="cursor-pointer text-white  md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() => setDrawer(true)}
                    >
                        <span className="absolute -inset-0.5"></span>
                        <span className="sr-only">Open main menu</span>
                        <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5"
                             stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                        </svg>
                        <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24"
                             strokeWidth="1.5"
                             stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"/>
                        </svg>
                    </button>
                    <div
                        className={
                            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow hidden overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded "
                        }
                    >
                        {/* Divider */}
                        <hr className="my-4 md:min-w-full text-white"/>
                        {/* Heading */}
                        <h6 className="md:min-w-full text-green-500 text-xs font-bold block no-underline">
                            {title} Panel
                        </h6>
                        <hr className="my-4 md:min-w-full text-white"/>

                        {/* Navigation */}
                        <ul className="md:flex-col md:min-w-full flex  flex-col list-none">
                            {menu.map((item: any) => <li key={item.title} className="items-center">
                                <Link
                                    className={
                                        "text-xs  py-3  block " +
                                        (window.location.href.indexOf(item.path) !== -1
                                            ? "text-lightBlue-300 hover:text-lightBlue-600"
                                            : "text-white hover:text-slate-300")
                                    }
                                    to={item.path}
                                >
                                    {item.title}
                                </Link>
                            </li>)}
                        </ul>

                        {/* Divider */}
                        <hr className="my-4 md:min-w-full text-white"/>

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center cursor-pointer" onClick={() => showLogoutModal(true)}>
                                <span
                                    className="text-red text-xs py-3 font-bold block"
                                >
                                    Logout
                                </span>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>

            <AppDarwer
                placement={Placement.LEFT}
                open={drawer}
                onClose={() => setDrawer(false)}>
                <div className={'bg-blueGray-800 py-8 px-4'}>
                    <div>
                        <Link to={'/'}>
                            <img className="h-8"
                                 src={fatra}
                                 alt="fatra"/>
                        </Link>
                    </div>
                    {/* Divider */}
                    <hr className="my-4 md:min-w-full text-white"/>
                    <ul className="md:flex-col md:min-w-full flex  flex-col list-none">
                        {menu.map((item: any) => <li key={item.title} className="items-center">
                            <Link
                                className={
                                    "text-xs  py-3  block " +
                                    (location.pathname.indexOf(item.path) !== -1
                                        ? "text-lightBlue-300 hover:text-lightBlue-600"
                                        : "text-white hover:text-slate-300")
                                }
                                to={item.path}
                            >
                                {item.title}
                            </Link>
                        </li>)}
                    </ul>
                    {/* Divider */}
                    <hr className="my-4 md:min-w-full text-white"/>

                    <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                        <li className="items-center cursor-pointer" onClick={() => {
                            setDrawer(false)
                            showLogoutModal(true)
                        }}>
                                <span
                                    className="text-red text-xs py-3 font-bold block"
                                >
                                    Logout
                                </span>
                        </li>
                    </ul>
                </div>
            </AppDarwer>

            {
                logoutModal &&
                <AppModal
                    open={logoutModal}
                    onClose={() => showLogoutModal(false)}>
                    <div className="flex flex-col gap-2 px-2">
                        <h1 className="font-bold">Logout</h1>
                        <h4>are you sure for logout ? </h4>

                        <div className="flex gap-3 mt-5 justify-center">

                            <Button
                                onClick={() => showLogoutModal(false)}
                                className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                no
                            </Button>
                            <Button
                                onClick={()=> {
                                    localStorage.clear() ;
                                    toast.success("logout successfully")
                                    navigate('/auth/login');
                                    window.location.reload();
                                }}
                                type={'submit'}
                                className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                yes
                            </Button>
                        </div>
                    </div>
                </AppModal>
            }
        </>
    );
}
