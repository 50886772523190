import Button from 'components/base/Button/Button';
import Expandable from 'components/base/Expandable/Expandable';
import Footer from 'components/base/Footers/Footer';
import IndexNavbar from 'components/base/Navbars/IndexNavbar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Text = "Do you have a question about your subscription, a recent order, products, shipping or you want to suggest a new magazine? Here you can find some helpful answers to frequently asked questions (FAQ).";


const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis,\
                    sapien vel blandit vestibulum, magna quam bibendum turpis, sed lacinia\
                    elit nunc ac nunc. Donec euismod, velit vel aliquam bibendum, sapien\
                    elit nunc ac nunc. Donec euismod, velit vel aliquam bibendum, sapien\
                    elit nunc ac nunc. Donec euismod, velit vel aliquam bibendum, sapien\
                    elit nunc ac nunc. Donec euismod, velit vel aliquam bibendum, sapien\
                    elit nunc ac nunc. Donec euismod, velit vel aliquam bibendum, sapien"


const loremObject = [
    {
        q: 'Will Fatra® guarantee the acceptance of my credit application?',
        a: 'While Fatra® will make sure that the SMEs applying meet the criteria of other concerned parties; the decision is made by the financial institution. However, since Fatra® operates with several financial institutions therefore Fatra® will be responsible for facilitating the credit arrangement with an alternative Financial partner should the credit application be refused in the first attempt.'
    },
    {
        q: 'Do I have to attend physically at the bank/ lenders offices as an SME?',
        a: 'A. No, the registration process will be executed with Fatra® platform, all documents, licences and information will be uploaded by the SME.'
    },
    {
        q: 'Do I have to attend physically at the bank/ lenders offices as an SME?',
        a: 'A. No, the registration process will be executed with Fatra® platform, all documents, licences and information will be uploaded by the SME.'
    },
    {
        q: 'Can the SME buy the product/ services from any supplier domestically and regionally?',
        a: 'A. Yes. SMEs can obtain their products from a selected list of both domestic and regional partner suppliers. The list of suppliers will be available on the SMEs account on Fatra® platform.'
    },
    {
        q: 'Does the SMEs have to have an existing account with the lender/ credit provider?',
        a: 'No, the SMEs can use their existing bank account, after registering with Fatra® platform and getting the approval from the lender/ credit provider, the SMEs can make the instalment payments via a payment link.'
    },
    {
        q: 'What is the duration of the loan period?',
        a: 'Fatra® offers a short term (typically less than six months), interest-free instalment plan. A fraction of the invoice is expected to be paid by the SME upfront before the delivery of the product. The remaining balance will be paid back through monthly instalments until the full invoice has been paid.'
    },
    {
        q: 'As a supplier, will I get influenced by any default or late payment that occurs on behalf of the SME?',
        a: 'No, Fatra® will settle the invoice 2-4 working days after it has been uploaded to the platform. This means the supplier is not affected by any disruption that might occur in the payment plan.'
    }
]

const FaqPage = () => {

    const [isExpand, setExpand] = useState<boolean[]>([]);

    useEffect(() => {
        const arraySize = loremObject.length;
        const init = Array.from({ length: arraySize }, () => false);
        setExpand(init);
    }, []);

    function onExpanded(index: number) {
        const expandArray = [...isExpand];
        const newState = !expandArray[index];
        const newExpands = Array(loremObject.length).fill(false);
        newExpands[index] = newState;
        setExpand(newExpands);
    }


    return (
        <>
            <IndexNavbar />
            <div className='overflow-x-hidden'>
                <div className='bg-blueGray-700 pb-32 w-screen overflow-x-hidden overflow-y-scroll flex flex-col items-center'>
                    {/*<IndexNavbar fixed />*/}
                    <div
                        className="bg-cover w-screen h-screen flex p-6 gap-6 justify-evenly items-center"
                        style={{
                            height: "450px",
                            background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(51,65,85,1) 35%, rgba(0,0,0,1) 100%)',
                        }}>

                        <div className='flex flex-col w-full md:w-1/2 lg:w-1/3 justify-evenly h-full'>
                            <p className='text-white italic text-4xl font-extrabold drop-shadow'>
                                Help & FAQ
                            </p>
                            <p className='text-white  text-xl text-justify drop-shadow'>
                                {Text}
                            </p>

                            <Link
                                to={'/auth/login'}
                            >
                                <Button
                                    loading={false}
                                    className="bg-blue-500 text-white active:bg-blueGray-600 text-sm font-bold
                                uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none
                                focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                    type="submit"
                                >
                                    Get Start
                                </Button>
                            </Link>
                        </div>

                        <img alt='' src='/assets/images/faq.png' className='hidden md:block w-60 h-60 lg:w-80 lg:h-80' />

                    </div>

                    <div className='w-full flex flex-col lg:flex-row justify-center items-start leading-6 gap-6 p-6'>

                        <div className='lg:w-2/3 w-full h-screen p-6 flex flex-col rounded bg-blueGray-600 mt-5'>
                            <p className='text-white text-4xl italic font-extrabold'>
                                Services We Provide
                            </p>
                            <p className='text-white text-justify p-5 leading-8 overflow-y-scroll'>
                                Our support team is here to assist you with any questions or concerns you may have
                                Our knowledgeable support staff is here to help you resolve any issues you may encounter
                                We pride ourselves on providing quick and efficient support to ensure your satisfaction.
                                Our support team is here to assist you with any questions or concerns you may have
                                Our knowledgeable support staff is here to help you resolve any issues you may encounter
                                We pride ourselves on providing quick and efficient support to ensure your satisfaction.
                                Our support team is here to assist you with any questions or concerns you may have
                                Our knowledgeable support staff is here to help you resolve any issues you may encounter
                                We pride ourselves on providing quick and efficient support to ensure your satisfaction.
                            </p>
                        </div>

                        <div className='w-full h-screen'>
                            {
                                loremObject.map((item, index) => {
                                    return (
                                        <Expandable
                                            expand={isExpand[index]}
                                            onExpand={() => onExpanded(index)}
                                            key={index}
                                            headerClass="bg-blueGray-600"
                                            contentClass="shadow-md text-white font-normal leading-8"
                                            title={item.q}>
                                            {item.a}
                                        </Expandable>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default FaqPage;