import { useMutation, useQuery } from "@tanstack/react-query"
import { addInfo, addPhone, deleteDocs, getCategories, getDocs, getInfo, getProfile, getSmeLogs, resendEmail, uploadDocs, validatePhone, verifyDocs, verifyEmail } from "http/sme/sme-requests"
import { QueryKeys } from "utils/ReactQueyConstants"


export function useSmeProfile() {

    const { data: smeProfile, isPending, refetch } = useQuery({
        queryKey: [QueryKeys.SME_GET_PROFILE],
        queryFn: () => getProfile() ,
    })

    return {
        refetch,
        isPending,
        smeProfile: smeProfile?.content.sme
    }
}

export function useResendVerificationEmail() {

    const { data, isLoading, refetch } = useQuery({
        retry: false,
        enabled: false,
        queryKey: [QueryKeys.SME_RESEND_VERIFY_EMAIL],
        queryFn: () => resendEmail()
    })

    return {
        refetch,
        isLoading,
        resendOtpResult: data
    }
}
export function useVerifyEmail() {

    const { data, isPending, mutate } = useMutation({
        mutationFn: verifyEmail,
    })

    return {
        emailVerifyLoading: isPending,
        emailVerifyResponse: data,
        confirmEmail: mutate

    }
}

export function useAddPhone() {

    const { data, isPending, mutate } = useMutation({
        mutationFn: addPhone
    })

    return {
        addPhoneLoading: isPending,
        addPhoneResponse: data,
        addPhone: mutate,
    }
}
export function useValidatePhone() {

    const { data, isPending, mutate } = useMutation({
        mutationFn: validatePhone,
    })

    return {
        phoneValidationLoading: isPending,
        phoneValidationResponse: data,
        validatePhone: mutate

    }
}

export function useCategories() {

    const { data: categories, isLoading, refetch } = useQuery({
        refetchInterval(query) {
            return (query.state.status === 'error') ? 5000 : 0;
        },
        queryKey: [QueryKeys.SME_GET_CATEGORIES],
        queryFn: () => getCategories()
    })

    return {
        getCategoryList: refetch,
        getCategoriesLoading: isLoading,
        categories
    }
}

export function useAddInfo() {

    const { data, isPending, mutate } = useMutation({
        mutationFn: addInfo
    })

    return {
        addInfoLoading: isPending,
        addInfoResponse: data,
        addInfo: mutate,
    }
}

/* @@@ this api will be removed soon @@@ */
export function useGetInfo() {

    const { data, isLoading, refetch } = useQuery({
        queryKey: [QueryKeys.SME_GET_INFO],
        queryFn: getInfo
    })

    return {
        getInfoLoading: isLoading,
        getInfoResponse: data?.content,
        getInfo: refetch,
    }
}

export function useGetDocs() {

    const { data: docs, isLoading, refetch } = useQuery({
        refetchInterval(query) {
            return (query.state.status === 'error') ? 5000 : 0;
        },
        queryKey: [QueryKeys.SME_GET_DOCS],
        queryFn: () => getDocs()
    })

    return {
        getDocList: refetch,
        getDocsLoading: isLoading,
        docs: docs?.content.docs || []
    }
}

export function useUploadDoc() {
    const { data, isPending, mutate } = useMutation({
        mutationFn: uploadDocs
    })

    return {
        uploadPending: isPending,
        uploadResponse: data,
        uploadDoc: mutate,
    }
}


export function useVerifyDocs() {
    const { data, isPending, mutate } = useMutation({
        mutationFn: verifyDocs
    })

    return {
        verifyDocsPending: isPending,
        verifyDocsResponse: data,
        verifyDoc: mutate,
    }
}



export function useDeleteDocs() {
    const { data, isPending, mutate } = useMutation({
        mutationFn: deleteDocs
    })

    return {
        deleteDocsPending: isPending,
        deleteDocsResponse: data,
        deleteDoc: mutate,
    }
}

export function useSmeLogs() {

    const { data: logs, isLoading, refetch } = useQuery({
        refetchInterval(query) {
            return (query.state.status === 'error') ? 3000 : 0;
        },
        // enabled: false,
        queryKey: [QueryKeys.SME_GET_LOGS],
        queryFn: getSmeLogs
    })

    return {
        getLogList: refetch,
        getLogsLoading: isLoading,
        logs: logs?.content.logs
    }
}