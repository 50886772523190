import {useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {bankGeInstallments} from "../../http/bank/bank-request";


export default function useInstallmentListBank (page : number ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.BANK_INSTALLMENTS_LIST , page ] ,
        queryFn : () => bankGeInstallments(page , 10)
    })
    return {isPending , data : data?.content.installments , total : data?.content.count}
}