import {useMutation} from "@tanstack/react-query";
import {login} from "../../http/auth/auth-requests";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import StorageKeys from "../../utils/StorageKeys";


export default function useLogin () {
    const navigate = useNavigate();
    const {isPending , mutate} = useMutation({
        mutationFn : login ,
        onSuccess : (data) => {
            toast.success('login successfully')
            localStorage.setItem(StorageKeys.TOKEN , data.content.token);
            localStorage.setItem(StorageKeys.ROLE , data.content['user-role'])
            localStorage.setItem(StorageKeys.NAME , data.content.user.name)
            localStorage.setItem(StorageKeys.EMAIL , data.content.user.auth_id.email)
            navigate(`/${data.content['user-role']}`)
        }
    })
    return {loading : isPending , login : mutate}
}