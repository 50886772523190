import {useMutation, useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {getInsuranceList, setInsuranceList} from "../../http/admin/admin-request";
import {toast} from "react-toastify";


export default  function  useInsurance(onSuccessChange : () => void){
    const {data , isPending , refetch } = useQuery({
        queryKey  : [QueryKeys.INSURANCE_LIST] ,
        queryFn : getInsuranceList
    })
    const { isPending : changeLoading ,  mutate : changeOrder} = useMutation({
        mutationFn : setInsuranceList ,
        onSuccess : () => {
            refetch();
            onSuccessChange()
            toast.success('done!')

        }
    })
    return {data : data?.content.insuranceList , isPending , changeLoading , changeOrder}
}