import {OrderStatusEnum} from "../../models/order.model";
import Tag from "../base/tag/Tag";
import OrderStatusList from "../../utils/orderStatusList";


interface OrderStatusProps {
    status: OrderStatusEnum,
    paid: boolean
}

export default function OrderStatus({status, paid}: OrderStatusProps) {
    if (paid) return <Tag
        color={'blue'}>finished</Tag>
    else return <Tag color={'blue'}>
        {OrderStatusList[status].title}
    </Tag>
}