import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "../../views/admin/Dashboard";
import {Role} from "../../models/auth.model";
import StorageKeys from "../../utils/StorageKeys";
import Sidebar from "../../components/base/Sidebar/Sidebar";
import SmeListAdmin from "../../views/admin/SmeListAdmin";
import SupplierListAdmin from "../../views/admin/SupplierListAdmin";
import OrderListAdmin from "../../views/admin/OrderListAdmin";
import InsuranceList from "../../views/admin/InsuranceList";
import InstallmentListAdmin from "../../views/admin/InstallmentListAdmin";
import SmeAdmin from "../../views/admin/SmeAdmin";
import SupplierAdmin from "../../views/admin/SupplierAdmin";
import Settings from "../../views/admin/Settings";
import OrderDetailAdmin from "../../views/admin/OrderDetailAdmin";
import roleMenus from "../../utils/roleMenus";


export default function AdminLayout() {
    return (
        <>
            <Sidebar menu={roleMenus[Role.ADMIN]} title={Role.ADMIN}/>
            <div className="relative md:ml-64 bg-blueGray-100">
                <div className="px-2 mx-auto w-full py-2">
                        <Routes>
                            <Route path="dashboard" element={<Dashboard/>}/>
                            <Route path="sme" element={<SmeListAdmin/>}/>
                            <Route path="sme/:id/*" element={<SmeAdmin/>}/>
                            <Route path="supplier" element={<SupplierListAdmin/>}/>
                            <Route path="supplier/:id/*" element={<SupplierAdmin/>}/>
                            <Route path="orders" element={<OrderListAdmin/>}/>
                            <Route path="order/:id" element={<OrderDetailAdmin/>}/>
                            {/*<Route path="insurances" element={<InsuranceList/>}/>*/}
                            <Route path="installments" element={<InstallmentListAdmin/>}/>
                            <Route path="settings" element={<Settings/>}/>
                            <Route
                                path="*"
                                element={<Navigate to="dashboard" replace={true} />}
                            />
                        </Routes>
                    {/*<FooterAdmin/>*/}
                </div>
            </div>
        </>
    );
}
