import {useMutation} from "@tanstack/react-query";
import {uploadInvoice} from "../../http/provider/provider-requests";


export function useUploadInvoice(onSuccess : () => void) {
    const {isPending, mutate } = useMutation({
        mutationFn: uploadInvoice ,
        onSuccess : onSuccess
    })

    return {
        uploadPending: isPending,
        uploadInvoice: mutate,
    }
}