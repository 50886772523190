import { FC } from "react";

type SpinnerSize = "small" | "normal" | "large"

interface SpinnerProps {
    size?: SpinnerSize;
}

const SizeMap = {
    ["small" as SpinnerSize]: 8,
    ["normal" as SpinnerSize]: 12,
    ["large" as SpinnerSize]: 16,
}

const Spinner: FC<SpinnerProps> = ({ size }) => {

    const spinnedSize = size ? SizeMap[size] : SizeMap["normal"];

    return (
        <div
            className={`inline-block h-${spinnedSize} w-${spinnedSize} animate-spin rounded-full border-8 border-solid border-current border-r-transparent 
            align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
            role="status">
            <span
                className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
        </div>
    );
}

export default Spinner;
