import {useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {verifyPayment} from "../../http/sme/sme-requests";


export default function useVerifyPayment (id : string) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.VERIFY_PAYMENT , id] ,
        queryFn : () => verifyPayment({
            transactionId : id
        })
    })
    return {payStatus : data?.content.payStatus , isPending}
}