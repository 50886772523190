import {QueryKeys} from "../../utils/ReactQueyConstants";
import {smeGetOrder} from "../../http/sme/sme-requests";
import {useQuery} from "@tanstack/react-query";


export default function useOrderSme (id : string ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SME_ORDER , id ] ,
        queryFn : () => smeGetOrder(id)
    })
    return {isPending , data : data?.content.order }
}