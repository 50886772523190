import {ReactNode} from "react";

interface CardProps {
    title: string,
    children: ReactNode
}

export default function Card({title, children}: CardProps) {
    return (
        <>
            <div
                className={
                    "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white"
                }
            >
                <div className="rounded-t mb-0 px-4 py-3 border-0 bg-sky-300">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full max-w-full flex-grow flex-1">
                            <h3
                                className={
                                    "text-lg text-black"
                                }
                            >
                                {title}
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="block w-full overflow-x-auto mt-2">
                    {/* Projects table */}
                    {children}
                </div>
            </div>
        </>
    );
}

