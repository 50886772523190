import Card from "../../components/base/Cards/Card";
import useInstallmentListAdmin from "../../hooks/admin/useInstallmentListAdmin";
import {Installment} from "../../models/order.model";
import Tag from "../../components/base/tag/Tag";
import {CheckCircleFilled} from "@ant-design/icons";
import React from "react";
import {Link} from "react-router-dom";


export default function InstallmentListAdmin() {
    const {data, isPending} = useInstallmentListAdmin(1)
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    <Card title={'list of installments'}>
                        <table className="items-center w-full bg-transparent border-collapse">
                            <thead>
                            <tr>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    #
                                </th>
                                <th
                                    className={
                                        "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Percent
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Amount
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    status
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Deadline
                                </th>
                                <th
                                    className={
                                        "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold  bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                    }
                                >
                                    Actions
                                </th>
                            </tr>
                            </thead>
                            {!isPending && data && <tbody>
                            {data.map((item: Installment, index: number) => <tr>
                                <td className="border-t-0 px-3  border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                                    <p className={
                                        "m-0"
                                    }>

                                        {index + 1}
                                    </p>
                                </td>
                                <td className="border-t-0 px-3 align-middle border-l-0 border-r-0  whitespace-nowrap p-4 items-center ">
                                    <p className={
                                        "text-blueGray-800 font-bold"
                                    }>
                                        {item.rate * 100} %
                                    </p>
                                </td>

                                <td className="border-t-0 px-6 align-middle border-l-0 text-blueGray-800 font-bold  border-r-0  whitespace-nowrap p-4">
                                    {item.amount} $
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0  whitespace-nowrap p-4 text-center">
                                    {item.paid?.at && <Tag icon={<CheckCircleFilled/>} color={'green'}>
                                        paid
                                    </Tag>}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-xs whitespace-nowrap p-4 text-center">
                                    {item.paid?.deadline && <p>
                                        {new Intl.DateTimeFormat('en-GB', {
                                            dateStyle: 'full',
                                        }).format(new Date(item.paid.deadline))}
                                    </p>}
                                </td>
                                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0  text-xs whitespace-nowrap p-4 text-center">
                                    <Link to={`/admin/order/${item.order_id}`}>
                                        <button
                                            className="bg-indigo-500 text-white active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                        >
                                            See Order
                                        </button>
                                    </Link>
                                </td>
                            </tr>)}
                            </tbody>
                            }
                        </table>
                    </Card>
                </div>
            </div>
        </>
    );
}
