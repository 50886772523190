import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import Card from "../../components/base/Cards/Card";
import useSmeBank from "../../hooks/bank/useSmeBank";
import Tag from "../../components/base/tag/Tag";
import Tabs from "../../components/base/Tab/tab";
import {Category} from "../../models/sme.models";
import SmeInfoComponent from "../../components/admin/sme/SmeInfo";
import SmeDocsComponent from "../../components/admin/sme/SmeDocsAdmin";
import {CheckCircleFilled, CloseCircleFilled, OrderedListOutlined} from "@ant-design/icons";
import {useState} from "react";
import AppModal from "../../components/base/modal/AppModal";
import {useFormik} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import Button from "../../components/base/Button/Button";
import useSmeMutateBank from "../../hooks/bank/useSmeMutateBank";
import SmeTimeline from "../../components/admin/sme/SmeTimeline";
import {SmeLevelsForAdmin} from "../../utils/smeLevels";
// import useSmeLogsAdmin from "../../hooks/bank/useSmeLogsAdmin";

const tabs = [
    {
        key: 'info',
        title: 'info'
    },
    {
        key: 'documents',
        title: 'documents'
    },
    // {
    //     key: 'verification_timeline',
    //     title: 'verification timeline'
    // },
    {
        key: 'installments',
        title: 'installments'
    },
    {
        key: 'invoices',
        title: 'invoices'
    }

]
const schema = Yup.object({
    desc: Yup.string().required('enter description'),
});

enum ModalType {
    ACCEPT,
    REJECT,
    FULL_REJECT,
}

export default function SmeBank() {
    const navigate = useNavigate()
    const {id} = useParams()
    const active = useLocation().pathname.split('/')[4]
    const {isPending, data} = useSmeBank(id!)
    const {verifyLoading, verify, rejectLoading, reject} = useSmeMutateBank(
        () => {
            showModal({
                type: ModalType.ACCEPT,
                show: false
            })
            toast.success("Done!")
            navigate('/bank/sme')
        },
        () => {
            showModal({
                type: ModalType.ACCEPT,
                show: false
            })
            toast.success("Done!")
            navigate('/bank/sme')
        }
    )
    const [modal, showModal] = useState({
        type: ModalType.ACCEPT,
        show: false
    })
    const formik = useFormik({
        initialValues: {
            desc: ''
        },
        validationSchema: schema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
            if (modal.type === ModalType.ACCEPT) {
                verify({
                    smeId: id!,
                    desc: data.desc
                })
            } else {
                reject({
                    smeId: id!,
                    desc: data.desc,
                    full: modal.type === ModalType.FULL_REJECT
                })
            }
        }
    })

    // const {logs , logsPending} = useSmeLogsAdmin(id!)

    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    {!isPending && data && <Card
                        title={`SME Profile : ${data?.info && data.info.firstname ? data.info.firstname + ' ' + data.info.lastname : data.auth_id.email}`}>

                        <div className={'px-4 pb-4'}>
                            <div>
                                <div className={'flex justify-end gap-x-2'}>
                                    {data.level === 5 &&
                                        <>
                                            <Button
                                                onClick={() => showModal({
                                                    type: ModalType.ACCEPT,
                                                    show: true
                                                })}
                                                className="text-white flex items-center font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                            >
                                                <CheckCircleFilled className={'mr-2'}/>
                                                Verify SME
                                            </Button>
                                            <Button
                                                onClick={() => showModal({
                                                    type: ModalType.REJECT,
                                                    show: true
                                                })}
                                                className="text-white flex items-center font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                            >
                                                <CloseCircleFilled className={'mr-2'}/>
                                                Reject SME
                                            </Button>
                                            <Button
                                                onClick={() => showModal({
                                                    type: ModalType.FULL_REJECT,
                                                    show: true
                                                })}
                                                className="text-white flex items-center font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                            >
                                                <CloseCircleFilled className={'mr-2'}/>
                                                full Reject SME
                                            </Button>

                                        </>
                                    }
                                </div>
                                <div className={'py-2 gap-x-2 align-middle'}>
                                    <span className={'text-neutral-500'}>Email : </span> {data.auth_id.email}
                                </div>
                                <div className={'py-2'}>
                                    <span className={'text-neutral-500'}>Phone : </span> {data.info?.phone?.country_code + "  " + data.info?.phone?.number}
                                </div>
                                <div className={'py-2 inline-flex gap-x-2 align-middle'}>
                                    <span className={'text-neutral-500'}>Verification Level : </span> <Tag
                                    color={'blue'}>{SmeLevelsForAdmin[String(data.level)].title}</Tag>
                                </div>

                                <div className={'py-2'}>
                                    <span className={'text-neutral-500'}>Request Credit : </span> {data.credit.request} $
                                </div>
                                <div className={'py-2'}>
                                    <span className={'text-neutral-500'}>Credit : </span> {data.credit.amount} $
                                </div>

                            </div>

                            <div className={'inline-flex flex-wrap align-middle gap-x-2 py-6'}>
                                <span className={'text-neutral-500'}>
                                    categories :
                                </span>
                                {
                                    data.categories.map((cat: Category) => <Tag key={cat} color={'blue'}>
                                        {cat.title}
                                    </Tag>)
                                }
                            </div>
                            <br className={'mb-3'}/>
                            <Tabs active={active} onTab={(key: string) => navigate(`/bank/sme/${id}/${key}`)}
                                  tabs={tabs}/>
                            <Routes>
                                <Route path="info" element={<SmeInfoComponent desc={data.desc} info={data.info}/>}/>
                                <Route path="documents" element={<SmeDocsComponent role={'sme'} docs={data.docs}/>}/>
                                {/*<Route path="verification_timeline" element={<SmeTimeline logs={logs} loading={logsPending} />}/>*/}
                            </Routes>
                        </div>

                    </Card>}
                </div>
            </div>

            {
                modal.show &&
                <AppModal
                    open={modal.show}
                    onClose={() => showModal({
                        type: ModalType.REJECT,
                        show: false
                    })}>
                    <div className="flex flex-col gap-2 px-2">
                        <h1 className="font-bold">SME Verification</h1>
                        <h4>{modal.type === ModalType.ACCEPT ? 'confirm' : 'reject'} this SME info and documents</h4>
                        {modal.type === ModalType.FULL_REJECT && <p className={'text-neutral-500 text-sm'}>if you reject fully sme . sme will cant continue verification</p>}
                        <form onSubmit={formik.handleSubmit}>
                            <input
                                id={'desc'}
                                name={'desc'}
                                type="text"
                                onChange={formik.handleChange}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="description"
                            />
                            {formik.errors.desc ?
                                <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.desc}</p> :
                                <p className={'h-5 pt-2'}>{" "}</p>}
                            <div className="flex gap-3 mt-5 justify-center">
                                <Button
                                    loading={modal.type === ModalType.ACCEPT ? verifyLoading : rejectLoading}
                                    type={'submit'}
                                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    {modal.type === ModalType.ACCEPT && 'confirm'}
                                    {modal.type === ModalType.REJECT && 'reject'}
                                    {modal.type === ModalType.FULL_REJECT && 'full reject'}
                                </Button>
                                <Button
                                    onClick={() => showModal({
                                        type: ModalType.REJECT,
                                        show: false
                                    })}
                                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    close
                                </Button>
                            </div>
                        </form>
                    </div>
                </AppModal>
            }
        </>
    )
}