import React, {useState} from "react";
import {Link} from "react-router-dom";
import fatra from "../../../assets/img/logo/logo.png";
import {useNavigate} from 'react-router-dom';
import AppDarwer from "../drawer/AppDrawer";
import StorageKeys from "../../../utils/StorageKeys";

export default function Navbar(props) {
    const navigate = useNavigate()
    const [navbarOpen, setNavbarOpen] = useState(false);
    return (
        <>
            <div className="min-h-full">
                <nav className="bg-blueGray-700">
                    <div className="mx-auto container px-2">
                        <div className="flex h-16 items-center justify-between">
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <Link to={'/'}>
                                        <img className="h-8"
                                             src={fatra}
                                             alt="fatra"/>
                                    </Link>
                                </div>
                                <div className="hidden md:block">
                                    <div className="ml-10 flex items-baseline space-x-4">
                                        <Link to="/about-us"
                                              className="text-white hover:text-lightBlue-200 px-3 py-2 text-sm font-medium"
                                              aria-current="page">About Us</Link>
                                        <Link to="/contact-us"
                                              className="text-white hover:text-lightBlue-200 px-3 py-2 text-sm font-medium"
                                              aria-current="page">Contact Us</Link>
                                        <Link to="/terms"
                                              className="text-white hover:text-lightBlue-200 px-3 py-2 text-sm font-medium"
                                              aria-current="page">Terms</Link>
                                        <Link to="/faq"
                                              className="text-white hover:text-lightBlue-200 px-3 py-2 text-sm font-medium"
                                              aria-current="page">FAQ</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-4 flex items-center md:ml-6">
                                    <button
                                        onClick={() => {
                                            if (localStorage.getItem(StorageKeys.TOKEN)) navigate(`/${localStorage.getItem(StorageKeys.ROLE)}`)
                                            else navigate('/auth/login')
                                        }}
                                        className="bg-green-400 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none
                                         lg:mr-1 lg:mb-0 ml-3  ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        {localStorage.getItem(StorageKeys.TOKEN) ? 'account' : 'Login / Register'}
                                    </button>
                                </div>
                            </div>
                            <div className="flex md:hidden justify-end">
                                <button
                                    onClick={() => setNavbarOpen(true)}
                                    type="button"
                                    className="relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-white hover:bg-gray-700 hover:text-white focus:outline-none "
                                    aria-controls="mobile-menu" aria-expanded="false">
                                    <span className="absolute -inset-0.5"></span>
                                    <span className="sr-only">Open main menu</span>
                                    <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"/>
                                    </svg>
                                    <svg className="hidden h-6 w-6" fill="none" viewBox="0 0 24 24"
                                         strokeWidth="1.5"
                                         stroke="currentColor" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
                <AppDarwer
                    placement={'left'} open={navbarOpen} onClose={() => setNavbarOpen(false)}>
                    <div id="mobile-menu" className={'bg-blueGray-800 py-3'}>
                       <div className={'px-2'}>
                           <Link
                            to={'/'}
                           >
                               <img alt={'fatra'} src={fatra} className={'h-6 m-2 '}/>
                           </Link>
                       </div>
                        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                            <Link to={'/about-us'}
                                  className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium"
                            >About Us</Link>
                            <Link to={'/about-us'}
                                  className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium"
                            >Contact Us</Link>
                            <Link to={'/about-us'}
                                  className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium"
                            >Terms</Link>
                            <Link to={'/about-us'}
                                  className="bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium"
                            >FAQ</Link>
                        </div>
                        <button
                            onClick={() => {
                                if (localStorage.getItem(StorageKeys.TOKEN)) navigate(`/${localStorage.getItem(StorageKeys.ROLE)}`)
                                else navigate('/auth/login')
                            }}
                            className="bg-green-400 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none
                                         lg:mr-1 lg:mb-0 ml-3  ease-linear transition-all duration-150"
                            type="button"
                        >
                            {localStorage.getItem(StorageKeys.TOKEN) ? 'account' : 'Login / Register'}
                        </button>
                    </div>
                </AppDarwer>

            </div>
        </>
    )
}
