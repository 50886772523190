import fetch, {REQUEST_METHOD} from "../axiosHelper";
import {
    ActiveSmeBody,
    GetSmeListResponse,
    GetSmeLogsResponse,
    GetSmeResponse,
    VerifySmeBody
} from "../../models/sme.models";
import {GetSupplierListResponse, GetSupplierResponse} from "../../models/supplier.models";
import {GetInstallmentListResponse, GetOrderListResponse, GetOrderResponse} from "../../models/order.model";
import {InitPaymentBody} from "../../models/payment.model";


export async function getSmeList(page: number, perPage: number, level: number) {
    let url = `/api/v1/bank/sme/list?page=${page}&perPage=${perPage}&sortBy=createdAt&order=desc`
    if (level !== -10) url = `/api/v1/bank/sme/list?page=${page}&perPage=${perPage}&level=${level}&sortBy=createdAt&order=desc`
    return await fetch<GetSmeListResponse, null>(url, REQUEST_METHOD.GET);
}

export async function getSme(id: string) {
    return await fetch<GetSmeResponse, null>(`/api/v1/bank/sme/show/${id}`, REQUEST_METHOD.GET);
}

export async function getSmeLgs(id: string) {
    return await fetch<GetSmeLogsResponse, null>(`/api/v1/bank/sme/log/list/${id}`, REQUEST_METHOD.GET);
}

export async function verifySme(data: VerifySmeBody) {
    return await fetch<any, VerifySmeBody>(`/api/v1/bank/sme/verify`, REQUEST_METHOD.POST, data);
}

export async function rejectSme(data: VerifySmeBody) {
    return await fetch<any, VerifySmeBody>(`/api/v1/bank/sme/reject`, REQUEST_METHOD.POST, data);
}

export async function bankGetOrderList(page: number, perPage: number, status: number) {
    let url = `/api/v1/bank/order/list?page=${page}&perPage=${perPage}`
    if (status !== -10) url += `&status=${status}`
    return await fetch<GetOrderListResponse, void>(url, REQUEST_METHOD.GET);
}

export async function bankGetOrder(id: string) {
    return await fetch<GetOrderResponse, void>(`/api/v1/bank/order/show/${id}`, REQUEST_METHOD.GET);
}

export async function bankGeInstallments(page: number, perPage: number) {
    let url = `/api/v1/bank/installment/list?page=${page}&perPage=${perPage}&sortBy=createdAt&order=desc`
    return await fetch<GetInstallmentListResponse, void>(url, REQUEST_METHOD.GET);
}

export async function bankPayOrder(data : InitPaymentBody) {
    return await fetch<any, InitPaymentBody>(`/api/v1/bank/order/pay`, REQUEST_METHOD.POST, data);
}



