import {useNavigate} from 'react-router-dom'
// @ts-ignore
import noItem from '../../assets/img/noitem.png'
import Button from "./Button/Button";

function NoData(props: any) {
    const navigate = useNavigate();
    const {description, uri, link} = props;
    return (
        <figure className={'w-fit mx-auto text-center'}>
            <div className={'text-center my-4'}>
                <img src={noItem} alt={description} className={'w-24 mx-auto'}/>
            </div>
            <div className={'text-center my-4'}>
                <span className={'text-primary-light f-small'}>{description}</span>
            </div>
            {link && <Button className="bg-blue-500 text-white active:bg-blueGray-600 text-sm font-bold
                                uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none
                                focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                             onClick={() => navigate(uri)}>
                {link}
            </Button>}
        </figure>
    )
}

export default NoData;
