import {Navigate, Route, Routes} from "react-router-dom";
import Dashboard from "../../views/admin/Dashboard";
import {Role} from "../../models/auth.model";
import StorageKeys from "../../utils/StorageKeys";
import Sidebar from "../../components/base/Sidebar/Sidebar";
import SmeListInsurance from "../../views/insurance/SmeListInsurance";
import SmeInsurance from "../../views/insurance/SmeInsurance";
import SmeOrders from "../../views/sme/SmeOrders";
import OrderListInsurance from "../../views/insurance/OrderListInsurance";
import OrderDetailInsurance from "../../views/insurance/OrderDetailInsurance";
import roleMenus from "../../utils/roleMenus";


export default function Insurance() {
    return (
        <>
            <Sidebar menu={roleMenus[Role.INSURANCE]} title={Role.INSURANCE}/>
            <div className="relative md:ml-64 bg-blueGray-100">
                <div className="px-2 mx-auto w-full py-2">
                    <Routes>
                        <Route path="dashboard" element={<Dashboard/>}/>
                        <Route path="sme" element={<SmeListInsurance/>}/>
                        <Route path="sme/:id/*" element={<SmeInsurance/>}/>
                        <Route path="orders" element={<OrderListInsurance/>}/>
                        <Route path="order/:id" element={<OrderDetailInsurance/>}/>
                        <Route
                            path="*"
                            element={<Navigate to="dashboard" replace={true} />}
                        />
                    </Routes>
                </div>
            </div>
        </>
    );
}
