import {Order} from "../../models/order.model";
import Button from "../base/Button/Button";
import FileLogo from "../../assets/img/other/file.png";
import {useState} from "react";
import {useUploadInvoice} from "../../hooks/provider/useUploadInvoice";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";

export default function SupplierOrderInvoiceUpload(){
    const {id} = useParams()
    const navigate = useNavigate()
    const [filesToUpload, setFilesToUpload] = useState<File>();
    const {uploadPending , uploadInvoice} = useUploadInvoice(() => {
        toast.success('uploaded successfully')
        navigate(-1)
    })
    function handleUploadFiles() {
        if (!filesToUpload) {
            return toast.warning('select file to upload')
        }
        uploadInvoice({file : filesToUpload , orderId : id! });
    }
    return (
        <>
                <div className={'flex flex-row gap-y-4 justify-center mb-4'}>
                    <div className={'md:col-span-6 sm:col-span-12 xs:col-span-12 '}>
                        <p className="font-bold text-2xl text-center"> Upload Invoice </p>
                        <p className="text-gray-dark text-center">
                            if you want to payment by bank you should upload invoice
                        </p>
                        <div className="flex gap-5 w-full mt-5 flex-col">
                            <label
                                className='w-full border-2 p-1 gap-2 cursor-pointer flex items-center text-blueGray-600 border-gray rounded-lg'
                                htmlFor='file'>
                                <img src={FileLogo} alt='' width={30} />
                                <span>
                        {filesToUpload ? filesToUpload.name : "Select File To Upload"}
                    </span>
                            </label>
                            <input
                                id='file'
                                className="w-full border-solid border-2 border-gray
                            hover:shadow-lg cursor-pointer rounded-lg"
                                type="file"
                                style={{ display: "none" }}
                                multiple={false}
                                onChange={(e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        setFilesToUpload(e.target.files[0]);
                                    }
                                }}
                            />
                            <div className={'text-left'}>
                                <Button
                                    loading={uploadPending}
                                    disabled={uploadPending}
                                    type="button"
                                    className="whitespace-nowrap  self-center bg-blueGray-600 text-white"
                                    onClick={handleUploadFiles}
                                >
                                    Upload
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

        </>
    )
}