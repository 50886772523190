import FullyRejected from "components/sme/steps/FullyRejected";
import WaitingForAdmin from "components/sme/steps/WaitingForAdmin";
import {useSmeLogs} from "hooks/sme/useSmeVerification";
import {SME_LEVEL, SME_REJECT_LEVEL} from "utils/smeLevels";
import Card from "../../components/base/Cards/Card";
import AddInformation from "../../components/sme/steps/AddInformation";
import Approved from "../../components/sme/steps/Approved";
import EmailVerification from "../../components/sme/steps/EmailVerification";
import LayoutWrapper from "../../components/sme/steps/LayoutWrapper";
import PhoneVerification from "../../components/sme/steps/PhoneVerification";
import WaitingForBank from "../../components/sme/steps/WaitingForBank";
import UploadDocuments from "components/sme/steps/UploadDocuments";
import WaitingForInsurances from "components/sme/steps/WaitingForInsurances";
import {SME} from "../../models/sme.profile.model";

export interface ISmeChild {
    levelUp: (level: SME_LEVEL) => void;
}

function LogRow({title, desc}: { title: string, desc: string | number }) {
    return (
        <div className="w-full text-sm flex items-center px-3">
            <p className="text-blueGray-600 font-bold">
                {title}:
            </p>
            <p className="px-3">{desc}</p>
        </div>
    )
}

interface SmePanelProps {
    currentLevel: SME_LEVEL | SME_REJECT_LEVEL,
    smeProfile: SME ,
    refetch : () => void
}

const SmePanel = ({currentLevel , smeProfile , refetch}: SmePanelProps) => {


    // function setLevel(newLevel: SME_LEVEL | SME_REJECT_LEVEL) {
    //     if (newLevel != currentLevel) {
    //         updateLevel(newLevel);
    //         getLogList();
    //
    //         if ((currentLevel === SME_REJECT_LEVEL.ConditionalRejected) && newLevel === SME_LEVEL.PhoneVerified)
    //             return;
    //
    //         if (newLevel > SME_LEVEL.Registered) {
    //             toast.success("Your level update")
    //         } else if (newLevel < SME_LEVEL.Registered) {
    //             toast.error("You are rejected");
    //         }
    //     }
    // }

    const {
        getLogList,
        getLogsLoading,
        logs
    } = useSmeLogs();


    function LogView() {
        return (
            <Card title="Verification Timeline">
                <div className="p-3 w-full grid gap-2 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
                    {
                        getLogsLoading ? <p>Loading Logs</p> :
                            logs?.map((log) => (
                                <div key={log._id} className="border-2 border-gray rounded-lg border-dotted">
                                    <p className="p-2 rounded-t-lg w-full text-white bg-blue-700">
                                        {log.createdAt}
                                    </p>
                                    <div className="px-2 py-3">
                                        <LogRow
                                            title="Description"
                                            desc={log.desc}
                                        />
                                        <LogRow
                                            title="Level"
                                            desc={log.level}
                                        />
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </Card>
        )
    }

    return (
        <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12">
                <Card title={'Verification'}>

                    <LayoutWrapper steps={4} activeStep={currentLevel}>

                        <>
                            {currentLevel === SME_LEVEL.Registered && <EmailVerification userEmail={smeProfile.auth_id.email} levelUp={refetch}/>}
                            {currentLevel === SME_LEVEL.EmailVerified &&
                                <PhoneVerification levelUp={refetch}/>}
                            {currentLevel === SME_LEVEL.PhoneVerified && <AddInformation levelUp={refetch} level={currentLevel}/>}
                            {currentLevel === SME_LEVEL.InfoAdded && <UploadDocuments levelUp={refetch}/>}
                            {currentLevel === SME_REJECT_LEVEL.ConditionalRejected && <AddInformation levelUp={refetch} level={currentLevel}/>}
                            {currentLevel === SME_LEVEL.DocsUploaded && <WaitingForAdmin/>}
                            {currentLevel === SME_LEVEL.AdminVerified && <WaitingForBank/>}
                            {currentLevel === SME_LEVEL.BankVerified && <WaitingForInsurances/>}
                            {currentLevel === SME_LEVEL.InsuranceVerified && <Approved
                                level={currentLevel}
                                installments={smeProfile && smeProfile.installments ? smeProfile.installments : []}
                                {...smeProfile!.credit} />}
                            {currentLevel === SME_LEVEL.AdminActivated && <Approved
                                level={currentLevel}
                                installments={smeProfile && smeProfile.installments ? smeProfile.installments : []}
                                {...smeProfile!.credit} />}

                            {
                                (
                                    currentLevel === SME_REJECT_LEVEL.FullRejected ||
                                    currentLevel === SME_REJECT_LEVEL.RejectedByInsurances
                                ) &&
                                <FullyRejected level={currentLevel}/>
                            }
                        </>

                    </LayoutWrapper>
                </Card>
                <LogView/>
            </div>
        </div>
    );
}

export default SmePanel;