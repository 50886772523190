import useSupplierSme from "../../hooks/sme/useSupplierSme";
import {useNavigate, useParams} from "react-router-dom";
import ProfileRow from "../../components/base/profileRow";
import Tag from "../../components/base/tag/Tag";
import {DollarOutlined} from "@ant-design/icons";
import Button from "../../components/base/Button/Button";
import {useContext, useState} from "react";
import AppModal from "../../components/base/modal/AppModal";
import useCreateOrder from "../../hooks/sme/useCreateOrder";
import {useFormik} from "formik";
import * as Yup from "yup";
import {SmeContext} from "../../layouts/roles/SmeLayout";
import {toast} from "react-toastify";


export default function SmeSupplierProfile() {
    const {smeProfile, getProfile} = useContext(SmeContext)
    const navigate = useNavigate()
    const schema = Yup.object({
        desc: Yup.string().required('enter description'),
        amount: Yup.number().required('enter amount').max(smeProfile.credit.amount, `must be less than ${smeProfile.credit.amount}$`).min(1, 'must be more than 1$'),
    });
    const {id} = useParams()
    const {data, isPending} = useSupplierSme(id!)
    const [modal, showModal] = useState(false)

    const {createOrderLoading, createNewOrder} = useCreateOrder((orderId: string) => {
        toast.success("payment link is sent by email and sms")
        toast.success('please check and do payment')
        getProfile()
        navigate(-1)
    })
    const formik = useFormik({
        initialValues: {
            desc: '',
            amount: ''
        },
        validationSchema: schema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
            createNewOrder({
                providerId: id!,
                amount: data.amount,
                description: data.desc
            })
        }
    })
    return (
        <>
            {!isPending && data && <div className="flex p-5 xs:p-2 w-full rounded-lg gap-2 relative flex-col">
                <div className="w-full flex flex-col gap-2 items-center">
                    <div className="w-full p-3 bg-white drop-shadow-xl rounded-lg h-full">
                        <div className={'flex flex-row items-center'}>
                            <p className="text-2xl font-bold flex-auto">{data.desc?.title}</p>
                            <Button
                                onClick={() => showModal(true)}
                                className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            >
                                <DollarOutlined className={'mr-2'}/>
                                create order
                            </Button>
                        </div>
                        <p className="pt-2 mt-2 border-t border-solid border-neutral-500">{data.desc?.text}</p>
                    </div>
                    {
                        data && data.info &&
                        <div className="w-full flex flex-col gap-2">
                            <ProfileRow title="industry"
                                        value={<div className={'flex-row flex gap-x-1 flex-wrap justify-end'}>
                                            {
                                                data.categories?.map(cat =>
                                                    <Tag key={cat._id} color={'blue'}>
                                                        {cat.title}
                                                    </Tag>
                                                )
                                            }
                                        </div>}/>
                            <ProfileRow title="Email" value={data.auth_id.email}/>
                            <ProfileRow title="Email2" value={data.info.email2}/>
                            <ProfileRow title="Phone" value={data.info.phone?.phone}/>
                            <ProfileRow title="Phone2" value={data.info.phone2?.number}/>
                            <ProfileRow title="First Name" value={data.info.firstname}/>
                            <ProfileRow title="Last Name" value={data.info.lastname}/>
                            <ProfileRow title="Address" value={data.info.address}/>
                            <ProfileRow title="Address2" value={data.info.address2}/>
                            <ProfileRow title="Instagram" value={data.info.instagram}/>

                        </div>
                    }
                </div>
            </div>}

            {
                modal &&
                <AppModal
                    open={modal}
                    onClose={() => showModal(false)}>
                    <div className="flex flex-col gap-2 px-2">
                        <h1 className="font-bold text-center">Create New Order</h1>
                        <div className='w-full justify-center flex flex-row gap-1 mb-2 whitespace-nowrap'>
                            <div className='text-center text-blueGray-800 shadow-lg'>
                                <p className=" py-2 px-2  bg-blue-200 rounded-t-lg">
                                    Available Credit
                                </p>
                                <p className="py-2  bg-blue-300">{smeProfile.credit.amount} $</p>
                            </div>
                            <div className='text-center text-blueGray-800 shadow-lg'>
                                <p className="py-2 px-2  bg-green-200 rounded-t-lg">
                                    Spent Credit
                                </p>
                                <p className="py-2   bg-green-300">{smeProfile.credit.spend} $</p>
                            </div>
                        </div>
                        <form onSubmit={formik.handleSubmit}>

                            <div className={'mb-4'}>
                                <input
                                    prefix={'$'}
                                    id={'amount'}
                                    name={'amount'}
                                    type="text"
                                    inputMode={"numeric"}
                                    onChange={formik.handleChange}
                                    className="border-0 px-3 py-3  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="amount"
                                />
                                {formik.errors.amount ?
                                    <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.amount}</p> :
                                    <p className={'h-5 pt-2'}>{" "}</p>}
                            </div>
                            <input
                                id={'desc'}
                                name={'desc'}
                                type="text"
                                onChange={formik.handleChange}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="description"
                            />
                            {formik.errors.desc ?
                                <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.desc}</p> :
                                <p className={'h-5 pt-2'}>{" "}</p>}
                            <div className="flex gap-3 mt-5 justify-center">
                                <Button
                                    onClick={() => showModal(false)}
                                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    close
                                </Button>
                                <Button
                                    loading={createOrderLoading}
                                    type={'submit'}
                                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    create order
                                </Button>
                            </div>
                        </form>
                    </div>
                </AppModal>
            }
        </>
    )
}