import {useMutation} from "@tanstack/react-query";
import {rejectSupplier, verifySupplier} from "../../http/admin/admin-request";


export default function useSupplierMutateAdmin(
    onSuccessVerify: () => void,
    onSuccessReject: () => void,
) {

    const {isPending: verifyLoading, mutate: verify} = useMutation({
        mutationFn: verifySupplier,
        onSuccess() {
            onSuccessVerify()
        }
    })
    const {isPending: rejectLoading, mutate: reject} = useMutation({
        mutationFn: rejectSupplier,
        onSuccess() {
            onSuccessReject()
        }
    })
    return {
        verifyLoading, verify,
        rejectLoading, reject
    }
}