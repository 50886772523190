import {Link, useParams} from "react-router-dom";
import useVerifyPayment from "../../hooks/sme/useVerifyPayment";
import BankVerifiedLogo from "../../assets/img/other/bank_verified.png";
import WarningLogo from "../../assets/img/other/warning.png";

export default function PaymentResult() {
    const {id} = useParams()
    const {isPending, payStatus} = useVerifyPayment(id!)

    return <>
        {!isPending && <>
            {payStatus ?
                <div className="w-full py-10 text-center h-full flex flex-col gap-5 justify-center items-center">
                    <img width={250} alt="" src={BankVerifiedLogo}/>
                    <p className="sm:w-1/2 text-2xl font-bold">
                        payment succesed!
                    </p>
                    <Link to={'/sme/orders'}
                          className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                        see orders
                    </Link>
                </div>
                : <div className="w-1/2 py-10 text-center h-full flex flex-col gap-5 justify-center items-center">
                    <img width={250} alt="" src={WarningLogo}/>
                    <p className="text-lg font-bold">
                        payment failed!
                    </p>
                    <Link to={'/sme/orders'}
                          className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                        see orders
                    </Link>
                </div>}
        </>}
    </>
}