import Button from "components/base/Button/Button";
import {Label, Select, TextInput} from "flowbite-react";
import {useFormik} from "formik";
import {useAddInfo, useProviderProfile} from "hooks/provider/useProviderVerification";
import {Category, ProviderAddInfoBody} from "models/provider.models";
import {FC, useEffect, useRef, useState} from "react";
import {PROVIDER_LEVEL, PROVIDER_REJECT_LEVEL, PROVIDER_REJECT_REASON} from "utils/providerLevels";
import * as Yup from 'yup';
import {IProviderChild} from "../../../views/provider/ProviderVerification";
import CategorySelectionModal from "../CategorySelectionModal";
import WarningLogo from "../../../assets/img/other/warning.png";
import countryCodes from "../../../utils/countryCodes";

const initialValues : any = {
    firstname: "",
    lastname: "",
    companyTile: "",
    companyDesc: "",
    address: "",
    companyVAT: "",
    website: "",
    categoriesSelected: [] ,
    countryCode: countryCodes[0].code
};

const InformationSchema = Yup.object({
    firstname: Yup.string().required('What is your first name ?'),
    lastname: Yup.string().required('What is your last name ?'),
    companyTile: Yup.string().required('What is Your Company Title ?'),
    companyDesc: Yup.string().required('What is Your Company Description ?'),
    companyVAT: Yup.string().required('What is Your Company VAT ?'),
    address: Yup.string().required('What is Your Company Address ?'),
    website: Yup.string().required('What is Your Website Address ?'),
    categoriesSelected: Yup.array().required('What is Select Your Categories').min(1),
    instagram: Yup.string().optional(),
    phone2: Yup.string().optional(),
    countryCode: Yup.string().optional(),
    address2: Yup.string().optional(),
    email2: Yup.string().optional(),
});

const AddInformation: FC<IProviderChild & { level: number }> = ({levelUp, level}) => {

    const [openModal, setOpenModal] = useState(false);
    const selectedItemsRef = useRef<Partial<Category>[]>([]);

    const {addInfo, addInfoLoading, addInfoResponse} = useAddInfo();
    const {isLoading, providerProfile, refetch} = useProviderProfile();

    const formik = useFormik({
        initialValues,
        validationSchema: InformationSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (values: any) => {
            const catsId = [] as string[];
            selectedItemsRef.current.forEach(c => catsId.push(c._id!));
            const finalValues = {
                ...values,
                countryCode : undefined ,
                phone2: {phone: values.phone2, countryCode: values.countryCode},
                categoriesSelected: catsId
            }
            addInfo(finalValues)
        },
    });

    useEffect(() => {
        if (!providerProfile)
            return;
        const {categories, info, desc, ...etc} = providerProfile;
        if (etc.level !== PROVIDER_REJECT_LEVEL.ConditionalRejected)
            return;

        selectedItemsRef.current = categories;
        const categoriesSelected: string[] = [];
        categories.forEach(cat => {
            categoriesSelected.push(cat.title);
            selectedItemsRef.current.push(cat);
        })

        formik.setValues({
            firstname: info.firstname,
            lastname: info.lastname!,
            address: info.address!,
            address2: info.address2!,
            email2: info.email2!,
            phone2: info.phone2 ? info.phone2.number : '',
            countryCode: info.phone2 ? info.phone2.country_code : countryCodes[0].code,
            instagram: info.instagram!,
            companyTile: desc.title!,
            companyDesc: desc.text!,
            companyVAT: "",
            website: info.website,
            categoriesSelected,
        })
    }, [providerProfile]);

    useEffect(() => {
        if (addInfoResponse?.status) {
            levelUp(PROVIDER_LEVEL.InfoAdded);
        }
    }, [addInfoResponse]);

    // function handleModalClose(selectedCats: String[]) {
    function handleModalClose() {
        setOpenModal(false);

        const selectedCats = [] as string[];
        selectedItemsRef.current.forEach(cat => selectedCats.push(cat.title!));
        formik.setValues((prev  :any) => ({...prev, categoriesSelected: selectedCats}));
    }

    function handleClearSelection() {
        selectedItemsRef.current = [];
        formik.setValues((v : any) => ({...v, categoriesSelected: []}))
    }

    return (
        <>
            <form className="relative flex flex-col w-full lg:w-2/3 xl:w-1/2 h-full p-3 gap-5
        overflow-y-scroll hide-scroll items-center"
                  onSubmit={formik.handleSubmit} noValidate={true}>
                {level === PROVIDER_REJECT_LEVEL.ConditionalRejected ?
                    <div className="w-1/2  text-center h-full flex flex-col gap-5 justify-center items-center">
                        <img width={100} alt="" src={WarningLogo}/>
                        <p className="font-bold">{PROVIDER_REJECT_REASON["-2"]}</p>
                        <p className="m-0 text-primary-light">please edit your information</p>
                    </div> :
                    <div className="w-1/2  text-center h-full flex flex-col gap-5 justify-center items-center">
                        <p className="font-bold text-3xl whitespace-nowrap">PROVIDER Add Information</p>
                        <p className="w-full text-justify">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua.
                        </p>
                    </div>
                }

                <div className="w-full flex flex-col">
                    <div className="flex gap-3">
                        <div className="flex-grow">
                            <Label htmlFor="firstname">First Name</Label>
                            <TextInput id="firstname" type="text" placeholder="First Name" required
                                // className="flex-grow"
                                       color={formik.errors.firstname ? "warning" : "gray"}
                                       value={formik.values.firstname}
                                       onChange={formik.handleChange}/>
                        </div>
                        <div className="flex-grow">
                            <Label htmlFor="lastname" className="mt-5">Last Name</Label>
                            <TextInput id="lastname" type="text" placeholder="Last Name" required
                                // className="flex-grow"
                                       color={formik.errors.lastname ? "warning" : "gray"}
                                       value={formik.values.lastname}
                                       onChange={formik.handleChange}/>
                        </div>
                    </div>


                    <Label htmlFor="companyTile" className="mt-5">Company Title</Label>
                    <TextInput id="companyTile" type="text" placeholder="Company Title" required
                               className="flex-grow"
                               color={formik.errors.companyTile ? "warning" : "gray"}
                               value={formik.values.companyTile}
                               onChange={formik.handleChange}/>


                    <Label htmlFor="companyDesc" className="mt-5">Company Description</Label>
                    <TextInput id="companyDesc" type="text" placeholder="Company Description" required
                               className="flex-grow"
                               color={formik.errors.companyDesc ? "warning" : "gray"}
                               value={formik.values.companyDesc}
                               onChange={formik.handleChange}/>


                    <Label htmlFor="address" className="mt-5">Address</Label>
                    <TextInput id="address" type="text" placeholder="Address..." required
                               className="flex-grow"
                               color={formik.errors.address ? "warning" : "gray"}
                               value={formik.values.address}
                               onChange={formik.handleChange}/>

                    <div className="flex gap-3 mt-5">
                        <div className="flex-grow">
                            <Label htmlFor="companyVAT">Company VAT</Label>
                            <TextInput id="companyVAT" type="text" placeholder="Company VAT" required
                                       className="flex-grow"
                                       color={formik.errors.companyVAT ? "warning" : "gray"}
                                       value={formik.values.companyVAT}
                                       onChange={formik.handleChange}/>
                        </div>
                        <div className="flex-grow">
                            <Label htmlFor="website">Website Address</Label>
                            <TextInput id="website" type="text" placeholder="Website Address" required
                                       className="flex-grow"
                                       color={formik.errors.website ? "warning" : "gray"}
                                       value={formik.values.website}
                                       onChange={formik.handleChange}/>
                        </div>
                    </div>

                    <Label htmlFor="categoriesSelected" className="mt-5">Categories</Label>
                    <div className="flex gap-3">
                        <TextInput id="categoriesSelected" type="text" placeholder="Categories..." required
                                   className="flex-grow"
                            // disabled
                                   color={formik.errors.categoriesSelected ? "warning" : "gray"}
                                   value={formik.values.categoriesSelected}
                                   onClick={() => setOpenModal(true)}
                                   onChange={formik.handleChange}
                        />
                        <Button
                            className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 whitespace-nowrap
                            bg-blue-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            disabled={selectedItemsRef.current.length === 0}
                            onClick={handleClearSelection}>
                            Clear
                        </Button>
                    </div>

                    <div className="flex gap-3 mt-5">
                        <div className="flex-grow">
                            <Label htmlFor="instagram">Instagram</Label>
                            <TextInput id="instagram" type="text" placeholder="Instagram"
                                       value={formik.values.instagram}
                                       onChange={formik.handleChange}/>
                        </div>
                        <div className="flex-grow">
                            <Label htmlFor="email2" className="mt-5">Email2</Label>
                            <TextInput id="email2" type="text" placeholder="Email2"
                                       value={formik.values.email2}
                                       onChange={formik.handleChange}/>
                        </div>
                    </div>

                    <div className="flex gap-3">
                        <div className="flex-grow">
                            <Label htmlFor="address2">Address2</Label>
                            <TextInput id="address2" type="text" placeholder="Address2"
                                       value={formik.values.address2}
                                       onChange={formik.handleChange}/>
                        </div>
                    </div>
                    <div className="flex-grow">
                        <Label htmlFor="phone2" className="mt-5">Phone2</Label>
                        <div className={'flex flex-row gap-2'}>
                            <Select
                                id={'countryCode'}
                                value={formik.values.countryCode}
                                onChange={formik.handleChange}
                            >
                                {countryCodes.map((c: any) => <option value={c.code} key={c.name}>
                                    {c.name}
                                </option>)}
                            </Select>

                            <TextInput id="phone2" type="text" placeholder="Phone2"
                                       value={formik.values.phone2}
                                       onChange={formik.handleChange}/>
                        </div>
                    </div>

                </div>


                <Button
                    loading={addInfoLoading}
                    type="submit"
                    className="w-full text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mb-1 whitespace-nowrap
                bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    disabled={addInfoLoading}
                >
                    Submit Form
                </Button>

                {
                    openModal &&
                    <CategorySelectionModal
                        selectedItemsRef={selectedItemsRef}
                        onClose={handleModalClose}
                        openModal={openModal}/>
                }
            </form>
        </>
    );
}

export default AddInformation;