import {adminVerifyInvoiceImage} from "../../http/admin/admin-request";
import {useMutation} from "@tanstack/react-query";

export default  function  useVerifyInvoiceAdmin(onSuccess : () => void){

    const { isPending : verifyLoading ,  mutate : verifyInvoice} = useMutation({
        mutationFn : adminVerifyInvoiceImage ,
        onSuccess : () => {
            onSuccess()
        }
    })
    return {verifyLoading , verifyInvoice}
}