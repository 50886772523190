import Card from "../../components/base/Cards/Card";
import SupplierTable from "../../components/admin/supplier/SupplierTable";



export default function SupplierListAdmin() {
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    <Card title={'List Of Supplier\'s'} >
                        <SupplierTable/>
                    </Card>
                </div>
            </div>
        </>
    );
}
