import {useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {adminGeInstallments} from "../../http/admin/admin-request";


export default function useInstallmentListAdmin (page : number ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.INSTALLMENTS_LIST , page ] ,
        queryFn : () => adminGeInstallments(page , 10)
    })
    return {isPending , data : data?.content.installments , total : data?.content.count}
}