'use client';

import Button from "components/base/Button/Button";
import { TextInput } from "flowbite-react";
import { useResendVerificationEmail, useVerifyEmail } from "hooks/sme/useSmeVerification";
import { FC, useEffect, useState } from "react";
import { SME_LEVEL } from "utils/smeLevels";
import StorageKeys from "../../../utils/StorageKeys";
import { ISmeChild } from "../../../views/sme/SmeVerification";

type FormObject<T> = {
    value: T,
    error: boolean
}


const initInputState = {
    error: false,
    value: ''
} as FormObject<string>;
const EmailVerification: FC<ISmeChild & {userEmail : string}> = ({ levelUp , userEmail }) => {

    const [email, setEmail] = useState<FormObject<string>>({
        error : false , value : userEmail
    });
    const [code, setCode] = useState<FormObject<string>>(initInputState);
    const [countdown, setCountDown] = useState<{ start: boolean, value: number }>({
        start: false, value: 0
    });

    const {
        resendOtpResult,
        isLoading: resendEmailLoading,
        refetch: resendCode
    } = useResendVerificationEmail();

    const {
        confirmEmail,
        emailVerifyLoading,
        emailVerifyResponse
    } = useVerifyEmail();

    useEffect(() => {
        if (resendOtpResult?.status) {
            alert('Your code is: ' + resendOtpResult?.content.code);
            setCountDown({
                start: true,
                value: 20
            })
        }
    }, [resendOtpResult]);

    useEffect(() => {
        setTimeout(() => {
            const email = localStorage.getItem(StorageKeys.EMAIL)!;
            setEmail(prevValue => ({ ...prevValue, value: email }))
        }, 200);
    }, []);

    function handleResendCode() {
        resendCode();
    }

    function onSubmit() {
        if (code.value.length === 0) {
            setCode(prevState => ({ ...prevState, error: true }))
            return;
        }

        confirmEmail({ code: code.value });
    }

    function onEmailChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setEmail({ error: false, value: event.target.value })
    }

    function onOtpChanged(event: React.ChangeEvent<HTMLInputElement>) {
        setCode({ error: false, value: event.target.value.trim() })
    }

    useEffect(() => {
        if (emailVerifyResponse?.status) {
            levelUp(SME_LEVEL.EmailVerified)
        }
    }, [emailVerifyResponse]);

    useEffect(() => {
        let interval: NodeJS.Timer;
        if (countdown.start) {
            interval = setInterval(() => {
                if (countdown.value > 0) {
                    setCountDown(prev => ({ ...prev, value: --prev.value }));
                }
                else {
                    clearInterval(interval);
                    setCountDown({
                        start: false,
                        value: 0
                    })
                }
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        }
    }, [countdown]);

    return (
        <div className="relative flex flex-col w-full lg:w-1/2 h-full p-3 gap-10 justify-center items-center">
            <p className="font-bold text-3xl">SME Verifying Email Address</p>
            <p className="w-full text-justify">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.
            </p>

            <div className="w-full flex gap-2">

                <TextInput disabled={true} id="email1" type="email" required
                    className="flex-grow"
                    color={email.error ? "warning" : "gray"}
                    value={email.value} onChange={onEmailChanged} />
                <Button
                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 whitespace-nowrap 
                bg-blue-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    disabled={countdown.start || resendEmailLoading}
                    onClick={handleResendCode}>
                    {countdown.start ? countdown.value : "Get Code"}
                </Button>
            </div>

            <div className="w-full flex gap-2">
                <TextInput type="text" placeholder="*OTP Code" required
                    color={code.error ? "warning" : "gray"}
                    value={code.value} onChange={onOtpChanged} />

                <Button
                    loading={emailVerifyLoading}
                    className="w-full text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 whitespace-nowrap 
                bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    disabled={emailVerifyLoading || code.value.length != 6}
                    onClick={() => onSubmit()}>
                    Submit Code
                </Button>
            </div>

            {/* {
                (resendEmailLoading || emailVerifyLoading) &&
                <div className="flex justify-center items-center fixed left-0 top-0 w-screen h-screen bg-[#000000AA] z-30">
                    <Spinner size="xl" />
                </div>
            } */}

            {/* {
                emailVerifyLoading &&
                <Backdrop
                    offsetDrawer={32}
                    size="normal"
                />
            } */}

        </div>
    );
}

export default EmailVerification;