import React, {useEffect, useState} from "react";
import StorageKeys from "../../utils/StorageKeys";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {login} from "../../http/auth/auth-requests";
import {toast} from "react-toastify";
import fatra from "../../assets/img/logo/logo.png";
import Button from "../../components/base/Button/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import BankVerifiedLogo from "../../assets/img/other/bank_verified.png";
import ErrorImage from "../../assets/img/other/banned.png";
import {Role} from "../../models/auth.model";
import useOrderDelivery from "../../hooks/provider/useOrderDelivery";

const LoginSchema = Yup.object({
    email: Yup.string().required('enter your email').email('email is incorrect'),
    password: Yup.string().required('enter you password').min(8, 'password is too short')
});
export default function DeliveredOrder() {
    const navigate = useNavigate();
    const [isLogin, setisLogin] = useState(() => {
        if (localStorage.getItem(StorageKeys.TOKEN) && localStorage.getItem(StorageKeys.ROLE) === Role.SUPPLIER) return true
        return false
    })
    const {isPending, mutate} = useMutation({
        mutationFn: login,
        onSuccess: (data) => {
            if (data.content['user-role'] === Role.SUPPLIER) {
                toast.success('login successfully')
                localStorage.setItem(StorageKeys.TOKEN, data.content.token);
                localStorage.setItem(StorageKeys.ROLE, data.content['user-role'])
                localStorage.setItem(StorageKeys.NAME, data.content.user.name)
                localStorage.setItem(StorageKeys.EMAIL, data.content.user.auth_id.email)
                setisLogin(true)
            } else {
                toast.error('you must login as supplier')
            }
        }
    })
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
            mutate(data)
        }
    })
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId');
    const verifyCode = searchParams.get('verifyCode')
    const {isPending: loading, isSuccess} = useOrderDelivery(isLogin, orderId!, verifyCode!);

    useEffect(() => {
        if (isSuccess) {
            toast.success('You order delivery is registered successfully')
            navigate(`/provider/order/${orderId}`)
        }
    }, [isSuccess])

    if (!orderId || !verifyCode) {
        toast.error('your data is incorrect')
        return <Navigate to={'/'} replace={true} key={'sad'}/>
    }

    if (isLogin) return <>
        <div className="w-full py-10 text-center h-full flex flex-col gap-5 justify-center items-center">
            {loading ? <p className={'text-center my-16'}>
                loading ...
            </p> : <>
                {isSuccess ? <img width={250} alt="" src={BankVerifiedLogo}/> :
                    <img width={250} alt="" src={ErrorImage}/>}

                <p className="sm:w-1/2 text-2xl font-bold">
                    {isSuccess ? 'You order delivery is registered successfully' : 'your data is incorrect'}
                </p>
                <Button
                    loading={loading}
                    disabled={loading}
                    onClick={() => navigate('/provider')}
                    className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                    {loading ? 'delivering ...' : 'account'}
                </Button>
            </>
            }
        </div>
    </>
    return <>
        <main>
            <section className="relative w-full h-full min-h-screen">
                <div
                    className="absolute top-0 w-full h-full  bg-no-repeat bg-cover auth-bg"
                ></div>
                <div className="container mx-auto md:px-4 px-3 py-10">
                    <div className="flex content-center items-center justify-center ">
                        <div className="w-full lg:w-4/12  md:px-4 sm:px-1 ">
                            <div
                                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                                <div className="rounded-t px-6 py-4 bg-blueGray-600">
                                    <div className="text-center mb-1">
                                        <img src={fatra} alt={"fatra"}/>
                                    </div>
                                    <div className="text-center mt-2">
                                        <h6 className="text-white text-sm font-bold">
                                            Sign In
                                        </h6>
                                    </div>
                                </div>
                                <p className={'mx-6 my-2 text-red border rounded p-2'}>
                                    login as supplier to verify delivery
                                </p>
                                <div className="flex-auto px-6 py-4">
                                    <form onSubmit={formik.handleSubmit} noValidate={true}>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                htmlFor="grid-password"
                                            >
                                                Email
                                            </label>
                                            <input
                                                id={'email'}
                                                name={'email'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                type="email"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Email"
                                            />
                                            {formik.errors.email ?
                                                <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.email}</p> :
                                                <p className={'h-5 pt-2'}>{" "}</p>}
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                htmlFor="grid-password"
                                            >
                                                Password
                                            </label>
                                            <input
                                                id={'password'}
                                                name={'password'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                type="password"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                            />
                                            {formik.errors.password ?
                                                <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.password}</p> :
                                                <p className={'h-5 pt-2'}>{" "}</p>}
                                        </div>
                                        <div className="text-center mt-6">
                                            <Button
                                                loading={isPending}
                                                disabled={isPending}
                                                className="bg-green-400 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                type="submit"
                                            >
                                                Sign In
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="flex flex-wrap my-6 relative">
                                <div className="w-1/2">
                                    <a
                                        href="views/Links/DeliveredOrder#"
                                        onClick={(e) => e.preventDefault()}
                                        className="text-black"
                                    >
                                        <small></small>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
}