import {QueryKeys} from "../../utils/ReactQueyConstants";
import {smeGetSupplierList} from "../../http/sme/sme-requests";
import {useQuery} from "@tanstack/react-query";


export default function useSupplierListSme (page : number ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SME_SUPPLIER_LIST , page ] ,
        queryFn : () => smeGetSupplierList(page , 10)
    })
    return {isPending , data : data?.content.providers , total : data?.content.count}
}