export const OrderStatusList : any = {
    0 : {
        title : 'created'
    } ,
    1 : {
        title : 'waiting'
    } ,
    2 : {
        title : 'sending'
    } ,
    3 : {
        title : 'delivered'
    } ,
    4 : {
        title : 'invoice uploaded'
    } ,
    5 : {
        title : 'invoice verified by admin'
    } ,
    6 : {
        title : 'paid by bank'
    } ,

    '-1' : {
        title : 'canceled'
    } ,
}


export default OrderStatusList