
'use client';

import Button from 'components/base/Button/Button';
import Spinner from 'components/base/Spinner';
import AppModal from 'components/base/modal/AppModal';
import { ListGroup } from 'flowbite-react';
import { useCategories } from 'hooks/sme/useSmeVerification';
import { Category } from 'models/sme.models';
import { FC, useEffect } from 'react';
import { HiUserCircle } from 'react-icons/hi';

// export type CategorySelection = {
//     id: string,
//     name: string
// }

interface CategorySelectionModal {
    openModal: boolean,
    onClose: () => void,
    selectedItemsRef: React.MutableRefObject<Partial<Category>[]>
    // selectedItemsRef: React.MutableRefObject<string[]>
    // onClose: (selectedItems: String[]) => void
}

const CategorySelectionModal: FC<CategorySelectionModal> = ({ onClose, openModal, selectedItemsRef }) => {

    function handleClose() {
    }

    const { categories, getCategoriesLoading, getCategoryList } = useCategories();

    function handleCheckedChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            const item = { _id: event.target.value, title: event.target.title } as Partial<Category>;
            selectedItemsRef.current.push(item)
        }
        else {
            const selectedIndex = selectedItemsRef.current.findIndex((item) => item._id === event.target.value);
            if (selectedIndex > -1) {
                selectedItemsRef.current.splice(selectedIndex, 1);
            }
        }
    }

    function handleSubmit() {
        onClose();
    }

    useEffect(() => {
        selectedItemsRef.current = [];
    }, []);

    return (
        <AppModal
            open={openModal}
            onClose={handleClose}
        >
            <div className="flex items-center justify-between p-4 md:p-5">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Select You Categories
                </h3>
                <button type="button" onClick={() => {
                    selectedItemsRef.current = [];
                    onClose();
                }}
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900
                rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center 
                dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="static-modal">
                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
            </div>

            {
                getCategoriesLoading ?
                    <div className='w-full h-full flex justify-center items-center gap-3'>
                        <Spinner />
                        <p>Waiting for categories</p>
                    </div>
                    :
                    (categories && categories.status) ?
                        <>
                            <ListGroup className="w-full">
                                {
                                    categories.content.categories.map(item =>
                                        <ListGroup.Item key={item._id} icon={HiUserCircle}>
                                            <span className='w-full flex justify-between'>{item.title}
                                                <input type='checkbox' title={item.title} value={item._id} onChange={handleCheckedChange} defaultChecked={false} />
                                            </span>
                                        </ListGroup.Item>
                                    )
                                }
                            </ListGroup>
                            <Button
                                className="mt-5 w-full text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 whitespace-nowrap 
                                        bg-blue-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                onClick={handleSubmit}>
                                Submit
                            </Button>
                        </>
                        :
                        <div className='w-full h-full flex justify-center items-center gap-3'>
                            <Button className='w-full'>
                                Retry
                            </Button>
                            <p className='text-red-dark'>Request failed</p>

                        </div>
            }


        </AppModal>
    )

}

export default CategorySelectionModal;