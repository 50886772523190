import { useMutation } from "@tanstack/react-query";
import { register } from "http/auth/auth-requests";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { saveLevel, saveRole, saveToken } from "utils/StorageUtils";

export default function useSignup() {
    const navigate = useNavigate();
    const { mutate: signup, isPending } = useMutation({
        mutationFn: register,
        onSuccess(data) {
            toast.success('login successfully')
            saveLevel(data.content.user.level)
            saveToken(data.content.token);
            saveRole(data.content['user-role']);
            navigate(`/${data.content['user-role']}`)
        },
    })

    return {
        loading: isPending,
        signup
    }
}