
interface OrderDetailProps {
    latePay : {
        once : boolean ,
        active : boolean
    }
}

export default function OrderPaymentStatus({latePay} : OrderDetailProps){
    return <div className={'px-4 pb-4'}>
        <div>
            <div className={'py-2 gap-x-2 align-middle'}>
                <ul className={'list-disc mt-2'}>
                    {latePay.once ? <li className={'ml-5 text-red-dark'}>
                        this order has late payment one time
                    </li> : <li className={'ml-5 text-green-500' }>
                        this order had not any late payment installment
                    </li> }

                    {latePay.active ? <li className={'ml-5 text-red-dark'}>
                        this order has late payment installment currently
                    </li> : <li className={'ml-5 text-green-500'}>
                        this order has not any late payment installment currently
                    </li>}

                </ul>
            </div>
        </div>
    </div>

}

