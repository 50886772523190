import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {smeGetSupplier} from "../../http/sme/sme-requests";


export default function useSupplierSme (id : string) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SME_SUPPLIER , id] ,
        queryFn : () => smeGetSupplier(id)
    })
    return {data : data?.content.provider , isPending}
}