import {Order, OrderStatusEnum} from "../../models/order.model";
import {useParams} from "react-router-dom";
import OrderStatus from "./OrderStatus";
import StorageKeys from "../../utils/StorageKeys";
import {Role} from "../../models/auth.model";
import {copyText} from "../../utils/general";
import {toast} from "react-toastify";
import Button from "../base/Button/Button";
import {CheckCircleFilled} from "@ant-design/icons";
import useAcceptOrder from "../../hooks/provider/useAcceptOrder";
import QRCode from "react-qr-code";


interface OrderDetailProps {
    order: Order,
    showDetail?: boolean
}

export default function OrderDetail({order, showDetail}: OrderDetailProps) {
    const {id} = useParams()
    const {isPending, mutate} = useAcceptOrder(() => {
        toast.success('order accepted successfully')
        window.location.reload()
    })
    return <div className={'px-4 pb-4'}>
        <div>
            <div className={'flex justify-end gap-x-2'}>
                {localStorage.getItem(StorageKeys.ROLE) === Role.SUPPLIER && order.status === OrderStatusEnum.waiting &&
                    <>
                        <Button
                            loading={isPending}
                            disabled={isPending}
                            onClick={() => mutate({orderId: id!})}
                            className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                            <CheckCircleFilled className={'mr-2'}/>
                            accept order
                        </Button>
                    </>
                }
            </div>
            <div className={'py-2 gap-x-2 align-middle'}>
                <span className={'text-neutral-500'}>SME : </span> {order?.sme_id.desc.title}
            </div>
            <div className={'py-2'}>
                <span className={'text-neutral-500'}>Supplier : </span> {order?.provider_id.desc.title}
            </div>
            <div className={'py-2'}>
                <span className={'text-neutral-500'}>Created At : </span> {new Intl.DateTimeFormat('en-GB', {
                dateStyle: 'full',
                timeStyle: 'long',
            }).format(new Date(order.createdAt))}
            </div>
            <div className={'py-2'}>
                <span className={'text-neutral-500'}>Updated At : </span>{new Intl.DateTimeFormat('en-GB', {
                dateStyle: 'full',
                timeStyle: 'long',
            }).format(new Date(order.updatedAt))}
            </div>
            <div className={'py-2'}>
                <span className={'text-neutral-500'}>Description : </span>
                {order.description}
            </div>
            {/*{*/}
            {/*    localStorage.getItem(StorageKeys.ROLE) === Role.SME && order.verify_code && <div className={'py-2'}>*/}
            {/*        <span className={'text-neutral-500'}>Verify Link : </span>*/}
            {/*        <span className={'text-blue-700 cursor-pointer'} onClick={() => {*/}
            {/*            copyText(`https://fatra.solutions/delivered-order?orderId=${id}&verifyCode=${order.verify_code}`, () => {*/}
            {/*                toast.success('link copied')*/}
            {/*            })*/}
            {/*        }}>*/}
            {/*            {`https://fatra.solutions/delivered-order?orderId=${id}&verifyCode=${order.verify_code}`}*/}
            {/*        </span>*/}
            {/*    </div>*/}

            {/*}*/}
            {
                localStorage.getItem(StorageKeys.ROLE) === Role.SME && order.verify_code && <div className={'py-2'}>
                    <span className={'text-neutral-500'}>Delivery QR Code : </span>
                    <div style={{height: "auto", margin: "0 auto", maxWidth: 200, width: "100%"}}>
                        <QRCode
                            size={256}
                            style={{height: "auto", maxWidth: "100%", width: "100%"}}
                            value={`https://fatra.solutions/delivered-order?orderId=${id}&verifyCode=${order.verify_code}`}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>

            }

            <div className={'py-2 inline-flex gap-x-2 align-middle'}>
                <span className={'text-neutral-500'}>Order Status : </span> <OrderStatus status={order?.status}
                                                                                         paid={order?.paid}/>
            </div>
            <hr className={'border-neutral-500'}/>
            <div className={'flex flex-row flex-wrap'}>
                <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                    <div className={'py-2'}>
                        <span className={'text-neutral-500'}>Amount : </span> <span
                        className={'text-blueGray-800 font-bold text-lg'}>
                            {order?.amount.toLocaleString()} $
                        </span>
                    </div>
                </div>
                <div className={'md:w-1/2 sm:w-full xs:w-full'}>

                </div>
            </div>

            {showDetail && <>
                <div className={'flex flex-row flex-wrap'}>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span className={'text-neutral-500'}>Discount Rate : </span> {order?.tax_rate * 100} %
                        </div>
                    </div>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span className={'text-neutral-500'}>Discount : </span> {order.amount * order?.tax_rate} $
                        </div>
                    </div>
                </div>
                <div className={'flex flex-row flex-wrap'}>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span
                                className={'text-neutral-500'}>Bank Share Rate : </span> {order?.bank_share_rate * 100} %
                        </div>
                    </div>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span
                                className={'text-neutral-500'}>Total Bank Share : </span> {order?.amount * order.tax_rate * order.bank_share_rate} $
                        </div>
                    </div>
                </div>
                <div className={'flex flex-row flex-wrap'}>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span
                                className={'text-neutral-500'}>Fatra Share Rate : </span> {100 - order?.bank_share_rate * 100} %
                        </div>
                    </div>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span
                                className={'text-neutral-500'}>Total Fatra Share : </span> {order?.amount * order.tax_rate * (100 - order?.bank_share_rate * 100) / 100} $
                        </div>
                    </div>
                </div>
            </>}

            {
                localStorage.getItem(StorageKeys.ROLE) === Role.SUPPLIER && <div className={'flex flex-row flex-wrap'}>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span className={'text-neutral-500'}>Discount Rate : </span> {(order?.tax_rate) * 100} %
                        </div>
                    </div>
                    <div className={'md:w-1/2 sm:w-full xs:w-full'}>
                        <div className={'py-2'}>
                            <span className={'text-neutral-500'}>Supplier Share : </span> <span
                            className={'font-bold text-lg'}>
                            {(order.amount * (1 - order?.tax_rate)).toLocaleString()} $
                        </span>
                        </div>
                    </div>
                </div>
            }

        </div>
    </div>

}