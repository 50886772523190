import {useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {deliverOrder} from "../../http/provider/provider-requests";


export default function useOrderDelivery  (enabled : boolean , orderId : string , verifyCode : string) {
    const {isPending , isSuccess } = useQuery({
        queryKey : [QueryKeys.DELIVERED , enabled , orderId , verifyCode] ,
        queryFn : () => deliverOrder({orderId , verifyCode}) ,
        enabled : enabled ,
        retry: 0
    })

    return {isPending , isSuccess}

}