import {useMutation} from "@tanstack/react-query";
import {createOrder} from "../../http/sme/sme-requests";


export default function useCreateOrder(onSuccess : (orderId : string) => void){
    const {isPending: createOrderLoading, mutate: createNewOrder} = useMutation({
        mutationFn: createOrder,
        onSuccess(data) {
            console.log("orderId" , data.content.order._id )
            onSuccess(data.content.order._id)
        }
    })

    return {createOrderLoading , createNewOrder}
}