import {Order, OrderStatusEnum} from "../../models/order.model";
import Button from "../base/Button/Button";
import {CheckCircleFilled} from "@ant-design/icons";
import React, {useState} from "react";
import useVerifyInvoiceAdmin from "../../hooks/admin/useVerifyInvoiceAdmin";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import StorageKeys from "../../utils/StorageKeys";
import {Role} from "../../models/auth.model";
import usePayOrderBank from "../../hooks/bank/usePayOrderBank";
import AppModal from "../base/modal/AppModal";

interface OrderDetailProps {
    order: Order,
}

const baseUrl: string = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '185.237.14.67:3000';

export default function OrderInvoice({order}: OrderDetailProps) {
    const {id} = useParams()
    const navigate = useNavigate()
    const {verifyLoading, verifyInvoice} = useVerifyInvoiceAdmin(() => {
        toast.success('invoice verified successfully');
        navigate(-1)
    })
    const {payLoading, payOrderByBank} = usePayOrderBank(() => {
        toast.success('order paid successfully');
        navigate(-1)
    })
    const [payModal, showPayModal] = useState(false);
    return (
        <>
            {localStorage.getItem(StorageKeys.ROLE) === Role.ADMIN && order && order.status === OrderStatusEnum.uploadedInvoice &&
                <div className={'flex justify-end gap-x-2 mr-6 mb-2'}>
                    <Button
                        loading={verifyLoading}
                        disabled={verifyLoading}
                        type={'button'}
                        onClick={() => verifyInvoice({
                            orderId: id!
                        })}
                        className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                        <CheckCircleFilled className={'mr-2'}/>
                        verify invoice
                    </Button>
                </div>}
            {localStorage.getItem(StorageKeys.ROLE) === Role.BANK && order && order.status === OrderStatusEnum.invoiceVerifiedByAdmin &&
                <div className={'flex justify-end gap-x-2 mr-6 mb-2'}>
                    <Button

                        type={'button'}
                        onClick={() => showPayModal(true)}
                        className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                    >
                        <CheckCircleFilled className={'mr-2'}/>
                        pay order
                    </Button>
                </div>}
            {order && order.invoice && <div>
                <a
                    href={`${baseUrl}/api/v1/pub/order/invoice/file/${order.invoice.file}`}
                    target="_blank"
                    className={'h-96 cursor-pointer block relative border rounded overflow-hidden border-primary-light border-solid'}>
                    <img className={'w-full h-full  object-cover'}
                         src={`${baseUrl}/api/v1/pub/order/invoice/file/${order.invoice.file}`} alt={"file"}/>
                </a>
            </div>}
            <AppModal
                open={payModal}
                onClose={() => showPayModal(false)}>
                <div className="flex flex-col gap-2 px-2">
                    <h1 className="font-bold"> Pay Order</h1>
                    <h4>if you pay this order please confirm your payment </h4>

                    <div className="flex gap-3 mt-5 justify-center">
                        <Button
                            onClick={() => showPayModal(false)}
                            className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                            close
                        </Button>
                        <Button
                            loading={payLoading}
                            disabled={payLoading}
                            type={'button'}
                            className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                            onClick={() => payOrderByBank({
                                orderId: id!
                            })}
                        >
                            confirm
                        </Button>
                    </div>
                </div>
            </AppModal>
        </>
    )
}