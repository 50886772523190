import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {adminGetOrderList} from "../../http/admin/admin-request";


export default function useOrderListAdmin (page : number , status : number ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.ADMIN_ORDER_LIST , page , status ] ,
        queryFn : () => adminGetOrderList(page , 10 , status)
    })
    return {isPending , data : data?.content.orders , total : data?.content.count}
}