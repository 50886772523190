const roleMenus = {
    admin: [
        {
            title: 'Dashboard',
            path: '/admin/dashboard'
        },
        {
            title: 'Sme\'s',
            path: '/admin/sme'
        },
        {
            title: 'Supplier\'s',
            path: '/admin/supplier'
        },
        {
            title: 'Order\'s',
            path: '/admin/orders'
        },
        {
            title: 'Installment\'s',
            path: '/admin/installments'
        },
        {
            title: 'Settings',
            path: '/admin/settings'
        },
    ],
    sme: [
        {
            title: 'Verification',
            path: '/sme/verification'
        },
        {
            title: 'Profile',
            path: '/sme/profile'
        },
        {
            title: 'Dashboard',
            path: '/sme/dashboard'
        },
        {
            title: 'Supplier\'s',
            path: '/sme/supplier'
        },
        {
            title: 'Order\'s',
            path: '/sme/orders'
        },
    ],
    provider: [
        {
            title: 'Verification',
            path: '/provider/verification'
        },
        {
            title: 'dashboard',
            path: '/provider/dashboard'
        },
        {
            title: 'profile',
            path: '/provider/profile'
        },
        {
            title: 'Order\'s',
            path: '/provider/orders'
        },
    ],
    bank: [
        {
            title: 'Dashboard',
            path: '/bank/dashboard'
        },
        {
            title: 'Sme\'s',
            path: '/bank/sme'
        },
        {
            title: 'Order\'s',
            path: '/bank/orders'
        },
        {
            title: 'Installment\'s',
            path: '/bank/installments'
        }
    ],
    insurance: [
        {
            title: 'Dashboard',
            path: '/insurance/dashboard'
        },
        {
            title: 'Sme\'s',
            path: '/insurance/sme'
        },
        {
            title: 'Order\'s',
            path: '/insurance/orders'
        },
    ]
}
export default roleMenus