import { ISampleProfile } from "models/sme.profile.model";
import {
    CategoriesModel,
    DeleteDocBody,
    DocListResponseModel,
    DocsModel,
    GetSmeLogsResponse,
    SmeAddInfoBody,
    SmeAddInfoResponse,
    SmeAddPhoneBody,
    SmeAddPhoneResponse,
    SmeConfirmDocResponse,
    SmeInfoResponseModel,
    SmeResendEmailResponse,
    SmeValidatePhoneBody,
    SmeValidatePhoneResponse,
    SmeVerifyEmailBody,
    SmeVerifyEmailResponse
} from "../../models/sme.models";

import fetch, { REQUEST_METHOD, upload } from "../axiosHelper";
import {GetSupplierListResponse, GetSupplierResponse} from "../../models/supplier.models";
import {CreateOrderBody, DeliveredOrderBody, GetOrderListResponse, GetOrderResponse} from "../../models/order.model";
import {InitPaymentBody, VerifyPaymentBody, VerifyPaymentResponse} from "../../models/payment.model";


export async function verifyEmail(data: SmeVerifyEmailBody) {
    return await fetch<SmeVerifyEmailResponse, SmeVerifyEmailBody>(
        `/api/v1/sme/email/validate`, REQUEST_METHOD.POST, data
    );
}
export async function resendEmail() {
    return await fetch<SmeResendEmailResponse, void>(`/api/v1/sme/email/resend`, REQUEST_METHOD.GET);
}

export async function addPhone(data: SmeAddPhoneBody) {
    return await fetch<SmeAddPhoneResponse, SmeAddPhoneBody>(
        `/api/v1/sme/phone/add`, REQUEST_METHOD.POST, data
    );
}
export async function validatePhone(data: SmeValidatePhoneBody) {
    return await fetch<SmeValidatePhoneResponse, SmeValidatePhoneBody>(
        `/api/v1/sme/phone/validate`, REQUEST_METHOD.POST, data
    );
}

export async function getProfile() {
    return await fetch<ISampleProfile, void>(`/api/v1/sme/profile/get`, REQUEST_METHOD.GET);
}

export async function getCategories() {
    return await fetch<CategoriesModel, void>(`/api/v1/sme/categories/list`, REQUEST_METHOD.GET);
}

export async function addInfo(data: SmeAddInfoBody) {
    return await fetch<SmeAddInfoResponse, SmeAddInfoBody>(
        `/api/v1/sme/info/add`, REQUEST_METHOD.POST, data
    );
}

export async function getInfo() {
    return await fetch<SmeInfoResponseModel, void>(
        `/api/v1/sme/info/get`, REQUEST_METHOD.GET
    );
}

export async function getDocs() {
    return await fetch<DocListResponseModel, void>(`/api/v1/sme/doc/list`, REQUEST_METHOD.GET);
}

export async function uploadDocs(data : {file: File, desc: string}) {
    return await upload<DocsModel>(
        `/api/v1/sme/doc/upload`, data.file , data.desc
    );
}


export async function verifyDocs() {
    return await fetch<SmeConfirmDocResponse, void>(
        `/api/v1/sme/doc/confirm`, REQUEST_METHOD.POST
    );
}


export async function deleteDocs(data: DeleteDocBody) {
    return await fetch<SmeConfirmDocResponse, DeleteDocBody>(
        `/api/v1/sme/doc/upload`, REQUEST_METHOD.DELETE, data
    );
}

export async function getSmeLogs() {
    return await fetch<GetSmeLogsResponse, void>(`/api/v1/sme/log/list`, REQUEST_METHOD.GET);
}


//supplier
export async function smeGetSupplierList(page: number, perPage: number ) {
    let url = `/api/v1/sme/provider/list?page=${page}&perPage=${perPage}`;
    return await fetch<GetSupplierListResponse, void>(url, REQUEST_METHOD.GET);
}

export async function smeGetSupplier(id : string) {
    return await fetch<GetSupplierResponse, void>(`/api/v1/sme/provider/show/${id}`, REQUEST_METHOD.GET);
}

//order
export async function smeGetOrderList(page: number, perPage: number , status : number) {
    let url = `/api/v1/sme/order/list?page=${page}&perPage=${perPage}`
    if (status !== -10) url += `&status=${status}`
    return await fetch<GetOrderListResponse, void>(url, REQUEST_METHOD.GET);
}

export async function smeGetOrder(id : string) {
    return await fetch<GetOrderResponse, void>(`/api/v1/sme/order/show/${id}`, REQUEST_METHOD.GET);
}

export async function createOrder(data : CreateOrderBody) {
    return await fetch< any , CreateOrderBody>(`/api/v1/sme/order/create`, REQUEST_METHOD.POST , data);
}
export async function deliveredOrder(data : DeliveredOrderBody) {
    return await fetch<any, DeliveredOrderBody>(`/api/v1/sme/order/delivered`, REQUEST_METHOD.POST , data);
}

export async function initPayment(data : InitPaymentBody) {
    return await fetch<any, InitPaymentBody>(`/api/v1/sme/pay/init`, REQUEST_METHOD.POST , data);
}
export async function payInstallment(data : InitPaymentBody) {
    return await fetch<any, InitPaymentBody>(`/api/v1/sme/pay/installment`, REQUEST_METHOD.POST , data);
}


export async function verifyPayment(data : VerifyPaymentBody) {
    return await fetch<VerifyPaymentResponse, VerifyPaymentBody>(`/api/v1/sme/pay/verify`, REQUEST_METHOD.POST , data);
}


export async function smeGeInstallments(page: number, perPage: number) {
    return await fetch<any, void>(`/api/v1/sme/installment/list?page=${page}&perPage=${perPage}&sortBy=createdAt&order=desc`, REQUEST_METHOD.GET);
}







