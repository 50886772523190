import CustomStepView from "components/base/stepview/StepView";
import React, { FC } from "react";

interface LayoutWrapperProps {
    steps: number,
    activeStep: number,
    children: React.ReactNode
}

const LayoutWrapper: FC<LayoutWrapperProps> = ({ children }) => {
    return (
        <div className="flex">
            <div className="hide-scroll p-3 overflow-hidden overflow-y-scroll w-full 
            flex justify-center items-center">
                {children}
            </div>
        </div>
    )
}

export default LayoutWrapper;