export interface CreateOrderBody {
    providerId: string,
    amount: number,
    description: string
}

export interface DeliveredOrderBody {
    orderId: string,
    verifyCode: string
}

export interface Installment {
    _id: string,
    order_id : string,
    ordinal_number: number,
    rate: number,
    amount: number,
    updatedAt: string,
    paid: {
        at?: string ,
        deadline? : string
    }
}

export interface Order {
    installments: Installment[],
    latePay: {
        active: boolean,
        once: boolean
    },
    _id: string,
    sme_id: {
        _id: string,
        auth_id: {
            email: string
        },
        desc: {
            title: string,
            text: string
        }
    },
    provider_id: {
        _id: string,
        auth_id: {
            email: string
        },
        desc: {
            title: string,
            text: string
        }
    },
    status: OrderStatusEnum,
    "paid": false,
    amount: number,
    bank_share_rate: number,
    tax_rate: number,
    description: string,
    createdAt: string,
    updatedAt: string,
    verify_code? : string ,
    invoice? : {
        createdAt :string ,
        file : string ,
    }
}


export interface GetOrderListResponse {
    status: boolean,
    content: {
        orders: Order[],
        count: number
    }
}

export interface GetInstallmentListResponse {
    status: boolean,
    content: {
        installments: Installment[],
        count: number
    }
}

export interface GetOrderResponse {
    status: boolean,
    content: {
        order: Order
    }
}

export enum OrderStatusEnum {
    canceled = -1,
    created = 0,
    waiting = 1,
    sending = 2,
    delivered = 3,
    uploadedInvoice = 4,
    invoiceVerifiedByAdmin = 5 ,
    paidByBank = 6
}

export const OrderStatuses : any = {
    '-1' : {
        title : 'canceled'
    } ,
    "0" : {
        title : 'created'
    } ,
    "1" : {
        title : 'waiting'
    } ,
    "2" : {
        title : 'sending'
    } ,
    "3" : {
        title : 'delivered'
    } ,
    "4" : {
        title : 'uploaded Invoice'
    } ,
    "5" : {
        title : 'invoice Verified By Admin'
    } ,
    "6" : {
        title : 'paid by bank'
    } ,
}

