const WaitingForAdmin = () => {
    return (
        <div className="relative flex flex-col w-full lg:w-1/2 h-full p-3 gap-10
                        overflow-y-scroll hide-scroll items-center">
            <img alt="" src="/assets/images/success.png" width={250} />
            <p className="text-2xl text-center font-bold">Your account created successfully</p>
            <p className="text-xl font-bold text-gray-dark">Waiting for admin</p>
        </div>
    );
}

export default WaitingForAdmin;