import {  Route, Routes } from "react-router-dom";

import Navbar from "../components/base/Navbars/AuthNavbar";
import FooterSmall from "../components/base/Footers/FooterSmall";
import Login from "../views/auth/Login";
import Register from "../views/auth/Register";

export default function Auth() {
  return (
    <>
      {/*<Navbar transparent />*/}
      <main>
        <section className="relative w-full h-full min-h-screen">
          <div
            className="absolute top-0 w-full h-full  bg-no-repeat bg-cover auth-bg"
          ></div>
          <Routes>
            <Route path="login" element={<Login/>} />
            <Route path="register/:role" element={<Register/>} />
          </Routes>
          <FooterSmall/>
        </section>
      </main>
    </>
  );
}
