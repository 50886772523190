import axios, { Axios } from 'axios';
import { toast } from "react-toastify";
import StorageKeys from "../utils/StorageKeys";

export const baseUrl: string = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '185.237.14.67:3000';

export enum REQUEST_METHOD {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete'
}


// todo add interceptor for handle error
async function fetch<Response, RequestBody>(url: string, method: REQUEST_METHOD, data?: RequestBody) {
    let headers = {
        'Content-Type': 'application/json',
        "authorization": `Bearer ${localStorage.getItem(StorageKeys.TOKEN)}`,
    };
    try {
        let response;
        response = await axios({
            url: baseUrl + url,
            data: data ? { ...data } : {},
            method,
            headers,
        })
        return response.data as Response;
    } catch (e: any) {
        console.log(e.response.statusCode)
        toast.error(e.response.data.msg)
        throw e
    }
}

export async function upload<Response>(url: string, imageFiles: File , desc : string) {
    let headers = {
        'Content-Type': 'multipart/form-data',
        "authorization": `Bearer ${localStorage.getItem(StorageKeys.TOKEN)}`,
    };

    const formData = new FormData();
    formData.append('title', desc);
    formData.append('smeDoc', imageFiles);
    // formData.append(imageFiles.name, imageFiles);

    try {
        let response;
        response = await axios({
            url: baseUrl + url,
            data: formData,
            method: REQUEST_METHOD.POST,
            headers,
            timeout: 10000
        })
        return response.data as Response;
    } catch (e: any) {
        console.log(e.response.data.msg)
        toast.error(e.response.data.msg)
        throw e
    }
}
export async function uploadProvider<Response>(url: string, imageFiles: File , desc : string) {
    let headers = {
        'Content-Type': 'multipart/form-data',
        "authorization": `Bearer ${localStorage.getItem(StorageKeys.TOKEN)}`,
    };

    const formData = new FormData();
    formData.append('title', desc);
    formData.append('providerDoc', imageFiles);
    // formData.append(imageFiles.name, imageFiles);

    try {
        let response;
        response = await axios({
            url: baseUrl + url,
            data: formData,
            method: REQUEST_METHOD.POST,
            headers,
            timeout: 10000
        })
        return response.data as Response;
    } catch (e: any) {
        console.log(e.response.data.msg)
        toast.error(e.response.data.msg)
        throw e
    }
}

export async function httpUploadInvoice<Response>(url: string, imageFiles: File , orderId : string) {
    let headers = {
        'Content-Type': 'multipart/form-data',
        "authorization": `Bearer ${localStorage.getItem(StorageKeys.TOKEN)}`,
    };

    const formData = new FormData();
    formData.append('orderId', orderId);
    formData.append('orderInvoice', imageFiles);
    // formData.append(imageFiles.name, imageFiles);

    try {
        let response;
        response = await axios({
            url: baseUrl + url,
            data: formData,
            method: REQUEST_METHOD.POST,
            headers,
            timeout: 10000
        })
        return response.data as Response;
    } catch (e: any) {
        toast.error(e.response.data.msg)
        throw e
    }
}


export default fetch
