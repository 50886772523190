

export const SmeRequirements = [
    {
        key : '-1' ,
        title : ''
    },
    {
        key : '0' ,
        title : 'Article of association',
        required: true
    },
    {
        key : '1' ,
        title : 'CR (commercial registration) license' ,
        required: true
    },
    {
        key : '2' ,
        title : 'ID of shareholders',
        required: true
    },
    {
        key : '3' ,
        title : 'Bank certified SME business account IBAN',
        required: true
    },
    {
        key : '4' ,
        title : 'A comprehensive financial statement (past two years)'
    },
    {
        key : '5' ,
        title : 'A bank statement covering the most recent year'
    }
]

export const SupplierRequirements = [
    {
        key : '-1' ,
        title : ''
    },
    {
        key : '0' ,
        title : 'Article of association',
        required: true
    },
    {
        key : '1' ,
        title : 'CR (commercial registration) license',
        required: true
    },
    {
        key : '2' ,
        title : 'ID of shareholders',
        required: true
    },
    {
        key : '3' ,
        title : 'A comprehensive financial statement (past two years)',
        required: true
    },
    {
        key : '4' ,
        title : 'A bank statement covering the most recent year'
    }
]