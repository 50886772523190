
'use client';

import AppModal from 'components/base/modal/AppModal';
import { Button, ListGroup, Modal } from 'flowbite-react';
import { useCategories } from 'hooks/sme/useSmeVerification';
import { FC, useEffect, useRef, useState } from 'react';
import { HiCloudDownload, HiInbox, HiOutlineAdjustments, HiUserCircle } from 'react-icons/hi';

export type CategorySelection = {
    id: string,
    name: string
}

interface DeleteDocConfirmModalProps {
    openModal: boolean,
    id: string,
    onClose: (accept: boolean, id: string) => void,
}

const DeleteDocConfirmModal: FC<DeleteDocConfirmModalProps> = ({ onClose, openModal, id }) => {

    return (
        <AppModal
            open={openModal}
            onClose={() => onClose(false, id)}
        >
            <div className='p-4 flex flex-col gap-5'>
                <div className="flex items-center justify-between">
                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                        Warning
                    </h3>
                </div>

                <p>Are you sure to delete this document ?</p>

                <div className="w-full flex gap-3">

                    <Button
                        className="whitespace-nowrap mt-5 w-full"
                        onClick={() => onClose(false, id)}
                    >
                    No
                    </Button>

                    <Button
                        className="whitespace-nowrap mt-5 w-full bg-pink-700"
                        onClick={() => onClose(true, id)}
                        >
                        Yes
                    </Button>
                </div>
            </div>

        </AppModal>
    )

}

export default DeleteDocConfirmModal;