import {Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import Card from "../../components/base/Cards/Card";
import Tag from "../../components/base/tag/Tag";
import Tabs from "../../components/base/Tab/tab";
import SmeDocs from "../../components/admin/sme/SmeDocsAdmin";
import useSupplierAdmin from "../../hooks/admin/useSupplierAdmin";
import {Category} from "../../models/sme.models";
import SmeTimeline from "../../components/admin/sme/SmeTimeline";
import SmeInfoComponent from "../../components/admin/sme/SmeInfo";
import providerLevels from "../../utils/providerLevels";
import useSupplierLogsAdmin from "../../hooks/admin/useSupplierLogsAdmin";
import Button from "../../components/base/Button/Button";
import {CheckCircleFilled, CloseCircleFilled} from "@ant-design/icons";
import {useState} from "react";
import {toast} from "react-toastify";
import AppModal from "../../components/base/modal/AppModal";
import {useFormik} from "formik";
import * as Yup from "yup";
import useSupplierMutateAdmin from "../../hooks/admin/useSupplierMutateAdmin";

const tabs = [
    {
        key: 'info',
        title: 'info'
    },
    {
        key: 'documents',
        title: 'documents'
    },
    {
        key: 'verification_timeline',
        title: 'verification timeline'
    },
    // {
    //     key: 'invoices',
    //     title: 'invoices'
    // }
]

enum ModalType {
    ACCEPT,
    REJECT,
    FULL_REJECT
}

const schema = Yup.object({
    desc: Yup.string().required('enter description'),
    taxRate: Yup.number().required('enter discount rate').max(99, 'invalid discount rate').min(1, 'invalid discount rate'),
});

export default function SupplierAdmin() {
    const navigate = useNavigate()
    const {id} = useParams()
    const active = useLocation().pathname.split('/')[4]
    const {isPending, data} = useSupplierAdmin(id!);
    const {logs, logsPending} = useSupplierLogsAdmin(id!)
    const [modal, showModal] = useState({
        type: ModalType.ACCEPT,
        show: false
    })
    const {verify, verifyLoading, rejectLoading, reject} = useSupplierMutateAdmin(
        () => {
            showModal({
                type: ModalType.ACCEPT,
                show: false
            })
            toast.success("Done!")
            navigate('/admin/supplier')
        },
        () => {
            showModal({
                type: ModalType.ACCEPT,
                show: false
            })
            toast.success("Done!")
            navigate('/admin/supplier')
        }
    )
    const formik = useFormik({
        initialValues: {
            desc: '',
            taxRate: 10
        },
        validationSchema: schema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
            if (modal.type === ModalType.ACCEPT) {
                verify({
                    providerId: id!,
                    desc: data.desc,
                    taxRate: data.taxRate / 100
                })
            } else {
                reject({
                    providerId: id!,
                    desc: data.desc,
                    full: modal.type === ModalType.FULL_REJECT
                })
            }
        }
    })
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    {!isPending && data && <Card
                        title={`Supplier Profile : ${data?.info && data.info.firstname ? data.info.firstname + ' ' + data.info.lastname : data.auth_id.email}`}>
                        <div className={'px-4 pb-4'}>
                            <div className={'flex justify-end gap-x-2'}>
                                {data.level === 4 &&
                                    <>
                                        <Button
                                            onClick={() => showModal({
                                                type: ModalType.ACCEPT,
                                                show: true
                                            })}
                                            className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                        >
                                            <CheckCircleFilled className={'mr-2'}/>
                                            Verify Supplier
                                        </Button>
                                        <Button
                                            onClick={() => showModal({
                                                type: ModalType.REJECT,
                                                show: true
                                            })}
                                            className="text-white flex items-center font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                        >
                                            <CloseCircleFilled className={'mr-2'}/>
                                            Reject Supplier
                                        </Button>
                                        <Button
                                            onClick={() => showModal({
                                                type: ModalType.FULL_REJECT,
                                                show: true
                                            })}
                                            className="text-white flex items-center font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                        >
                                            <CloseCircleFilled className={'mr-2'}/>
                                            full Reject Supplier
                                        </Button>
                                    </>
                                }
                            </div>
                            {data.desc && <>
                                <h1 className={'text-lg font-bold'}>{data.desc.title}</h1>
                                <p className={'text-md text-neutral-500 py-2'}>{data.desc.text}</p>
                                <hr/>
                            </>}
                            <div>
                                <div className={'py-2 inline-flex gap-x-2 align-middle'}>
                                    <span className={'text-neutral-500'}>Verification Level : </span> <Tag
                                    color={'blue'}>{providerLevels[String(data.level)].title}</Tag>
                                </div>
                                <div className={'py-2'}>
                                    <span className={'text-neutral-500'}>address : </span> {data.info?.address}
                                </div>
                                <div className={'py-2'}>
                                    <span className={'text-neutral-500'}>phone : </span> {data.info?.phone?.country_code + "  " + data.info?.phone?.number }
                                </div>
                            </div>
                            <hr/>
                            <div className={'inline-flex flex-wrap align-middle gap-x-2 py-6'}>
                                <span>
                                    categories :
                                </span>
                                {
                                    data.categories.map((cat: Category) => <Tag key={cat} color={'blue'}>
                                        {cat.title}
                                    </Tag>)
                                }
                            </div>
                            <Tabs active={active} onTab={(key: string) => navigate(`/admin/supplier/${id}/${key}`)}
                                  tabs={tabs}/>
                            <Routes>
                                <Route path="info" element={<SmeInfoComponent desc={data.desc} info={data.info}/>}/>
                                <Route path="documents" element={<SmeDocs role={'provider'} docs={data.docs}/>}/>
                                <Route path="verification_timeline"
                                       element={<SmeTimeline logs={logs} loading={logsPending}/>}/>
                            </Routes>
                        </div>

                    </Card>}
                </div>
            </div>
            {
                modal.show &&
                <AppModal
                    open={modal.show}
                    onClose={() => showModal({
                        type: ModalType.REJECT,
                        show: false
                    })}>
                    <div className="flex flex-col gap-2 px-2">
                        <h1 className="font-bold">Supplier Verification</h1>
                        <h4>{modal.type === ModalType.ACCEPT ? 'confirm' : 'reject'} this Supplier info and
                            documents</h4>
                        {modal.type === ModalType.FULL_REJECT &&
                            <p className={'text-neutral-500 text-sm'}>if you reject fully Supplier . Supplier will cant
                                continue verification</p>}
                        {modal.type === ModalType.ACCEPT &&
                            <p className={'text-neutral-500 text-sm'}>submit discount rate for Supplier</p>}
                        <form onSubmit={formik.handleSubmit}>
                            <input
                                id={'desc'}
                                name={'desc'}
                                type="text"
                                onChange={formik.handleChange}
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="description"
                            />
                            {formik.errors.desc ?
                                <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.desc}</p> :
                                <p className={'h-5 pt-2'}>{" "}</p>
                            }
                            {modal.type === ModalType.ACCEPT && <>
                                <div className={'relative mt-3'}>
                                    <input
                                        id={'taxRate'}
                                        name={'taxRate'}
                                        type="number"
                                        value={formik.values.taxRate}
                                        onChange={formik.handleChange}
                                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                        placeholder="discount rate"
                                    />
                                    <span className={'text-neutral-500 absolute right-8 top-2'}>
                                                %
                                    </span>
                                </div>
                                {formik.errors.taxRate ?
                                    <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.taxRate}</p> :
                                    <p className={'h-5 pt-2'}>{" "}</p>}
                            </>
                            }
                            <div className="flex gap-3 mt-5 justify-center">
                                <Button
                                    onClick={() => showModal({
                                        type: ModalType.REJECT,
                                        show: false
                                    })}
                                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    close
                                </Button>
                                <Button
                                    loading={modal.type === ModalType.ACCEPT ? verifyLoading : rejectLoading}
                                    type={'submit'}
                                    className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-800 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                >
                                    {modal.type === ModalType.ACCEPT && 'confirm'}
                                    {modal.type === ModalType.REJECT && 'reject'}
                                    {modal.type === ModalType.FULL_REJECT && 'full reject'}
                                </Button>
                            </div>
                        </form>
                    </div>
                </AppModal>
            }
        </>
    )
}