import Button from 'components/base/Button/Button';
import Footer from 'components/base/Footers/Footer';
import IndexNavbar from 'components/base/Navbars/IndexNavbar';

const Text = "Terms and conditions is a legal document that outlines the rules and regulations for using a website or mobile application. It includes details such as intellectual property rights, payment terms, dispute resolution, privacy, and more. Terms and conditions can ....";

const TermsPage = () => {


    return (
        <>
            <IndexNavbar/>
            <div className='overflow-x-hidden'>
                <div
                    className='bg-blueGray-700 pb-32 w-screen overflow-x-hidden overflow-y-scroll flex flex-col items-center'>
                    <div
                        className="bg-cover w-screen h-screen px-6 flex flex-col md:flex-row p-6 gap-6 justify-evenly items-center"
                        style={{
                            height: "450px",
                            background: 'linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(51,65,85,1) 35%, rgba(0,0,0,1) 100%)',
                        }}>

                        <div className='flex flex-col md:w-1/2 lg:w-1/3 justify-evenly w-full h-full px-6 md:px-0'>
                            <p className='text-gray-100 italic text-4xl font-extrabold drop-shadow'>
                                Terms & Conditions
                            </p>
                            <p className='text-white  text-xl text-justify drop-shadow'>
                                {Text}
                            </p>

                            <Button
                                loading={false}
                                className="bg-blue-500 text-white active:bg-blueGray-600 text-sm font-bold
                                uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none
                                focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                            >
                                Get Start
                            </Button>
                        </div>

                        <img alt='' src='/assets/images/terms.webp'
                             className='hidden md:block w-60 h-60 lg:w-96 lg:h-96'/>

                    </div>

                    <ul className='w-11/12 md:w-9/12 h-fit px-10 py-5 flex flex-col rounded bg-blueGray-600 mt-6 list-decimal'>
                        {
                            Array.from({length: 6}).map(item =>
                                <li className='text-white w-full text-justify p-5 leading-8 text-xl border-b-2 border-gray-500'>
                                    Our support team is here to assist you with any questions or concerns you may have
                                    Our knowledgeable support staff is here to help you resolve any issues you may
                                    encounter
                                    We pride ourselves on providing quick and efficient support to ensure your
                                    satisfaction.
                                </li>
                            )
                        }
                    </ul>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default TermsPage;