import fetch, {REQUEST_METHOD} from "../axiosHelper";
import {
    GetSmeListResponse,
    GetSmeResponse,
    VerifySmeBody
} from "../../models/sme.models";
import {GetOrderListResponse, GetOrderResponse} from "../../models/order.model";


export async function getSmeList(page: number, perPage: number, level: number) {
    let url = `/api/v1/insurance/sme/list?page=${page}&perPage=${perPage}&sortBy=createdAt&order=desc`
    if (level !== -10) url = `/api/v1/insurance/sme/list?page=${page}&perPage=${perPage}&level=${level}&sortBy=createdAt&order=desc`
    return await fetch<GetSmeListResponse, null>(url, REQUEST_METHOD.GET);
}

export async function getSme(id: string) {
    return await fetch<GetSmeResponse, null>(`/api/v1/insurance/sme/show/${id}`, REQUEST_METHOD.GET);
}


export async function verifySmeByInsurance(data: Omit<VerifySmeBody , 'full'> & {credit : number}) {
    return await fetch<any, VerifySmeBody>(`/api/v1/insurance/sme/verify`, REQUEST_METHOD.POST , data);
}
export async function rejectSmeByInsurance(data: VerifySmeBody) {
    return await fetch<any, VerifySmeBody>(`/api/v1/insurance/sme/reject`, REQUEST_METHOD.POST , data);
}

export async function insuranceGetOrderList(page: number, perPage: number) {
    return await fetch<GetOrderListResponse, void>(`/api/v1/insurance/order/list?page=${page}&perPage=${perPage}`, REQUEST_METHOD.GET);
}

export async function insuranceGetOrder(id : string) {
    return await fetch<GetOrderResponse, void>(`/api/v1/insurance/order/show/${id}`, REQUEST_METHOD.GET);
}


