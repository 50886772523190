'use client'
import { ReactNode, useEffect, useRef } from "react";
import { ArrowLeftOutlined } from '@ant-design/icons'
import classNames from "classnames";
import { createPortal } from "react-dom";
import useOutsideClick from "../../../hooks/useOutsideClick";
interface ModalProps {
    children: ReactNode,
    open: boolean,
    onClose: () => void
}
export default function AppModal(props: ModalProps) {
    const { open, onClose, children } = props;
    useEffect(() => {
        if (open) document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [open])
    const ref: any = useRef(null)

    useEffect(() => {
        const closeOnEscapeKey = (e: any) => e.key === "Escape" ? onClose() : null;
        document?.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document?.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [onClose]);
    useOutsideClick(ref, () => {
        onClose()
    })

    const root = document.getElementById('loan-modal-portal');

    if (!root) {
        return null;
    }

    return createPortal(
        <div className={'app-modal'}>
            <div
                className={classNames({
                    'fixed z-50 top-1/2 left-1/2 -translate-x-1/2 -translate-y-2/4 h-screen w-screen app-back-drop-open': open,
                    'hidden h-0 w-0 overflow-hidden  app-back-drop-close': !open
                })}>
                <div
                    ref={ref}
                    className={classNames({
                        'app-modal-content-open': open,
                        'app-modal-content-close': !open
                    })}>
                    <div
                        className={'bg-white md:py-2 sm:py-10 xs:py-10 px-2 md:rounded-md md:w-96 md:h-fit md:max-h-screen md:overflow-y-auto  sm:h-screen sm:w-screen sm:rounded-0 xs:h-screen xs:rounded-0 xs:w-screen'}>
                        <div className={'text-left md:hidden sm:block xs:block vertical-scroll-view'}>
                            <ArrowLeftOutlined onClick={onClose} className={'text-primary-light'} />
                        </div>
                        <div className={'p-2'}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>,
        root
    );
}