

export default  [
    {
        name : 'kuwait' ,
        code : '965'
    },
    {
        name : 'qatar' ,
        code : '974'
    },
    {
        name : 'bahrain' ,
        code : '973'
    },
    {
        name : 'oman' ,
        code : '968'
    },
    {
        name : 'UAE' ,
        code : '971'
    },
    {
        name : 'Saudi Arabia' ,
        code : '966'
    },
]