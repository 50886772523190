import Card from "../../base/Cards/Card";
import useCategoryList from "../../../hooks/admin/useCategoryList";
import {useFormik} from "formik";
import * as Yup from "yup";
import React, {useState} from "react";
import Button from "../../base/Button/Button";
import AppModal from "../../base/modal/AppModal";
import {Category, SmeListItem} from "../../../models/sme.models";
import {SmeLevelsForAdmin} from "../../../utils/smeLevels";
import {Link} from "react-router-dom";
import {
    CheckCircleFilled,
    CloseCircleFilled,
    EditFilled, FileAddFilled,
    OrderedListOutlined,
    RightCircleFilled
} from "@ant-design/icons";

const CategorySchema = Yup.object({
    title: Yup.string().required('enter your title'),
    desc: Yup.string().required('enter you description')
});

enum ModalType {
    create,
    update
}

export default function CategorySetting() {
    const {data, isPending, updateLoading, update, create, createLoading} = useCategoryList(
        () => {
            showModal({
                open: false,
                type: ModalType.create,
                id: ''
            })
        },
        () => {
            showModal({
                open: false,
                type: ModalType.create,
                id: ''
            })
        }
    )
    const [modal, showModal] = useState<{ open: boolean, type: ModalType, id?: string }>({
        open: false,
        type: ModalType.create,
        id: ''
    })
    const formik = useFormik({
        initialValues: {
            title: '',
            desc: ''
        },
        validationSchema: CategorySchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
            if (modal.type === ModalType.create) {
                create(data)
            } else {
                update({
                    ...data,
                    categoryId: modal.id!
                })
            }
        }
    })
    return (
        <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12">
                <Card title={'Industries'}>
                    <div className={'flex justify-end gap-x-2 px-4'}>

                        <Button
                            onClick={() => showModal({
                                type: ModalType.create,
                                open: true
                            })}
                            className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                        >
                            <FileAddFilled className={'mr-2'}/>
                            create
                        </Button>

                    </div>
                    <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                        <tr>
                            <th
                                className={
                                    "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                }
                            >
                                Title
                            </th>
                            <th
                                className={
                                    "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                }
                            >
                                Description
                            </th>
                            <th
                                className={
                                    "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                                }
                            >
                                Action
                            </th>
                        </tr>
                        </thead>
                        {!isPending && data && data.length > 0 && <tbody>
                        {data.map((item: Category) => <tr key={item._id}>
                            <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">

                                {item.title}

                            </td>
                            <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center ">
                                <p>
                                    {item.desc}
                                </p>
                            </td>
                            <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                                <EditFilled className={'cursor-pointer'} onClick={() => {
                                    formik.setValues({
                                        title : item.title ,
                                        desc : item.desc
                                    })
                                    showModal({
                                        id: item._id,
                                        open: true,
                                        type: ModalType.update
                                    })
                                }}/>
                            </td>
                        </tr>)}

                        </tbody>}
                    </table>
                </Card>
            </div>
            <AppModal
                open={modal.open}
                onClose={() => showModal({
                    open: false,
                    type: ModalType.create
                })}>
                <div className="flex flex-col gap-2 px-2">
                    <h1 className="font-bold">{modal.type === ModalType.create ? 'Insert New Industry' : 'Update Industry'}</h1>
                    <h4>please enter title and description </h4>

                    <form onSubmit={formik.handleSubmit}>
                        <div className="relative w-full my-3">
                            <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                htmlFor="title"
                            >
                                Title
                            </label>
                            <input
                                id={'title'}
                                name={'title'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.title}
                                type="text"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Title"
                            />
                            {formik.errors.title ?
                                <p className={'text-red text-xs h-4 pt-1'}>{formik.errors.title}</p> :
                                <p className={'h-4 pt-1'}>{" "}</p>}
                        </div>
                        <div className="relative w-full my-3">
                            <label
                                className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                htmlFor="desc"
                            >
                                Description
                            </label>
                            <input
                                id={'desc'}
                                name={'desc'}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.desc}
                                type="text"
                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                placeholder="Description"
                            />
                            {formik.errors.desc ?
                                <p className={'text-red text-xs h-4 pt-1'}>{formik.errors.desc}</p> :
                                <p className={'h-4 pt-1'}>{" "}</p>}
                        </div>
                        <div className="w-full flex justify-center gap-3">

                            <Button
                                type={'button'}
                                className="text-white font-bold px-6 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-red-dark uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                onClick={() => showModal({open: false, type: ModalType.create})}
                            >
                                close
                            </Button>

                            <Button
                                loading={createLoading || updateLoading}
                                className="text-white flex items-center  font-bold px-3 py-2 rounded outline-none focus:outline-none mr-1 mb-1 bg-green-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                                type={'submit'}
                            >
                                submit
                            </Button>
                        </div>
                    </form>
                </div>
            </AppModal>
        </div>
    )
}