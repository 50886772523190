import {useMutation} from "@tanstack/react-query";
import {bankPayOrder} from "../../http/bank/bank-request";

export default  function  usePayOrderBank(onSuccess : () => void){

    const { isPending : payLoading ,  mutate : payOrderByBank} = useMutation({
        mutationFn : bankPayOrder ,
        onSuccess : () => {
            onSuccess()
        }
    })
    return {payLoading , payOrderByBank}
}