export enum QueryKeys {
    SME_LIST = 'smeList',
    SME = 'sme',
    SME_PROFILE = 'smeProfile',
    SME_LOGS = 'sme-logs',
    SME_SUPPLIER_LIST = 'sme-supplier-list',
    SME_SUPPLIER = 'sme-supplier',

    SME_ORDER_LIST = 'sme-order-list',
    SME_ORDER = 'sme-order',
    INIT_PAY = 'sme-INIT_PAY',
    PAY_INSTALLMENT = 'sme-PAY_INSTALLMENT',


    BANK_SME_LIST = 'bank_smeList',
    BANK_SME = 'bank_sme',
    BANK_ORDER_LIST = 'bank-order-list',
    BANK_ORDER = 'bank-order',
    BANK_INSTALLMENTS_LIST = 'bank-installment-list',

    INSURANCE_SME_LIST = 'insurance_smeList',
    INSURANCE_SME = 'insurance_sme',
    INSURANCE_ORDER_LIST = 'insurance-order-list',
    INSURANCE_ORDER = 'insurance-order',

    INSURANCE_LIST = 'insurance_list',
    SME_VERIFY_EMAIL = 'sme_verify_email',
    SME_RESEND_VERIFY_EMAIL = 'sme_resend_verify_email',
    SME_GET_CATEGORIES = 'sme_get_categories',
    SME_GET_INFO = 'sme_get_info',
    SME_GET_DOCS = 'sme_get_docs',
    SME_GET_LOGS = 'sme_get_logs',
    SME_GET_PROFILE = 'sme_get_profile',
    SME_ADD_PHONE = 'sme_add_phone',
    SME_VERIFY_PHONE = 'sme_verify_phone',
    
    PROVIDER_LIST = 'providerList',
    PROVIDER = 'provider',
    PROVIDER_VERIFY_EMAIL = 'provider_verify_email',
    PROVIDER_RESEND_VERIFY_EMAIL = 'provider_resend_verify_email',
    PROVIDER_GET_CATEGORIES = 'provider_get_categories',
    PROVIDER_GET_INFO = 'provider_get_info',
    PROVIDER_GET_DOCS = 'provider_get_docs',
    PROVIDER_GET_PROFILE = 'provider_get_profile',
    PROVIDER_ADD_PHONE = 'provider_add_phone',
    PROVIDER_VERIFY_PHONE = 'provider_verify_phone',
    PROVIDER_ORDER_LIST = 'supplier-order-list',
    PROVIDER_ORDER = 'supplier-order',
    DELIVERED = 'order-delivery',



    SUPPLIER_LIST = 'supplierList',
    SUPPLIER = 'supplier',
    SUPPLIER_LOGS = 'supplier-logs',
    ADMIN_CATEGORIES_LIST = 'admin-category-list',
    ADMIN_SHARE_RATE = 'admin-share-rate',
    ADMIN_ORDER_LIST = 'admin-order-list',
    ADMIN_ORDER = 'admin-order',
    INSTALLMENTS_LIST = 'installment-list',


    VERIFY_PAYMENT = 'verify-payment'
}

export enum StaleTime {
    MARKETS = 10 * 60 * 1000
}
