import {rejectSmeByInsurance, verifySmeByInsurance} from "../../http/insurance/insurance-request";
import {useMutation} from "@tanstack/react-query";


export default function useSmeMutateInsurance(
    onSuccessVerify: () => void,
    onSuccessReject: () => void,
) {
    const {isPending: verifyLoading, mutate: verify} = useMutation({
        mutationFn: verifySmeByInsurance,
        onSuccess() {
            onSuccessVerify()
        }
    })
    const {isPending: rejectLoading, mutate: reject} = useMutation({
        mutationFn: rejectSmeByInsurance,
        onSuccess() {
            onSuccessReject()
        }
    })

    return {
        verifyLoading, verify,
        rejectLoading, reject
    }
}