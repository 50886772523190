import {useMutation, useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {acceptOrder} from "../../http/provider/provider-requests";


export default function useAcceptOrder (onSuccess : () => void) {
    const {isPending , mutate} = useMutation({
        mutationFn : acceptOrder ,
        onSuccess : () => {
            onSuccess()
        }
    })

    return {isPending , mutate}

}