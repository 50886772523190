import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AboutPage from "views/about-us";
import ContactPage from "views/contact-us";
import FaqPage from "views/faq";
import TermsPage from "views/terms";
import Auth from './layouts/Auth';
import AdminLayout from './layouts/roles/AdminLayout';
import BankLayout from "./layouts/roles/BankLayout";
import InsuranceLayout from "./layouts/roles/InsuranceLayout";
import Smelayout from "layouts/roles/SmeLayout";
import Supplierlayout from "layouts/roles/SupplierLayout";

import HomePage from "./views/HomePage";
import Landing from './views/Landing';
import Profile from './views/Profile';
import DeliveredOrder from "./views/Links/DeliveredOrder";
import PayOrder from "./views/Links/PayOrder";
import PayInstallment from "./views/Links/PayInstallment";



const queryClient = new QueryClient();

function App() {
    return (
        <QueryClientProvider  client={queryClient}>
            <BrowserRouter>
                <Routes>
                    {/* add routes with layouts */}
                    <Route path="/sme/*" element={<Smelayout />} />
                    <Route path="/provider/*" element={<Supplierlayout />} />
                    <Route path="/admin/*" element={<AdminLayout />} />
                    <Route path="/bank/*" element={<BankLayout />} />
                    <Route path="/insurance/*" element={<InsuranceLayout />} />
                    <Route path="/auth/*" element={<Auth />} />
                    {/* add routes without layouts */}
                    <Route path="/landing" element={<HomePage />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/faq" element={<FaqPage />} />
                    <Route path="/contact-us" element={<ContactPage />} />
                    <Route path="/about-us" element={<AboutPage />} />
                    <Route path="/terms" element={<TermsPage />} />
                    <Route path="/" element={<Landing />} />
                    <Route path={'/delivered-order'} element={<DeliveredOrder/>}/>
                    <Route path={'/pay-order'} element={<PayOrder/>}/>
                    <Route path={'/pay-instalment'} element={<PayInstallment/>}/>
                    {/* add redirect for first page */}
                    {/* <Redirect from="*" to="/" /> */}
                </Routes>
            </BrowserRouter>
            <ToastContainer />
        </QueryClientProvider>
    );
}

export default App;
