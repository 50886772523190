import {useParams} from "react-router-dom";
import Card from "../../components/base/Cards/Card";
import OrderDetail from "../../components/order/OrderDetail";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import useOrderInsurance from "../../hooks/insurance/useOrderInsurance";
import OrderPaymentStatus from "../../components/order/OrderPaymentStatus";
import OrderInstallments from "../../components/order/OrderInstallments";

export default function OrderDetailInsurance(){
    const {id} = useParams()
    const {data , isPending} = useOrderInsurance(id!)
    return <div className="flex flex-wrap mt-4">
        <div className="w-full">
            <Card title={'Order Detail'}>
                {isPending ? <Loading3QuartersOutlined/> :
                    <OrderDetail order={data!}/>}
            </Card>
        </div>
        <div className="w-full ">
            <Card title={'Payment Status'}>
                {isPending ? <Loading3QuartersOutlined/> :
                    <OrderPaymentStatus latePay={data?.latePay!}/>}
            </Card>
        </div>
        <div className="w-full">
            <Card title={'Installments'}>
                {isPending ? <Loading3QuartersOutlined/> :
                    data?.installments ?
                        <OrderInstallments installments={data?.installments}/> : null}
            </Card>
        </div>
    </div>
}