import NoData from "../../base/noData";
import {SmeDesc, SmeInfo} from "../../../models/sme.models";
import map from '../../../assets/img/info/map.png'
import phone from '../../../assets/img/info/calling.png'
import email from '../../../assets/img/info/email.png'

interface Props {
    info?: SmeInfo,
    desc?: SmeDesc
}

export default function SmeInfoComponent({info, desc}: Props) {
    return (
        <>
            {desc && <div >
                <h1 className={'text-lg text-center font-bold'}>{desc.title}</h1>
                <p className={'text-md text-center text-neutral-500 py-2'}>{desc.text}</p>
                <hr className={'my-4'}/>
            </div>}

            <div className={'flex flex-wrap flex-row'}>

                <hr/>
                {info ? <>
                        {info.email2 && <div
                            className={'lg:w-1/4 md:w-1/2  w-full  p-2'}>
                            <div className={'rounded-md p-6 border border-slate-300 '}
                                 style={{borderTop: `4px solid green`}}>
                                <div className={'text-center'}>
                                    <img
                                        className={'mx-auto w-14'}
                                        loading={'lazy'}
                                        alt={"address"} src={email}/>
                                </div>
                                <br/>
                                <div className={'text-center text-sm text-primary-light'}>
                                    email2
                                </div>
                                <p className={'text-center ltr mb-2 text-primary-dark '}>
                                    {info.email2}
                                </p>
                            </div>
                        </div>}
                        {info.phone && <div
                            className={'lg:w-1/4 md:w-1/2  w-full  p-2'}>
                            <div className={'rounded-md p-6 border border-slate-300 '}
                                 style={{borderTop: `4px solid green`}}>
                                <div className={'text-center'}>
                                    <img
                                        className={'mx-auto w-14'}
                                        loading={'lazy'}
                                        alt={"address"} src={phone}/>
                                </div>
                                <br/>
                                <div className={'text-center text-sm text-primary-light'}>
                                    phone
                                </div>
                                <p className={'text-center ltr mb-2 text-primary-dark '}>
                                    {info.phone.country_code + " " + info.phone.number}
                                </p>
                            </div>
                        </div>}
                        {info.phone2 && <div
                            className={'lg:w-1/4 md:w-1/2    w-full  p-2'}>
                            <div className={'rounded-md p-6 border border-slate-300 '}
                                 style={{borderTop: `4px solid green`}}>
                                <div className={'text-center'}>
                                    <img
                                        className={'mx-auto w-14'}
                                        loading={'lazy'}
                                        alt={"address"} src={phone}/>
                                </div>
                                <br/>
                                <div className={'text-center text-sm text-primary-light'}>
                                    phone2
                                </div>
                                <p className={'text-center ltr mb-2 text-primary-dark '}>
                                    {info.phone2?.country_code + ' ' + info.phone2?.number}
                                </p>
                            </div>
                        </div>}
                        {info.address && <div
                            className={'lg:w-1/4 md:w-1/2  w-full  p-2'}>
                            <div className={'rounded-md p-6 border border-slate-300 '}
                                 style={{borderTop: `4px solid green`}}>
                                <div className={'text-center'}>
                                    <img
                                        className={'mx-auto w-14'}
                                        loading={'lazy'}
                                        alt={"address"} src={map}/>
                                </div>
                                <br/>
                                <div className={'text-center text-sm text-primary-light'}>
                                    address
                                </div>
                                <p className={'text-center ltr mb-2 text-primary-dark '}>
                                    {info.address}
                                </p>
                            </div>
                        </div>}
                        {info.address2 && <div
                            className={'lg:w-1/4 md:w-1/2  w-full  p-2'}>
                            <div className={'rounded-md p-6 border border-slate-300 '}
                                 style={{borderTop: `4px solid green`}}>
                                <div className={'text-center'}>
                                    <img
                                        className={'mx-auto w-14'}
                                        loading={'lazy'}
                                        alt={"address"} src={map}/>
                                </div>
                                <br/>
                                <div className={'text-center text-sm text-primary-light'}>
                                    address2
                                </div>
                                <p className={'text-center ltr mb-2 text-primary-dark '}>
                                    {info.address2}
                                </p>
                            </div>
                        </div>}

                    </>
                    : <NoData description={'no documents'}/>}
            </div>
        </>
    )
}