
export enum PROVIDER_LEVEL {
    // None,
    Registered,
    EmailVerified,
    PhoneVerified,
    InfoAdded,
    DocsUploaded,
    AdminVerified,
}

export enum PROVIDER_REJECT_LEVEL {
    ConditionalRejected = -2,
    FullRejected = -1,
}

export const PROVIDER_REJECT_REASON = {
    [PROVIDER_REJECT_LEVEL.ConditionalRejected]: "Your documents are not applied. Your level is downgraded !", 
    [PROVIDER_REJECT_LEVEL.FullRejected]: "Unfourtunately you are banned !!!" 
}

export const ProviderLevels : any = {
    0 : {
        title : 'Registered'
    } ,
    1 : {
        title : 'Email Verified'
    } ,
    2 : {
        title : 'Phone Verified'
    } ,
    3 : {
        title : 'Info Added'
    } ,
    4 : {
        title : 'Docs Uploaded'
    } ,
    5 : {
        title : 'Admin Verified'
    } ,
    '-1' : {
        title : 'Full Rejected'
    } ,
    '-2' : {
        title : 'Conditional Rejected'
    }
}


export default ProviderLevels