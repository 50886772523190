import React, {useEffect, useState} from "react";
import StorageKeys from "../../utils/StorageKeys";
import {Link, Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {useMutation} from "@tanstack/react-query";
import {login} from "../../http/auth/auth-requests";
import {toast} from "react-toastify";
import fatra from "../../assets/img/logo/logo.png";
import Button from "../../components/base/Button/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Role} from "../../models/auth.model";
import useInitPay from "../../hooks/sme/useInitPay";

const LoginSchema = Yup.object({
    email: Yup.string().required('enter your email').email('email is incorrect'),
    password: Yup.string().required('enter you password').min(8, 'password is too short')
});
export default function PayOrder() {
    const [searchParams] = useSearchParams()
    const orderId = searchParams.get('orderId');
    console.log("orderid" , orderId)
    const navigate = useNavigate();
    const [isLogin, setisLogin] = useState(() => {
        if (localStorage.getItem(StorageKeys.TOKEN) && localStorage.getItem(StorageKeys.ROLE) === Role.SME) return true
        return false
    })
    const {paymentLoading, payment} = useInitPay((id) => {
        navigate(`/sme/payment-result/${id}`)
    })
    const {isPending, mutate} = useMutation({
        mutationFn: login,
        onSuccess: (data) => {
            if (data.content['user-role'] === Role.SME) {
                toast.success('login successfully')
                localStorage.setItem(StorageKeys.TOKEN, data.content.token);
                localStorage.setItem(StorageKeys.ROLE, data.content['user-role'])
                localStorage.setItem(StorageKeys.NAME, data.content.user.name)
                localStorage.setItem(StorageKeys.EMAIL, data.content.user.auth_id.email)
                setisLogin(true)

            } else {
                toast.error('you must login as SME')
            }
        }
    })
    useEffect(()=> {
        if (orderId && isLogin) payment({orderId: orderId!})
    } , [isLogin])
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
            mutate(data)
        }
    })

    if (!orderId) {
        toast.error('your data is incorrect')
        return <Navigate to={'/'} replace={true}/>
    }


    // useEffect(() => {
    //     if (orderId && isLogin) payment({
    //         orderId
    //     })
    // } , [isLogin])

    if (isLogin) return <>
        <div className="w-full py-10 text-center h-full flex flex-col gap-5 justify-center items-center">
            {paymentLoading ? <p className={'text-center text-lg font-bold my-16'}>
                loading ...
            </p> : null
            }
        </div>
    </>
    return <>
        <main>
            <section className="relative w-full h-full min-h-screen">
                <div
                    className="absolute top-0 w-full h-full  bg-no-repeat bg-cover auth-bg"
                ></div>
                <div className="container mx-auto md:px-4 px-3 py-10">
                    <div className="flex content-center items-center justify-center ">
                        <div className="w-full lg:w-4/12  md:px-4 sm:px-1 ">
                            <div
                                className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                                <div className="rounded-t px-6 py-4 bg-blueGray-600">
                                    <div className="text-center mb-1">
                                        <img src={fatra} alt={"fatra"}/>
                                    </div>
                                    <div className="text-center mt-2">
                                        <h6 className="text-white text-sm font-bold">
                                            Sign In
                                        </h6>
                                    </div>
                                </div>
                                <p className={'mx-6 my-2 text-red border rounded p-2'}>
                                    login as sme to payment
                                </p>
                                <div className="flex-auto px-6 py-4">
                                    <form onSubmit={formik.handleSubmit} noValidate={true}>
                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                htmlFor="grid-password"
                                            >
                                                Email
                                            </label>
                                            <input
                                                id={'email'}
                                                name={'email'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                                type="email"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Email"
                                            />
                                            {formik.errors.email ?
                                                <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.email}</p> :
                                                <p className={'h-5 pt-2'}>{" "}</p>}
                                        </div>

                                        <div className="relative w-full mb-3">
                                            <label
                                                className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                                htmlFor="grid-password"
                                            >
                                                Password
                                            </label>
                                            <input
                                                id={'password'}
                                                name={'password'}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                type="password"
                                                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                                placeholder="Password"
                                            />
                                            {formik.errors.password ?
                                                <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.password}</p> :
                                                <p className={'h-5 pt-2'}>{" "}</p>}
                                        </div>
                                        <div className="text-center mt-6">
                                            <Button
                                                loading={isPending}
                                                disabled={isPending}
                                                className="bg-green-400 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                                type="submit"
                                            >
                                                Sign In
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
}