import BankVerifiedLogo from 'assets/img/other/bank_verified.png';

const WaitingForInsurances = () => {
    return (
        <div className="w-1/2 py-10 text-center h-full flex flex-col gap-5 justify-center items-center">

            <img width={250} alt="" src={BankVerifiedLogo} />

            <p className="text-2xl font-bold">
                You are verified by the Bank.
            </p>
            <p className="text-xl font-bold text-gray-dark">Waiting for the Insurances</p>

        </div>
    );
}

export default WaitingForInsurances;