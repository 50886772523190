import {Log, SmeListItem} from "../../../models/sme.models";
import Tag from "../../base/tag/Tag";
import {CheckCircleFilled, CloseCircleFilled, RightCircleFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";
import NoData from "../../base/noData";

interface Props {
    logs?: Log[],
    loading: boolean
}

export default function SmeTimeline({logs, loading}: Props) {
    return (
        <div>
            {!loading && logs && logs.length > 0 ?
                <table className="items-center w-full bg-transparent border-collapse">
                    <thead>
                    <tr>
                        <th
                            className={
                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            }
                        >
                            Time
                        </th>
                        <th
                            className={
                                "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                            }
                        >
                            Description
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {logs.map((item: Log) => <tr key={item._id}>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                            {new Intl.DateTimeFormat('en-GB', {
                                dateStyle: 'full',
                                timeStyle: 'long',
                            }).format(new Date(item.createdAt))}

                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {item.operator.role + " : " + item.desc}
                        </td>
                    </tr>)}
                    </tbody>
                </table> :
                <NoData description={'no data'}/>
            }
        </div>
    )
}