import {Link} from "react-router-dom";
import {useFormik} from "formik";
import * as Yup from 'yup'
import useLogin from "../../hooks/auth/useLogin";
import Button from "../../components/base/Button/Button";
import fatra from '../../assets/img/logo/logo.png'
const LoginSchema = Yup.object({
    email: Yup.string().required('enter your email').email('email is incorrect'),
    password: Yup.string().required('enter you password').min(8, 'password is too short')
});

export default function Login() {
    const {loading, login} = useLogin()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: LoginSchema,
        validateOnBlur: true,
        validateOnChange: true,
        validateOnMount: false,
        onSubmit: (data: any) => {
            login(data)
        }
    })

    return (
        <>
            <div className="container mx-auto md:px-4 px-3 py-10">
                <div className="flex content-center items-center justify-center ">
                    <div className="w-full lg:w-4/12  md:px-4 sm:px-1 ">
                        <div
                            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                            <div className="rounded-t px-6 py-4 bg-blueGray-600">
                                <div className="text-center mb-1">
                                    <img src={fatra} alt={"fatra"}/>
                                </div>
                                <div className="text-center mt-2">
                                    <h6 className="text-white text-sm font-bold">
                                        Sign In
                                    </h6>
                                </div>
                            </div>
                            <div className="flex-auto px-6 py-4" >
                                <form onSubmit={formik.handleSubmit} noValidate={true}>
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                            htmlFor="grid-password"
                                        >
                                            Email
                                        </label>
                                        <input
                                            id={'email'}
                                            name={'email'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                            type="email"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Email"
                                        />
                                        {formik.errors.email ?
                                            <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.email}</p> :
                                            <p className={'h-5 pt-2'}>{" "}</p>}
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-blueGray-600 text-xs font-bold mb-1"
                                            htmlFor="grid-password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            id={'password'}
                                            name={'password'}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                            type="password"
                                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Password"
                                        />
                                        {formik.errors.password ?
                                            <p className={'text-red text-xs h-5 pt-2'}>{formik.errors.password}</p> :
                                            <p className={'h-5 pt-2'}>{" "}</p>}
                                    </div>
                                    <div className="text-center mt-6">
                                        <Button
                                            loading={loading}
                                            disabled={loading}
                                            className="bg-green-400 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            type="submit"
                                        >
                                            Sign In
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="flex flex-wrap my-6 relative">
                            <div className="w-1/2">
                                <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                    className="text-black"
                                >
                                    <small></small>
                                </a>
                            </div>
                            <div className="w-1/2 text-right">
                                <Link to="/auth/register/sme" className="text-black">
                                    <small>Create new account</small>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
