import React from "react";
interface TabProps {
    tabs : { key : string , title : string }[] ,
    onTab : (key : string) => void ,
    active : string
}
function Tabs(props: TabProps) {
    const {active , onTab , tabs} = props
    return (
        <div className={'panel-tabs border-b uppercase border-slate-300 border-solid mb-4'}>
            <ul className={'rtl'}>
                {tabs.map((t: any) =>
                    <li
                        onClick={() => {
                            onTab(t.key)
                        }}
                        key={t.key} className={active === t.key ? "active" : ''}>
                        {t.title}
                    </li>
                )}
            </ul>

        </div>
    )
}

export default React.memo(Tabs);
