import fetch, {REQUEST_METHOD} from "../axiosHelper";
import {LoginBody, LoginResponse, RegisterBody, RegisterResponse} from "../../models/auth.model";


export async function login(data: LoginBody) {
    return await fetch<LoginResponse, LoginBody>(`/api/v1/auth/login`, REQUEST_METHOD.POST, data);
}


export async function register(data : RegisterBody) {
    return await fetch<RegisterResponse , RegisterBody>(`/api/v1/auth/register`, REQUEST_METHOD.POST , data);
}