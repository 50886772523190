import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {bankGetOrderList} from "../../http/bank/bank-request";


export default function useOrderListBank(page: number, status: number) {
    const {data, isPending} = useQuery({
        queryKey: [QueryKeys.BANK_ORDER_LIST, page, status],
        queryFn: () => bankGetOrderList(page, 10, status)
    })
    return {isPending, data: data?.content.orders, total: data?.content.count}
}