import StorageKeys from "./StorageKeys";

export function saveLevel(level: number) {
    localStorage.setItem(StorageKeys.LEVEL, level.toString());
}
export function getLevel() {
    const level = localStorage.getItem(StorageKeys.LEVEL);
    if (!level) return 0;

    return parseInt(level);
}


export function saveToken(token: string) {
    localStorage.setItem(StorageKeys.TOKEN, token);
}
export function getToken() {
    const token = localStorage.getItem(StorageKeys.TOKEN);
    return token || "";
}


export function saveRole(role: string) {
    localStorage.setItem(StorageKeys.ROLE, role);
}
export function getRole() {
    return localStorage.getItem(StorageKeys.ROLE);
}
