import Pagination from "../../base/Pagination/Pagination";
import {useState} from "react";
import {SmeListItem} from "../../../models/sme.models";
import { RightCircleFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {Label, Select} from "flowbite-react";
import {SmeLevelsForAdmin} from "../../../utils/smeLevels";
import useSmeListAdmin from "../../../hooks/admin/useSmeListAdmin";

export default function SmeTableAdmin() {
    const [page, setPageNumber] = useState(1);
    const [level , setlevel] = useState<number>(-10)
    const {data, total, isPending} = useSmeListAdmin(page , level)
    return (
        <>

            <div className={'px-4'}>
                <div className={'flex flex-row flex-wrap mb-4'}>
                    <div className={'md:w-1/4 w-full '}>
                        <div className="mb-2 block">
                            <Label htmlFor="levels" value="Verification Level"/>
                        </div>
                        <Select value={level} id="levels" onChange={(event) => setlevel(Number(event.target.value))}>
                            <option key={'all'} value={-10}>
                                All
                            </option>
                            {Object.keys(SmeLevelsForAdmin).map((item : string) => <option key={item} value={Number(item)}>
                                {SmeLevelsForAdmin[Number(item)].title}
                            </option>)}
                        </Select>
                    </div>
                </div>

            </div>
            <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                <tr>
                    <th
                        className={
                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Email
                    </th>
                    <th
                        className={
                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Phone / Name
                    </th>
                    <th
                        className={
                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Verification Level
                    </th>
                    <th
                        className={
                            "px-3 align-middle border border-solid  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        <p className={'text-neutral-500'}>
                            Request Credit
                        </p>
                        <p>
                            Credit
                        </p>
                    </th>
                    <th
                        className={
                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Actions
                    </th>
                </tr>
                </thead>
                {!isPending && data && data.length > 0 &&  <tbody>
                {data.map((item: SmeListItem) => <tr key={item._id}>
                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        <a
                            className={
                                "text-blueGray-600"
                            }
                            href={`mailto:${item.auth_id.email}`}
                        >
                            {item.auth_id.email}
                        </a>

                    </td>
                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center ">
                        <div>{item.info && item.info.phone && <a
                            className={
                                "text-blueGray-600"
                            }
                        >
                            {item.info.phone.country_code + ' ' + item.info.phone.number }
                        </a>}</div>
                        <p>
                            {item.info && item.info.firstname && item.info.lastname ? `${item.info.firstname} ${item.info.lastname}` : "---"}
                        </p>
                    </td>
                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                        {SmeLevelsForAdmin[String(item.level)]?.title}
                    </td>
                    {/*<td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">*/}
                    {/*    <Tag color={item.bankVerified ? 'green' : 'red'}*/}
                    {/*         icon={item.bankVerified ? <CheckCircleFilled/> : <CloseCircleFilled/>}>*/}
                    {/*        {item.bankVerified ? 'verified' : 'unverified'}*/}
                    {/*    </Tag>*/}
                    {/*</td>*/}
                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0  whitespace-nowrap text-center">
                        <p className={'my-1 text-xs text-neutral-500'}>
                            {item.credit.request} $
                        </p>
                        <p className={'font-bold text-xs'}>
                            {item.credit.amount} $
                        </p>
                    </td>
                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        <Link to={`/admin/sme/${item._id}/info`}><RightCircleFilled/></Link>
                    </td>
                </tr>)}

                </tbody>}
            </table>

            {!isPending  && <Pagination
                current={page}
                pageSize={10}
                total={total!}
                onChange={(page: number) => setPageNumber(page)}

            />
            }
        </>
    );
}

