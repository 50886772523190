import Button from 'components/base/Button/Button';
import Footer from 'components/base/Footers/Footer';
import IndexNavbar from 'components/base/Navbars/IndexNavbar';

const Text1 = "Fatra® is an Open Banking Solution that provides Buy Now Pay Later services “BNPL” to SMEs in the GCC countries. Fatra® solution aims to provide seamless transactions between SME’s and suppliers.\
In partnership with esteemed financial organizations, Fatra® provides credit facilities to SMEs in GCC region. Utilizing Fatra® digital platform, SMEs are afforded access to financing through a pioneering approach.";
const Text2 = "In 2023, a team of entrepreneurs in Kuwait established Fatra®. Drawing upon their collective expertise in E-commerce, Marketing, and Construction, the founders determined that the market conditions were favourable for the introduction of a distinctive financial product.";


const AvatarText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit\
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit';

const Members = [
    { name: "John Doe", role: "Project Manager" },
    { name: "Jane Smith", role: "Software Engineer" },
    { name: "Mark Johnson", role: "Data Analyst" },
    { name: "Samantha Williams", role: "UX Designer" },
]

const AboutPage = () => {
    return (
        <>
            <IndexNavbar fixed />

            <div className='overflow-x-hidden'>
                <div
                    className='bg-blueGray-700 pb-32 w-screen overflow-x-hidden overflow-y-scroll flex flex-col items-center'>
                    <div
                        className="bg-cover py-3 w-screen h-screen items-center flex flex-col justify-center"
                        style={{
                        
                            backgroundImage: `url('/assets/images/landing/about2.jpg')`,
                            backgroundRepeat: "no-repeat"
                        }}>

                        <div className='flex flex-col w-full md:w-2/3 justify-evenly h-full px-6 gap-3'>
                            <p className='text-white   italic text-4xl font-extrabold drop-shadow'>
                                About Us
                            </p>
                            <p className='text-white  text-xl text-justify w-full drop-shadow'>
                                {Text1}
                            </p>
                            <p className='text-white text-xl   text-justify w-full drop-shadow'>
                                {Text2}
                            </p>

                            <Button
                                loading={false}
                                className="bg-blue-500 text-white active:bg-blueGray-600 text-sm font-bold
                                uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none
                                focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                type="submit"
                            >
                                Get Start
                            </Button>

                        </div>
                    </div>

                    <div className='md:w-9/12 w-full flex flex-col md:flex-row justify-center items-center py-6 gap-3'>

                        {
                            ["face1.jpg", "face2.jpg", "face3.jpg"].map((item, index) =>
                                <div
                                    key={index}
                                    className='rounded bg-blueGray-600 drop-shadow md:w-9/12 w-11/12 h-fit p-3 flex flex-col gap-3'>
                                    <div className='flex md:gap-3 gap-10 md:justify-evenly justify-start items-center'>
                                        <img src={`/assets/images/landing/${item}`} alt=''
                                            className='rounded-full w-20 h-20' />
                                        <p className='text-2xl font-extrabold text-white'>Lorem 1</p>
                                    </div>
                                    <span className=''>
                                        <p className='text-white overflow-hidden mt-3'>{AvatarText}</p>
                                    </span>
                                </div>)
                        }

                    </div>

                    <ul className='w-11/12 md:w-9/12 h-fit px-10 py-5 flex flex-col rounded bg-blueGray-600 mt-6 list-decimal'>
                        {
                            Members.map(item =>
                                <li key={item.name}
                                    className='text-white w-full p-5 leading-8 text-xl border-b-2 border-gray-500'>
                                    <span className='text-white font-extrabold'>{item.name}:&nbsp;</span>
                                    <span className='font-thin text-lg'>{item.role}</span>
                                </li>
                            )
                        }
                    </ul>

                    {/* <div className='w-screen flex md:flex-row flex-col justify-center items-center leading-6 text-justify gap-5'>
                    <img alt='' src='/assets/images/landing/about3.png' className='md:w-1/4 w-full' />
                    <p className='text-white font-bold md:w-1/4 w-11/12'>
                        {Text}
                    </p>
                </div> */}
                </div>
                <Footer />
            </div>
        </>
    );
}

export default AboutPage;