import Card from "../../components/base/Cards/Card";
import useSupplierListSme from "../../hooks/sme/useSupplierListSme";
import {SupplierListItem} from "../../models/supplier.models";
import {Category} from "../../models/sme.models";
import Tag from "../../components/base/tag/Tag";
import {Link} from "react-router-dom";
import {useState} from "react";
import Pagination from "../../components/base/Pagination/Pagination";


export default function SmeSupplierList() {
    const [page, setPageNumber] = useState(1);
    const {isPending, data , total} = useSupplierListSme(page)
    return <Card title="Suppliers">
        <p className={'text-neutral-500 mx-4 mb-2'}> choose a supplier and create a request</p>
        <div className={'flex flex-row flex-wrap'}>
            {!isPending && data && data.map((supplier: SupplierListItem) => <div
                className={'xs:w-full sm:w-1/2 md:w-1/4 p-2'}>
                <Link to={`/sme/supplier/${supplier._id}`}>
                    <div className='text-blueGray-800 shadow-lg hover:scale-95 transition-all cursor-pointer'>
                        <p className="text-lg font-bold py-2 px-2 bg-blue-200 rounded-t-lg">
                            {supplier.desc?.title}
                        </p>
                        <p className="py-2 px-2 bg-blue-300 border-solid border-b border-blueGray-100 min-h-40 max-h-40 overflow-hidden">
                            {supplier.desc?.text}
                        </p>
                        <div className={'py-2 px-1 bg-blue-400 rounded-b-lg'}>
                            <div className={'inline-flex flex-wrap align-middle gap-x-2 '}>
                                {
                                    supplier.categories.map((cat: Category) => <Tag key={cat._id} color={'blue'}>
                                        {cat.title}
                                    </Tag>)
                                }
                            </div>
                        </div>
                    </div>
                </Link>
            </div>)}
        </div>
        {!isPending  && <Pagination
            current={page}
            pageSize={10}
            total={total!}
            onChange={(page: number) => setPageNumber(page)}
        />
        }
    </Card>
}