import useOrderSme from "../../hooks/sme/useOrderSme";
import {useParams} from "react-router-dom";
import Card from "../../components/base/Cards/Card";
import OrderDetail from "../../components/order/OrderDetail";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import OrderInstallments from "../../components/order/OrderInstallments";
import OrderPaymentStatus from "../../components/order/OrderPaymentStatus";
import {OrderStatusEnum} from "../../models/order.model";
import OrderInvoice from "../../components/supplier/OrderInvoice";


export default function SmeOrderDetail() {
    const {id} = useParams()
    const {data, isPending} = useOrderSme(id!)
    return <div className="flex flex-col  flex-wrap mt-4">
        <div className="w-full ">
            <Card title={'Order Detail'}>
                {isPending ? <Loading3QuartersOutlined/> :
                    <OrderDetail order={data!}/>}
            </Card>
        </div>
        <div className="w-full ">
            <Card title={'Payment Status'}>
                {isPending ? <Loading3QuartersOutlined/> :
                    <OrderPaymentStatus latePay={data?.latePay!}/>}
            </Card>
        </div>
        <div className="w-full">
            <Card title={'Installments'}>
                {isPending ? <Loading3QuartersOutlined/> :
                    data?.installments && data.status !== OrderStatusEnum.created ?
                        <OrderInstallments installments={data?.installments.reverse()}/> : null}
            </Card>
        </div>
        {!isPending && data && data.status >= OrderStatusEnum.uploadedInvoice && <div className="w-full mb-12">
            <Card title={'Order Invoice Image'}>
                <OrderInvoice order={data!}/>
            </Card>
        </div>}
    </div>
}