import Pagination from "../../base/Pagination/Pagination";
import {useState} from "react";
import { RightCircleFilled} from "@ant-design/icons";
import {Link} from "react-router-dom";
import useSupplierListAdmin from "../../../hooks/admin/useSupplierListAdmin";
import {SupplierListItem} from "../../../models/supplier.models";
import {Label, Select} from "flowbite-react";
import providerLevels from "../../../utils/providerLevels";
import {SmeLevelsForAdmin, SmeLevelsForBank} from "../../../utils/smeLevels";

export default function SupplierTable() {
    const [page, setPageNumber] = useState(1);
    const [level , setlevel] = useState<number>(-10)
    const {data, total, isPending} = useSupplierListAdmin(page , level)
    return (
        <>
            <div className={'px-4'}>
                <div className={'flex flex-row flex-wrap mb-4'}>
                    <div className={'md:w-1/4 w-full '}>
                        <div className="mb-2 block">
                            <Label htmlFor="levels" value="Verification Level"/>
                        </div>
                        <Select value={level} id="levels" onChange={(event) => setlevel(Number(event.target.value))}>
                            <option key={'all'} value={-10}>
                                All
                            </option>
                            {Object.keys(providerLevels).map((item : string) => <option key={item} value={Number(item)}>
                                {providerLevels[Number(item)].title}
                            </option>)}
                        </Select>
                    </div>
                </div>

            </div>
            <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                <tr>
                    <th
                        className={
                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Email
                    </th>
                    <th
                        className={
                            "px-3 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Phone / Name
                    </th>
                    <th
                        className={
                            "px-6 align-middle border border-solid py-3 text-center text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Verification Level
                    </th>
                    <th
                        className={
                            "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Discount Rate
                    </th>
                    <th
                        className={
                            "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                    >
                        Actions
                    </th>
                </tr>
                </thead>
                {!isPending && data && <tbody>
                {data.map((item: SupplierListItem) => <tr>
                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                        <span
                            className={
                                "font-bold text-blueGray-600"
                            }
                        >
                            {item.auth_id.email}
                        </span>
                    </td>
                    <td className="border-t-0 px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 items-center ">
                        <div>{item.info && item.info.phone && <a
                            className={
                                "text-blueGray-600"
                            }
                        >
                            {item.info.phone.country_code + ' ' + item.info.phone.number }
                        </a>}</div>
                        <p>
                            {item.info && item.info.firstname && item.info.lastname ? `${item.info.firstname} ${item.info.lastname}` : "---"}
                        </p>
                    </td>

                    <td className="border-t-0 px-6 align-middle border-l-0 text-center border-r-0 text-xs whitespace-nowrap p-4">
                        {providerLevels[String(item.level)].title}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        <span className={'font-bold'}>
                            {item.tax_rate * 100} %
                        </span>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                        <Link to={`/admin/supplier/${item._id}/info`}><RightCircleFilled /></Link>
                    </td>
                </tr>)}

                </tbody>}
            </table>

            {!isPending  && <Pagination
                current={page}
                pageSize={10}
                total={total!}
                onChange={(page: number) => setPageNumber(page)}

            />
            }
        </>
    );
}

