import {useQuery} from "@tanstack/react-query";
import {QueryKeys} from "../../utils/ReactQueyConstants";
import {getSupplierList} from "../../http/admin/admin-request";


export default function useSupplierListAdmin (page : number , level : number) {

    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.SUPPLIER_LIST , page , level] ,
        queryFn : () => getSupplierList(page , 10 , level)
    })
    return {isPending , data : data?.content.providers , total : data?.content.count}
}