import {QueryKeys} from "../../utils/ReactQueyConstants";
import {useQuery} from "@tanstack/react-query";
import {adminGetOrder} from "../../http/admin/admin-request";


export default function useOrderAdmin (id : string ) {
    const {data , isPending } = useQuery({
        queryKey  : [QueryKeys.ADMIN_ORDER , id ] ,
        queryFn : () => adminGetOrder(id)
    })
    return {isPending , data : data?.content.order }
}