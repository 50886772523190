
import Banned from 'assets/img/other/banned.png';
import { FC } from "react";
import { PROVIDER_REJECT_LEVEL, PROVIDER_REJECT_REASON } from 'utils/providerLevels';

const FullyRejected: FC<{ level: PROVIDER_REJECT_LEVEL }> = ({ level }) => {
    return (
        <div className="w-1/2 py-10 text-center h-full flex flex-col gap-5 justify-center items-center">

            <img alt="" width={250} src={Banned} />

            <p className="text-2xl font-bold">{PROVIDER_REJECT_REASON[level]}</p>

        </div>
    );
}

export default FullyRejected;