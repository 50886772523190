import { useState } from "react";

function Expandable(props) {
    const {
        expand,
        onExpand,
        title,
        children,
        rootClass,
        headerClass,
        contentClass
    } = props;

    return (
        <div className={"z-30 w-full mt-5 max-h-fit m-2" + rootClass}>
            <div
                className={"text-white flex items-center justify-between px-4 py-3\
                cursor-pointer transition duration-300 ease-in-out border-none rounded-t-lg " + headerClass}
                onClick={onExpand}
            >
                <h3 className="text-lg font-medium">{title}</h3>
                <svg
                    className={`${expand ? "transform rotate-180" : ""
                        } w-10 h-10 text-white`}
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </div>
            <div
                className={contentClass + ` text-black transition-height duration-300 ease-in-out rounded-b-lg
                ${expand ? "max-h-60 p-3 opacity-100" : "max-h-0 p-0 opacity-0"} border-gray-200 border-2 overflow-y-scroll`}
            >
                {children}
            </div>
        </div>
    );
}

export default Expandable;