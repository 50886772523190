import Card from "../../components/base/Cards/Card";
import SmeTableInsurance from "../../components/insurance/sme/SmeTableInsurance";



export default function SmeListInsurance() {
    return (
        <>
            <div className="flex flex-wrap mt-4">
                <div className="w-full mb-12">
                    <Card  title={'List Of SME\'s'} >
                        <SmeTableInsurance/>
                    </Card>
                </div>
            </div>
        </>
    );

}
