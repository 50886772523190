import CategorySetting from "../../components/admin/setting/CategorySetting";
import BankShareSetting from "../../components/admin/setting/BankShareSetting";
import InsuranceList from "./InsuranceList";

export default function Settings() {
  return (
    <>
      <div className="flex flex-wrap flex-col gap-y-2">
        <div className="w-full ">
          <InsuranceList />
        </div>
        <div className="w-full ">
          <BankShareSetting />
        </div>
        <div className="w-full">
          <CategorySetting />
        </div>
      </div>
    </>
  );
}
