import {IProviderProfile} from "models/provider.profile.model";
import {
    CategoriesModel,
    DeleteDocBody, DeliverOrderBody,
    DocListResponseModel,
    DocsModel,
    GetProviderLogsResponse,
    ProviderAddInfoBody,
    ProviderAddInfoResponse,
    ProviderAddPhoneBody,
    ProviderAddPhoneResponse,
    ProviderConfirmDocResponse,
    ProviderInfoResponseModel,
    ProviderResendEmailResponse,
    ProviderValidatePhoneBody,
    ProviderValidatePhoneResponse,
    ProviderVerifyEmailBody,
    ProviderVerifyEmailResponse
} from "../../models/provider.models";

import fetch, {httpUploadInvoice, REQUEST_METHOD, upload, uploadProvider} from "../axiosHelper";
import {SmeConfirmDocResponse} from "../../models/sme.models";
import {CreateOrderBody, GetOrderListResponse, GetOrderResponse} from "../../models/order.model";
import {InitPaymentBody, VerifyPaymentBody, VerifyPaymentResponse} from "../../models/payment.model";


export async function verifyEmail(data: ProviderVerifyEmailBody) {
    return await fetch<ProviderVerifyEmailResponse, ProviderVerifyEmailBody>(
        `/api/v1/provider/email/validate`, REQUEST_METHOD.POST, data
    );
}

export async function resendEmail() {
    return await fetch<ProviderResendEmailResponse, void>(`/api/v1/provider/email/resend`, REQUEST_METHOD.GET);
}

export async function addPhone(data: ProviderAddPhoneBody) {
    return await fetch<ProviderAddPhoneResponse, ProviderAddPhoneBody>(
        `/api/v1/provider/phone/add`, REQUEST_METHOD.POST, data
    );
}

export async function validatePhone(data: ProviderValidatePhoneBody) {
    return await fetch<ProviderValidatePhoneResponse, ProviderValidatePhoneBody>(
        `/api/v1/provider/phone/validate`, REQUEST_METHOD.POST, data
    );
}

export async function getProfile() {
    return await fetch<IProviderProfile, void>(`/api/v1/provider/profile/get`, REQUEST_METHOD.GET);
}

export async function getCategories() {
    return await fetch<CategoriesModel, void>(`/api/v1/provider/categories/list`, REQUEST_METHOD.GET);
}

export async function addInfo(data: ProviderAddInfoBody) {
    return await fetch<ProviderAddInfoResponse, ProviderAddInfoBody>(
        `/api/v1/provider/info/add`, REQUEST_METHOD.POST, data
    );
}

export async function getInfo() {
    return await fetch<ProviderInfoResponseModel, void>(
        `/api/v1/provider/info/get`, REQUEST_METHOD.GET
    );
}

export async function getDocs() {
    return await fetch<DocListResponseModel, void>(`/api/v1/provider/doc/list`, REQUEST_METHOD.GET);
}

export async function uploadDocs(data: { file: File, desc: string }) {
    return await uploadProvider<DocsModel>(
        `/api/v1/provider/doc/upload`, data.file, data.desc
    );
}


export async function verifyDocs() {
    return await fetch<ProviderConfirmDocResponse, void>(
        `/api/v1/provider/doc/confirm`, REQUEST_METHOD.POST
    );
}


export async function deleteDocs(data: DeleteDocBody) {
    return await fetch<ProviderConfirmDocResponse, DeleteDocBody>(
        `/api/v1/provider/doc/upload`, REQUEST_METHOD.DELETE, data
    );
}

export async function getProviderLogs() {
    return await fetch<GetProviderLogsResponse, void>(`/api/v1/provider/log/list`, REQUEST_METHOD.GET);
}

export async function supplierGetOrderList(page: number, perPage: number, status: number) {
    let url = `/api/v1/provider/order/list?page=${page}&perPage=${perPage}`
    if (status !== -10) url += `&status=${status}`
    return await fetch<any, void>(url, REQUEST_METHOD.GET);
}

export async function supplierGetOrder(id: string) {
    return await fetch<GetOrderResponse, void>(`/api/v1/provider/order/show/${id}`, REQUEST_METHOD.GET);
}

export async function uploadInvoice(data : {file: File, orderId: string}) {
    return await httpUploadInvoice<any>(
        `/api/v1/provider/order/invoice/upload`, data.file , data.orderId
    );
}
export async function deliverOrder(data : DeliverOrderBody) {
    return await fetch<any, DeliverOrderBody>(`/api/v1/provider/order/delivered`, REQUEST_METHOD.POST , data);
}
export async function acceptOrder(data : Omit<DeliverOrderBody , 'verifyCode'>) {
    return await fetch<any, Omit<DeliverOrderBody , 'verifyCode'>>(`/api/v1/provider/order/accept`, REQUEST_METHOD.POST , data);
}
