import {QueryKeys} from "../../utils/ReactQueyConstants";
import {
    createCategory,
    getCategoryList,
    updateCategory
} from "../../http/admin/admin-request";
import {toast} from "react-toastify";
import {useMutation, useQuery} from "@tanstack/react-query";


export default  function  useCategoryList(onSuccessChange : () => void , onSuccessCreate : () => void){
    const {data , isPending , refetch } = useQuery({
        queryKey  : [QueryKeys.ADMIN_CATEGORIES_LIST] ,
        queryFn : getCategoryList
    })
    const { isPending : updateLoading ,  mutate : update} = useMutation({
        mutationFn : updateCategory ,
        onSuccess : () => {
            refetch();
            onSuccessChange()
            toast.success('done!')

        }
    })
    const { isPending : createLoading ,  mutate : create} = useMutation({
        mutationFn : createCategory ,
        onSuccess : () => {
            refetch();
            onSuccessCreate()
            toast.success('done!')

        }
    })
    return {data : data?.content.categories , isPending , updateLoading , update , createLoading , create , refetch}
}