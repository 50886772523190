import {ProviderModel} from 'models/provider.profile.model';
import {useMemo, useRef, useState} from "react";
import StorageKeys from "utils/StorageKeys";
import ProfileRow from "../../base/profileRow";


interface SmePanelProps {
    providerProfile: ProviderModel,
}

const SupplierProfile = ({providerProfile}: SmePanelProps) => {

    const ImgWidth = 220;
    const authToken = useRef<string>(localStorage.getItem(StorageKeys.TOKEN) || "");
    const imgRef = useRef<{ refList: HTMLImageElement[], idList: string[] }>({
        idList: [],
        refList: []
    });
    const imgContainer = useRef<HTMLDivElement>(null);
    const [selectedImage, setSelectedImage] = useState<{ name: string, link: string }>();

    const {categories, info, ...etc} = useMemo(() => {

        const profileData = {
            adminVerified: false,
            bankVerified: false,
            createdAt: undefined,
            categories: [],
            docs: [],
            desc: undefined,
            info: undefined,
        } as Partial<ProviderModel>;

        if (!providerProfile)
            return profileData;

        return {...providerProfile}

    }, [providerProfile]);

    return (
        <div className="flex p-5 w-full rounded-lg gap-2 relative flex-col xl:flex-row">

            {
                selectedImage &&
                <div
                    onClick={() => setSelectedImage(undefined)}
                    className="fixed z-40 overflow-hidden left-0 top-0 bg-[#000000F0] w-screen h-screen flex flex-col justify-center items-center">
                    <img src={selectedImage.link} alt="" className="z-50"/>
                    <p className='text-white'>{selectedImage.name}</p>
                </div>
            }

            <div className="xl:w-1/3 w-full flex flex-col gap-2 items-center">
                <div className="w-full p-3 bg-white drop-shadow-xl rounded-lg h-full">
                    <p className="text-2xl font-bold">{etc.desc?.title}</p>
                    <p className="px-2 mt-2 border-t-2">{etc.desc?.text}</p>
                </div>
                {
                    info &&
                    <div className="w-full flex flex-col gap-2">
                        <ProfileRow title="First Name" value={info.firstname}/>
                        <ProfileRow title="Last Name" value={info.lastname}/>
                        <ProfileRow title="Email" value={providerProfile.auth_id.email}/>
                        <ProfileRow title="Phone" value={info.phone.country_code + ' ' + info.phone.number }/>

                        <ProfileRow title="Email 2" value={providerProfile.info.email2}/>
                        <ProfileRow title="Phone 2" value={providerProfile.info?.phone?.country_code + "  " + providerProfile.info?.phone?.number }/>
                        <ProfileRow title="Discount Percent" value={(providerProfile.tax_rate * 100) + ' %'}/>
                        {/*<ProfileRow title="Instagram" value={info.instagram} />*/}
                    </div>
                }
            </div>

            <div className="xl:w-2/3 w-full p-3  min-h-full bg-white rounded-lg flex flex-col gap-5 drop-shadow-xl">

                <div className=" text-base  border-b-2 border-b-blueGray-200 py-2">
                    Created At: <span className="mx-5 font-normal">{etc.createdAt}</span>
                </div>
                <div className=" text-base  border-b-2 border-b-blueGray-200 py-2">
                    Last Updated At: <span className="mx-5 font-normal">{etc.updatedAt}</span>
                </div>
                <div className=" text-base  border-b-2 border-b-blueGray-200 py-2">
                    Address : <span className="mx-5 font-normal">{providerProfile.info.address}</span>
                </div>

                <div className=" text-base  border-b-2 border-b-blueGray-200 py-2">
                    Address 2: <span className="mx-5 font-normal">{providerProfile.info.address2}</span>
                </div>
                <div className=" text-base  border-b-2 border-b-blueGray-200 py-2">
                    Instagram: <span className="mx-5 font-normal">{providerProfile.info.instagram}</span>
                </div>
                <div className=" text-base  border-b-2 border-b-blueGray-200 py-2">
                    Website: <span className="mx-5 font-normal">{providerProfile.info.website}</span>
                </div>


                {/* <div className="text-2xl border-b-2 border-b-blueGray-200 py-3">
                    Veryfied By Admin: <span className="mx-5 bg-teal-400 text-black p-2 rounded-lg">{etc.adminVerified ? "YES" : "NO"}</span>
                </div>
                <div className="text-2xl border-b-2 border-b-blueGray-200 py-3">
                    Veryfied By Bank: <span className="mx-5 bg-teal-400 text-black p-2 rounded-lg">{etc.bankVerified ? "YES" : "NO"}</span>
                </div> */}

                <div className="overflow-x-scroll hide-scroll  text-base   py-3">
                    Selected categories:
                    {
                        categories?.map(cat =>
                            <span key={cat._id} className="p-2 rounded-lg bg-blueGray-200 m-2 font-normal">
                                {cat.title}
                            </span>
                        )
                    }
                </div>

                <div className="flex gap-3 w-full border-2 border-blueGray-200 rounded-2xl">

                    <div
                        onClick={() => {
                            if (imgContainer.current) {
                                imgContainer.current.scrollLeft -= 10;
                            }
                        }}
                        className="w-10 text-4xl font-bold px-2 justify-center cursor-pointer text-white
                            min-h-full flex flex-col rounded-tl-xl rounded-bl-xl bg-blueGray-600">
                        {"<"}
                    </div>

                    <div ref={imgContainer}
                         className="h-[180px] overflow-x-scroll justify-center items-center hide-scroll w-full flex gap-3 p-2">
                        {providerProfile.docs?.length === 0 ? <span>No Image</span> :
                            providerProfile.docs?.map(doc => {
                                const link: string = `${process.env.REACT_APP_BASE_URL}/api/v1/pub/provider/doc/file/${doc.file}`;
                                return (
                                    <div
                                        key={doc._id}
                                        className="relative group h-full cursor-pointer min-w-32 bg-[#FFFFFFA0] rounded-lg flex
                                    justify-center items-center">

                                        <span
                                            onClick={() => setSelectedImage({
                                                link, name: doc.title
                                            })}
                                            className='absolute left-0 top-0 rounded-lg justify-center items-center group-hover:flex hidden
                                    bg-[#000000A0] w-full h-full text-pink-500 font-bold text-sm'>
                                            {doc.title}
                                        </span>

                                        <img
                                            className="rounded-lg max-h-full w-full"
                                            src={link}
                                            alt=""/>
                                    </div>
                                )
                            })
                        }
                    </div>

                    <div onClick={() => {
                        if (imgContainer.current) {
                            imgContainer.current.scrollLeft += 10;
                        }
                    }}
                         className="w-10 text-4xl font-bold px-2 justify-center cursor-pointer min-h-full
                                flex flex-col rounded-tr-xl rounded-br-xl bg-blueGray-600 text-white">
                        {">"}
                    </div>

                </div>

            </div>

        </div>

    );
}

export default SupplierProfile;